/* REACT IMPORTS */
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

/* STYLES */
import "../styles/global.css";

/* LAYOUT */
import Layout from "../layout";

/* PAGES */
import AuthLogin from "../pages/auth/login";
import AuthRegister from "../pages/auth/register";

import Organization from "../pages/dashboard/organization";
import AddOrganization from "../pages/dashboard/organization/add-organization";
import ViewOrganization from "../pages/dashboard/organization/view-organization";
import EditOrganization from "../pages/dashboard/organization/edit-organization";

import Zone from "../pages/dashboard/zone";
import AddZone from "../pages/dashboard/zone/add-zone";
import ViewZone from "../pages/dashboard/zone/view-zone";
import EditZone from "../pages/dashboard/zone/edit-zone";

import Region from "../pages/dashboard/region";
import AddRegion from "../pages/dashboard/region/add-region";
import ViewRegion from "../pages/dashboard/region/view-region";
import EditRegion from "../pages/dashboard/region/edit-region";

import Members from "../pages/dashboard/members";
import AddMembers from "../pages/dashboard/members/add-member";
import EditMembers from "../pages/dashboard/members/edit-member";
import MemberDetails from "../pages/dashboard/members/view-member";

import Role from "../pages/dashboard/role";
import AddRole from "../pages/dashboard/role/add-role";
import ViewRole from "../pages/dashboard/role/view-role";
import EditRole from "../pages/dashboard/role/edit-role";

import Dashboard from "../pages/dashboard";

import CmsView from "../pages/dashboard/cms/view-page/index";
import CmsList from "../pages/dashboard/cms/index";

import MedicalAid from "../pages/dashboard/medical-aid";
import ViewMedicalAid from "../pages/dashboard/medical-aid/view-medical-aid";
import EditMedicalAid from "../pages/dashboard/medical-aid/edit-medical-aid";
import ExportMedicalAid from "pages/dashboard/medical-aid/export-medical-aid";

import ContactUs from "../pages/dashboard/contact-us";
import NewsLetter from "../pages/dashboard/newsLetter";
import Advertisements from "../pages/dashboard/advertisement";


/* SCROLL TO TOP */
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const AppRoutes = () => {
  return (
    <div className="app">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<AuthLogin />} />

          <Route path="/register" element={<AuthRegister />} />

          {/* DASHBOARD */}
          <Route
            path="/dashboard"
            element={
              <Layout>
                <Dashboard />
              </Layout>
            }
          />

          {/* ZONE */}
          <Route
            path="/zones"
            element={
              <Layout>
                <Zone />
              </Layout>
            }
          />
          <Route
            path="/zone/add-zone"
            element={
              <Layout>
                <AddZone />
              </Layout>
            }
          />
          <Route
            path="/zone/view-zone/:id"
            element={
              <Layout>
                <ViewZone />
              </Layout>
            }
          />
          <Route
            path="/zone/edit-zone/:id"
            element={
              <Layout>
                <EditZone />
              </Layout>
            }
          />

          {/* REGION */}
          <Route
            path="/regions"
            element={
              <Layout>
                <Region />
              </Layout>
            }
          />
          <Route
            path="/region/add-region"
            element={
              <Layout>
                <AddRegion />
              </Layout>
            }
          />
          <Route
            path="/region/view-region/:id"
            element={
              <Layout>
                <ViewRegion />
              </Layout>
            }
          />
          <Route
            path="/region/edit-region/:id"
            element={
              <Layout>
                <EditRegion />
              </Layout>
            }
          />

          {/* ROLE */}
          <Route
            path="/roles"
            element={
              <Layout>
                <Role />
              </Layout>
            }
          />
          <Route
            path="/role/add-role"
            element={
              <Layout>
                <AddRole />
              </Layout>
            }
          />
          <Route
            path="/role/view-role/:id"
            element={
              <Layout>
                <ViewRole />
              </Layout>
            }
          />
          <Route
            path="/role/edit-role/:id"
            element={
              <Layout>
                <EditRole />
              </Layout>
            }
          />

          {/* MEMBERS */}
          <Route
            path="/members"
            element={
              <Layout>
                <Members />
              </Layout>
            }
          />
          <Route
            path="/member/view-member/:id"
            element={
              <Layout>
                <MemberDetails />
              </Layout>
            }
          />
          <Route
            path="/member/edit-member/:id"
            element={
              <Layout>
                <EditMembers />
              </Layout>
            }
          />
          <Route
            path="/member/add-member"
            element={
              <Layout>
                <AddMembers />
              </Layout>
            }
          />

          {/* ORGANIZATION */}
          <Route
            path="/organization"
            element={
              <Layout>
                <Organization />
              </Layout>
            }
          />
          <Route
            path="/organization/add-organization"
            element={
              <Layout>
                <AddOrganization />
              </Layout>
            }
          />
          <Route
            path="/organization/view-organization/:id"
            element={
              <Layout>
                <ViewOrganization />
              </Layout>
            }
          />
          <Route
            path="/organization/edit-organization/:id"
            element={
              <Layout>
                <EditOrganization />
              </Layout>
            }
          />

          {/* CONTACT US */}
          <Route
            path="/contact-us"
            element={
              <Layout>
                <ContactUs />
              </Layout>
            }
          />

          {/* NEWSLETTER */}
          <Route
            path="/newsletter"
            element={
              <Layout>
                <NewsLetter />
              </Layout>
            }
          />

          {/* ADVERTISEMENTS */}
          <Route
            path="/advertisement"
            element={
              <Layout>
                <Advertisements />
              </Layout>
            }
          />


          {/* MEDICAL AID */}
          <Route
            path="/medical-aid"
            element={
              <Layout>
                <MedicalAid />
              </Layout>
            }
          />
          <Route
            path="/medical-aid/view-medical-aid/:id"
            element={
              <Layout>
                <ViewMedicalAid />
              </Layout>
            }
          />
          <Route
            path="/medical-aid/edit-medical-aid/:id"
            element={
              <Layout>
                <EditMedicalAid />
              </Layout>
            }
          />

          <Route
            path="/medical-aid/export-medical-aid/:id"
            element={
              <ExportMedicalAid />
            }
          />

          <Route
            path="/cms/"
            element={
              <Layout>
                <CmsList />
              </Layout>
            }
          />
          <Route
            path="/cms/view/:pageName"
            element={
              <Layout>
                <CmsView />
              </Layout>
            }
          />
        </Routes>
      </Router>
    </div>
  );
};

export default AppRoutes;
