/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";

import { Helmet } from "react-helmet";
import Select from "react-select";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";

/* MATERIAL TAILWIND COMPONENTS */
import { Button, Dialog, DialogBody, Tooltip } from "@material-tailwind/react";

/* ICONS */
import { IoEyeSharp, IoBan, IoClose } from "react-icons/io5";
import { BiEdit } from "react-icons/bi";
import { FiTrash } from "react-icons/fi";
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";

/* USER DEFINED COMPONENTS */
import Input from "../../../components/input";

/* TOAST NOTIFICATION */
import { toast } from "react-toast";

/* API IMPORT */
import { deleteRoles, getRolesList, editRolesStatusById } from "../../../service/api";


/* STATUS */
const status = [
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
];

const RoleList = () => {
  // STATE VARIABLES
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);

  const [rolesList, setRolesList] = useState([]);

  const [totalDocs, setTotalDocs] = useState([]);

  const [search, setSearch] = useState("");

  const [deleteId, setDeleteId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);

  /* FUNCTIONS */
  const handlePageChange = async (e) => {
    setPage(e);
    getRolesListFunction();
  };

  const handlePerPageChange = async (e) => {
    setSizePerPage(e);
    getRolesListFunction();
  };

  const getRolesListFunction = () => {
    setLoading(true);
    const params = {
      page: page,
      sizePerPage: sizePerPage,
      pagination: true,
    };
    if (search) {
      params.search = search;
    }

    getRolesList(params)
      .then((res) => {
        setRolesList(res?.data?.docs);
        setTotalDocs(res?.data?.totalDocs);
      })
      .catch((e) => {
        console.log("e-->", e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /* HANDLE DELETE DATA */
  const handleDelete = () => {
    deleteRoles(deleteId)
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        setDeleteModal(!deleteModal);
        setDeleteId(null);
        getRolesListFunction();
      });
  };

  /* HANDLE STATUS CHANGE FUNCTION */
  const handleStatusChange = (editId) => {
    editRolesStatusById(editId)
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        getRolesListFunction();
      });
  };



  /* TABLE COLUMNS */
  const columns = [
    {
      name: "Image",
      width: "10vh",
      selector: (row) => (
        <span>
          <img
            src={
              row?.roleImage ? row?.roleImage : require("../../../assets/images/organization.png")
            }
            className={row?.roleImage ? "w-14 h-14 rounded-lg object-cover" : "w-10 h-10 rounded-lg "}
            alt={row.name}
          />
        </span>
      ),
    },
    {
      name: "Name",
      // width: "40vh",
      selector: (row) => (
        <span className="text-sm font-proxima-bold text-black">{row.name}</span>
      ),
    },

    {
      name: "Permission",
      // width: "20vh",
      selector: (row) => (
        <span className="text-sm font-proxima-bold text-black">
          -
        </span>
      ),
    },
    {
      name: "Visibility",
      // width: "15vh",
      selector: (row) => (
        <span
          className={
            row?.isActive
              ? "text-sm font-proxima-bold text-green-800 text-opacity-60"
              : "text-sm font-proxima-bold text-yellow-700 text-opacity-60"
          }
        >
          {row.isActive ? "Enabled" : "Disabled"}
        </span>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="flex items-center">
          <Tooltip
            content="View role details"
            className="text-sm font-proxima-semibold"
          >
            <Link target="_blank" to={"/role/view-role/" + row?._id}>
              <Button className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5">
                <IoEyeSharp className="w-5 h-5 text-greyText" />
              </Button>
            </Link>
          </Tooltip>
          <Tooltip
            content="Edit role details"
            className="text-sm font-proxima-semibold"
          >
            <Link target="_blank" to={"/role/edit-role/" + row?._id}>
              <Button className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5">
                <BiEdit className="w-5 h-5 text-greyText" />
              </Button>
            </Link>
          </Tooltip>
          <Tooltip
            content="Remove role"
            className="text-sm font-proxima-semibold"
          >
            <Button onClick={() => {
              setDeleteId(row?._id)
              setDeleteModal(!deleteModal)
            }} className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5">
              <FiTrash className="w-5 h-5 text-greyText" />
            </Button>
          </Tooltip>
          <Tooltip
            content="Disable on website"
            className="text-sm font-proxima-semibold"
          >
            <Button onClick={() => { handleStatusChange(row?._id) }} className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5">
              <IoBan className="w-5 h-5 text-greyText" />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  /* USE-EFFECTS */
  useEffect(() => {
    getRolesListFunction();
  }, [page, sizePerPage, search]);

  /* LOADING SCREEN FOR DATATABLE */
  const LoadingScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-proxima-semibold text-black text-center">
          Please wait!
        </p>
        <p className="text-sm font-proxima-semibold text-black text-center">
          We are fetching data{" "}
        </p>
      </div>
    );
  };

  const NoDataScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-proxima-semibold text-black text-center">
          There are no queries to display.
        </p>
      </div>
    );
  };

  return (
    <section className="px-0 py-2 xl:p-10 lg:p-10 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Roles | Lohana Mahaparishad</title>
      </Helmet>

      <div className="container-full-width">
        <div className="block xl:flex lg:flex items-center justify-between">
          {/* HEADING */}
          <div>
            <h1 className="text-3xl font-proxima-bold text-black tracking-tight">
              Roles
            </h1>
            <h2 className="text-lg font-proxima-regular text-greyText tracking-tight">
              Manage the role details for your website
            </h2>
          </div>

          {/* SEARCH AND FILTERS */}
          <div className="block xl:flex lg:flex items-center gap-2">
            {/* SEARCHBAR */}
            <div className="w-full xl:w-searchBar lg:w-searchBar mt-2 xl:mt-0 lg:mt-0">
              <Input
                type="text"
                placeholder="Search..."
                name="searchInv"
                id="searchInv"
              />
            </div>

            {/* DROPDOWN */}
            {/* <div className="w-full xl:w-categorySelect lg:w-categorySelect mt-2 xl:mt-0 lg:mt-0">
              <Select
                options={categoryOptions}
                classNamePrefix="dropdown"
                placeholder="Select category"
                name="category"
                id="category"
              />
            </div> */}

            {/* STATUS DROPDOWN */}
            <div className="w-full xl:w-statusSelect lg:w-statusSelect mt-2 xl:mt-0 lg:mt-0">
              <Select
                options={status}
                classNamePrefix="dropdown"
                placeholder="Select status"
                name="status"
                id="status"
              />
            </div>

            {/* ADD PRODUCT */}
            <Link to="/role/add-role">
              <Button className="w-full xl:w-auto lg:w-auto mt-2 xl:mt-0 lg:mt-0 bg-logoYellow rounded-lg py-3 px-8 text-sm font-proxima-semibold shadow-none hover:shadow-none normal-case hover:bg-opacity-80 transition-all duration-300 ease-in-out">
                Add Role
              </Button>
            </Link>
          </div>
        </div>

        {/* INVENTORY TABLE */}
        <div className="mt-5">
          <DataTable
            className="data-table"
            columns={columns}
            data={totalDocs > 0 ? rolesList : []}
            progressPending={loading}
            progressComponent={<LoadingScreen />}
            pagination
            paginationServer
            paginationTotalRows={totalDocs}
            onChangeRowsPerPage={handlePerPageChange}
            onChangePage={handlePageChange}
            noDataComponent={<NoDataScreen />}
          />
        </div>
      </div>

      <Dialog
        open={deleteModal}
        handler={() => setDeleteModal(!deleteModal)}
        size="xs"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <DialogBody className="p-0">
          <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
            <h5 className="text-lg font-proxima-bold text-black tracking-tight">
              Remove Role
            </h5>
            <Button
              className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
              onClick={() => setDeleteModal(!deleteModal)}
            >
              <IoClose className="w-4 h-4 text-greyText" />
            </Button>
          </div>
          <div className="p-5 border-b border-authBorder">
            <p className="text-base font-proxima-regular text-black">
              Are you sure you want to delete this role?
            </p>
          </div>
          <div className="flex items-center justify-end px-5 py-3">
            <Button className="text-sm shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md font-proxima-semibold text-white transition-all duration-300 ease-in-out normal-case">
              Delete details
            </Button>
          </div>
        </DialogBody>
      </Dialog>
    </section>
  );
};

export default RoleList;
