import React from "react";
import { Button, Tooltip } from "@material-tailwind/react";
import { BiEdit } from "react-icons/bi";

import Member01 from "assets/images/CMS/team/jitendrabhai-thakkar.jpg";
import Member02 from "assets/images/CMS/team/jitendrabhai-lal.jpg";
import Member03 from "assets/images/CMS/team/dharmeshbhai-hariyani.jpg";
import Member04 from "assets/images/CMS/team/harishbhai-thakkar.jpg";
import Member05 from "assets/images/CMS/team/sureshbhai-popat.jpg";

const TeamSecond = (props) => {
  const cmsList = props?.cmsList;

  const EditButton = (editProps) => {
    return (
      <Tooltip
        content={editProps?.content}
        className="text-sm font-proxima-semibold"
      >
        <Button
          onClick={() => {
            props.setEditDetails(editProps?.details);
            props.setEditId(editProps?.id);
            props.setEditDialog(!props.editDialog);
          }}
          className="p-2 bg-white border border-greyBorder rounded-full shadow hover:shadow hover:border-logoRed transition-all duration-300 ease-in-out"
        >
          <BiEdit className="w-5 h-5 text-greyText" />
        </Button>
      </Tooltip>
    );
  };

  return (
    <div className="bg-logoRed bg-opacity-5 p-10 rounded-xl">
      <div className="container-full-width">
        <h2 className="text-4xl font-sunflower-bold text-black tracking-tight">
          Key Office Bearers
        </h2>
        <p className="text-lg font-proxima-regular text-greyText">
          Meet the heartbeat of Shree Lohana Mahaparishad: Our diverse team
          committed to unity and progress
        </p>
        <div className="grid grid-cols-1 xl:grid-cols-5 lg:grid-cols-5 gap-5 mt-5">
          {/* MEMBER 1 */}
          <div className="border border-greyBorder shadow rounded-lg bg-white">
            <div className="relative">
              <img
                src={Member01}
                alt="Shri. Jitendrabhai Thakkar"
                className="w-full h-[35vh] xl:h-[30vh] lg:h-[30vh] object-cover rounded-t-lg"
              />
              <div className="absolute top-3 right-3">
                <EditButton
                  details={cmsList?.find(
                    (el) => el?.moduleName === "bannerImage"
                  )}
                  content="Edit details"
                  id={
                    cmsList?.find((el) => el?.moduleName === "bannerImage")?._id
                  }
                />{" "}
              </div>
            </div>
            <div className="py-3 text-center">
              <p className="text-lg font-proxima-bold text-black">
                Shri. Jitendrabhai Thakkar
              </p>
              <p className="text-base font-proxima-semibold text-logoRed">
                Senior Vice President
              </p>
            </div>
          </div>

          {/* MEMBER 2 */}
          <div className="border border-greyBorder shadow rounded-lg bg-white">
            <div className="relative">
              <img
                src={Member02}
                alt="Shri. Jitendrabhai Lal"
                className="w-full h-[35vh] xl:h-[30vh] lg:h-[30vh] object-cover rounded-t-lg"
              />
              <div className="absolute top-3 right-3">
                <EditButton
                  details={cmsList?.find(
                    (el) => el?.moduleName === "bannerImage"
                  )}
                  content="Edit details"
                  id={
                    cmsList?.find((el) => el?.moduleName === "bannerImage")?._id
                  }
                />{" "}
              </div>
            </div>
            <div className="py-3 text-center">
              <p className="text-lg font-proxima-bold text-black">
                Shri. Jitendrabhai Lal
              </p>
              <p className="text-base font-proxima-semibold text-logoRed">
                Senior Vice President
              </p>
            </div>
          </div>

          {/* MEMBER 3 */}
          <div className="border border-greyBorder shadow rounded-lg bg-white">
            <div className="relative">
              <img
                src={Member03}
                alt="Shri. Dharmeshbhai Hariyani"
                className="object-cover w-full h-[35vh] xl:h-[30vh] lg:h-[30vh] rounded-t-lg"
              />
              <div className="absolute top-3 right-3">
                <EditButton
                  details={cmsList?.find(
                    (el) => el?.moduleName === "bannerImage"
                  )}
                  content="Edit details"
                  id={
                    cmsList?.find((el) => el?.moduleName === "bannerImage")?._id
                  }
                />{" "}
              </div>
            </div>
            <div className="py-3 text-center">
              <p className="text-lg font-proxima-bold text-black">
                Shri. Dharmeshbhai Hariyani
              </p>
              <p className="text-base font-proxima-semibold text-logoRed">
                Senior Vice President
              </p>
            </div>
          </div>

          {/* MEMBER 4 */}
          <div className="border border-greyBorder shadow rounded-lg bg-white">
            <div className="relative">
              <img
                src={Member04}
                alt="Shri. Harishbhai Thakkar"
                className="w-full h-[35vh] xl:h-[30vh] lg:h-[30vh] object-cover rounded-t-lg"
              />
              <div className="absolute top-3 right-3">
                <EditButton
                  details={cmsList?.find(
                    (el) => el?.moduleName === "bannerImage"
                  )}
                  content="Edit details"
                  id={
                    cmsList?.find((el) => el?.moduleName === "bannerImage")?._id
                  }
                />{" "}
              </div>
            </div>
            <div className="py-3 text-center">
              <p className="text-lg font-proxima-bold text-black">
                Shri. Harishbhai Thakkar
              </p>
              <p className="text-base font-proxima-semibold text-logoRed">
                Secretary
              </p>
            </div>
          </div>

          {/* MEMBER 5 */}
          <div className="border border-greyBorder shadow rounded-lg bg-white">
            <div className="relative">
              <img
                src={Member05}
                alt="Shri Dr. Sureshbhai Popat"
                className="w-full h-[35vh] xl:h-[30vh] lg:h-[30vh] object-cover rounded-t-lg"
              />
              <div className="absolute top-3 right-3">
                <EditButton
                  details={cmsList?.find(
                    (el) => el?.moduleName === "bannerImage"
                  )}
                  content="Edit details"
                  id={
                    cmsList?.find((el) => el?.moduleName === "bannerImage")?._id
                  }
                />{" "}
              </div>
            </div>
            <div className="py-3 text-center">
              <p className="text-lg font-proxima-bold text-black">
                Shri Dr. Sureshbhai Popat
              </p>
              <p className="text-base font-proxima-semibold text-logoRed">
                Secretary
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamSecond;
