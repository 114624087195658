/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";

import { Helmet } from "react-helmet";

import moment from "moment";

import DataTable from "react-data-table-component";
import Input from "../../../components/input";

/* MATERIAL TAILWIND COMPONENTS */
import { Button, Dialog, DialogBody, Tooltip } from "@material-tailwind/react";

/* ICONS */
import { FiEye, FiTrash } from "react-icons/fi";
import { IoClose, IoEyeSharp } from "react-icons/io5";

/* TOAST NOTIFICATION */
import { toast } from "react-toast";

/* API IMPORT */
import {
  deleteAdvertisementDetailsById,
  getAdvertisementList,
} from "../../../service/api";

const Advertisement = () => {
  // STATE VARIABLES
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);

  const [advertisementList, setAdvertisementList] = useState([]);

  const [totalDocs, setTotalDocs] = useState([]);

  const [search, setSearch] = useState("");

  const [deleteId, setDeleteId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);

  const [viewDetails, setViewDetails] = useState(null);
  const [viewModal, setViewModal] = useState(false);

  /* FUNCTIONS */
  const handlePageChange = async (e) => {
    setPage(e);
    getAdvertisementListFunction();
  };

  const handlePerPageChange = async (e) => {
    setSizePerPage(e);
    getAdvertisementListFunction();
  };

  const getAdvertisementListFunction = () => {
    setLoading(true);
    const params = {
      page: page,
      sizePerPage: sizePerPage,
    };
    if (search) {
      params.search = search;
    }

    getAdvertisementList(params)
      .then((res) => {
        setAdvertisementList(res?.data?.docs);
        setTotalDocs(res?.data?.totalDocs);
      })
      .catch((e) => {
        console.log("e-->", e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /* HANDLE DELETE DATA */
  const handleDelete = () => {
    deleteAdvertisementDetailsById(deleteId)
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        setDeleteModal(!deleteModal);
        setDeleteId(null);
        getAdvertisementListFunction();
      });
  };

  /* GET INITIALS */
  const getInitials = (string1, string2) => {
    function extractFirstLetter(string) {
      let extractedString = [];

      for (var i = 0; i < string?.length; i++) {
        if (
          (string?.charAt(i) === string?.charAt(i).toUpperCase() ||
            string?.charAt(i) === string?.charAt(i).toLowerCase()) &&
          string?.charAt(i) !== " "
        ) {
          extractedString.push(string?.charAt(i).toUpperCase());
        }
      }
      if (extractedString.length > 1) {
        return extractedString[0];
      } else {
        return extractedString[0];
      }
    }
    let letterOne = extractFirstLetter(string1);
    let letterTwo = extractFirstLetter(string2);
    if (letterOne !== undefined && letterTwo !== undefined) {
      return letterOne + letterTwo;
    } else if (letterOne === undefined && letterTwo !== undefined) {
      return letterTwo;
    } else if (letterOne !== undefined && letterTwo === undefined) {
      return letterOne;
    } else {
      return "";
    }
  };

  /* COLUMNS */
  const columns = [
    {
      name: "Sr. No",
      width: "10vh",
      selector: (row, index) => (
        <span className="text-sm font-proxima-semibold text-black text-opacity-60">
          {index + 1}
        </span>
      ),
    },
    {
      name: "Name",
      grow: 2,
      selector: (row) => (
        <div className="flex items-center gap-2">
          <div className="w-10 h-10 rounded-full flex items-center justify-center bg-logoYellow text-sm font-proxima-semibold text-white">
            {getInitials(row.firstName, row.lastName)}
          </div>
          <p className="text-sm font-proxima-semibold text-black text-opacity-60">
            {row.firstName} {row?.lastName}
          </p>
        </div>
      ),
    },
    {
      name: "Email",
      width: "25vh",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black text-opacity-60">
          {row.email}
        </span>
      ),
    },
    {
      name: "Phone",
      width: "20vh",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black text-opacity-60">
          {row.phoneNumber}
        </span>
      ),
    },
    {
      name: "Message",
      width: "50vh",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black text-opacity-60">
          {row.message}
        </span>
      ),
    },
    {
      name: "Timestamp",
      width: "25vh",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black text-opacity-60">
          {moment(row.createdAt).format("MMM DD, YYYY ")}
        </span>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="flex items-center">
          <Tooltip content="View" className="text-sm font-proxima-semibold">
            <Button
              onClick={() => {
                setViewDetails(row);
                setViewModal(!viewModal);
              }}
              className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5"
            >
              <IoEyeSharp className="w-4 h-4 text-greyText" />
            </Button>
          </Tooltip>

          <Tooltip content="Remove" className="text-sm font-proxima-semibold">
            <Button
              onClick={() => {
                setDeleteId(row?._id);
                setDeleteModal(!deleteModal);
              }}
              className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5"
            >
              <FiTrash className="w-4 h-4 text-greyText" />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  /* USE-EFFECTS */
  useEffect(() => {
    getAdvertisementListFunction();
  }, [page, sizePerPage, search]);

  /* LOADING SCREEN FOR DATATABLE */
  const LoadingScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-proxima-semibold text-black text-center">
          Please wait!
        </p>
        <p className="text-sm font-proxima-semibold text-black text-center">
          We are fetching data{" "}
        </p>
      </div>
    );
  };

  const NoDataScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-proxima-semibold text-black text-center">
          There are no queries to display.
        </p>
      </div>
    );
  };

  return (
    <section className="px-0 py-2 xl:p-5 lg:p-5 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Advertisement Queries | Lohana Mahaparishad</title>
      </Helmet>

      <div className="container-full-width">
        {/* HEADING */}
        <div className="block xl:flex lg:flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-proxima-bold text-black tracking-tight">
              Advertisement
            </h1>
            <h2 className="text-base font-proxima-regular text-greyText">
              Manage the queries by your users from website.
            </h2>
          </div>
          <div className="block xl:flex lg:flex items-center gap-2">
            {/* SEARCHBAR */}
            <div className="w-3/4 xl:w-full lg:w-full mt-2 xl:mt-0 lg:mt-0">
              <Input
                type="text"
                placeholder="Search..."
                name="search"
                id="search"
                value={search}
                onChange={(e) => setSearch(e?.target?.value)}
              />
            </div>
          </div>
        </div>

        <div className="mt-5">
          <DataTable
            className="data-table"
            columns={columns}
            data={totalDocs > 0 ? advertisementList : []}
            progressPending={loading}
            progressComponent={<LoadingScreen />}
            pagination
            paginationServer
            paginationTotalRows={totalDocs}
            onChangeRowsPerPage={handlePerPageChange}
            onChangePage={handlePageChange}
            noDataComponent={<NoDataScreen />}
          />
        </div>

        {/* VIEW DIALOG */}
        <Dialog
          open={viewModal}
          handler={() => setViewModal(!viewModal)}
          size="xs"
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
        >
          <DialogBody className="p-0">
            <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
              <h5 className="text-lg font-proxima-bold text-black tracking-tight">
                View Details
              </h5>
              <Button
                className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
                onClick={() => setViewModal(!viewModal)}
              >
                <IoClose className="w-4 h-4 text-greyText" />
              </Button>
            </div>
            <div className="p-5 pt-3 border-b border-authBorder">
              {/* NAME */}
              <p className="text-base font-proxima-regular text-black">
                <span className=" font-proxima-bold">Name: </span>
                {viewDetails?.firstName} {viewDetails?.lastName}
              </p>
              {/* EMAIL */}
              <p className="text-base font-proxima-regular text-black">
                <span className=" font-proxima-bold">Email: </span>
                {viewDetails?.email}
              </p>
              {/* PHONE NUMBER */}
              <p className="text-base font-proxima-regular text-black">
                <span className=" font-proxima-bold">Phone Number: </span>
                {viewDetails?.phoneNumber}
              </p>
              {/* MESSAGE */}
              <p className="text-base font-proxima-regular text-black">
                <span className=" font-proxima-bold">Message: </span>
                {viewDetails?.message}
              </p>
              {/* TITLE */}
              <p className="text-base font-proxima-regular text-black">
                <span className=" font-proxima-bold">Title: </span>
                {viewDetails?.title}
              </p>
              {/* START DATE */}
              <p className="text-base font-proxima-regular text-black">
                <span className=" font-proxima-bold">Start Date: </span>
                {moment(viewDetails?.startDate).format("DD/MM/YYYY")}
              </p>
              {/* END DATE */}
              <p className="text-base font-proxima-regular text-black">
                <span className=" font-proxima-bold">End Date: </span>
                {moment(viewDetails?.endDate).format("DD/MM/YYYY")}
              </p>
              {/* DESCRIPTION */}
              <p className="text-base font-proxima-regular text-black">
                <span className=" font-proxima-bold">Description: </span>
                {viewDetails?.description}
              </p>
              {/* ADVERTISEMENT IMAGE */}
              <p className="text-base font-proxima-regular text-black">
                <span className=" font-proxima-bold">
                  Advertisement Image:{" "}
                </span>
              </p>
              <div className="col-span-3 relative">
                <img
                  src={viewDetails?.advertisementImage?.url}
                  className="w-full h-full object-cover rounded-md"
                  alt="Product"
                />
              </div>
            </div>
          </DialogBody>
        </Dialog>

        {/* DELETE DIALOG */}
        <Dialog
          open={deleteModal}
          handler={() => setDeleteModal(!deleteModal)}
          size="xs"
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
        >
          <DialogBody className="p-0">
            <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
              <h5 className="text-lg font-proxima-bold text-black tracking-tight">
                Delete Details
              </h5>
              <Button
                className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
                onClick={() => setDeleteModal(!deleteModal)}
              >
                <IoClose className="w-4 h-4 text-greyText" />
              </Button>
            </div>
            <div className="p-5 border-b border-authBorder">
              <p className="text-base font-proxima-regular text-black">
                Are you sure you want to delete this details?
              </p>
            </div>
            <div className="flex items-center justify-end px-5 py-3">
              <Button
                onClick={handleDelete}
                className="text-sm shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md font-proxima-semibold text-white transition-all duration-300 ease-in-out normal-case"
              >
                Delete
              </Button>
            </div>
          </DialogBody>
        </Dialog>
      </div>
    </section>
  );
};

export default Advertisement;
