/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { Helmet } from "react-helmet";

/* ASSETS */
import Logo from "assets/images/lmp-logo.png";
import MedicalLogo from "assets/images/medical-logo.png";

/* PACKAGES */
import DataTable from "react-data-table-component";

import { getMedicalAidDetailsById } from "../../../service/api";
import { toast } from "react-toast";
import { Link, useParams } from "react-router-dom";
import moment from "moment";


const columns = [
  {
    name: "No.",
    selector: (row) => (
      <input
        disabled={true}
        type="number"
        id="no"
        name="no"
        className="w-full bg-white block text-xs font-proxima-semibold text-black outline-none focus:outline-none px-0 py-2 placeholder:text-xs placeholder:font-proxima-regular text-center"
        placeholder="No."
        value={row?.no}
      />
    ),
  },
  {
    name: "Family member's name",
    grow: 3,
    selector: (row) => (
      <input
        disabled={true}
        type="text"
        id="familyMember"
        name="familyMember"
        className="w-full bg-white block text-xs font-proxima-semibold text-black outline-none focus:outline-none px-0 py-2 placeholder:text-xs placeholder:font-proxima-regular text-center"
        placeholder="Name"
        value={row?.familyMember}
      />
    ),
  },
  {
    name: "Age",
    selector: (row) => (
      <input
        disabled={true}
        type="number"
        id="age"
        name="age"
        className="w-full bg-white block text-xs font-proxima-semibold text-black outline-none focus:outline-none px-0 py-2 placeholder:text-xs placeholder:font-proxima-regular text-center"
        placeholder="Age"
        value={row?.age}
      />
    ),
  },
  {
    name: "Occupation",
    grow: 2,
    selector: (row) => (
      <input
        disabled={true}
        type="text"
        id="occupation"
        name="occupation"
        className="w-full bg-white block text-xs font-proxima-semibold text-black outline-none focus:outline-none px-0 py-2 placeholder:text-xs placeholder:font-proxima-regular text-center"
        placeholder="Occupation"
        value={row?.occupation}
      />
    ),
  },
  {
    name: "Monthly Income",
    grow: 2,
    selector: (row) => (
      <input
        disabled={true}
        type="number"
        id="monthlyIncome"
        name="monthlyIncome"
        className="w-full bg-white block text-xs font-proxima-semibold text-black outline-none focus:outline-none px-0 py-2 placeholder:text-xs placeholder:font-proxima-regular text-center"
        placeholder="Monthly income"
        value={row?.monthlyIncome}
      />
    ),
  },
  {
    name: "Relation",
    grow: 2,
    selector: (row) => (
      <input
        disabled={true}
        type="text"
        id="relation"
        name="relation"
        className="w-full bg-white block text-xs font-proxima-semibold text-black outline-none focus:outline-none px-0 py-2 placeholder:text-xs placeholder:font-proxima-regular text-center"
        placeholder="Relation"
        value={row?.relation}
      />
    ),
  },
];


const ExportMedicalAid = () => {

  const { id } = useParams();

  /* STATE VARIABLE */
  const [loading, setLoading] = useState(false);
  const [medicalAidFormDetails, setMedicalAidFormDetails] = useState(null);

  const handleGetMedicalFormDetails = () => {
    getMedicalAidDetailsById(id)
      .then((res) => {
        // toast.success(res?.message);
        setMedicalAidFormDetails(res?.data);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
  };


  useEffect(() => {
    if (id) {
      handleGetMedicalFormDetails();
      // setTimeout(() => window.print(), 1500);
    }
  }, [id])


  return (
    <section className="px-0 py-2 xl:p-5 lg:p-5 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Medical Aid Queries | Lohana Mahaparishad</title>
      </Helmet>

      <div className="container-full-width">


        {/* FORM */}
        <div className="mt-10 border-black border max-w-5xl mx-auto bg-white p-2">
          {/* HEADER */}
          <div className="px-4 py-2">
            <div className="grid grid-cols-4">
              <div className="col-span-1 ">
                {" "}
                <img
                  src={Logo}
                  className="w-24 h-24 object-cover"
                  alt="Lohana Mahaparishad"
                />
              </div>
              <div className="text-center col-span-2">
                <p className="text-xl lg:text-4xl text-red-700 font-extrabold font-proxima">
                  શ્રી લોહાણા મહાપરિષદ
                </p>
                <p className="text-xl lg:text-4xl text-red-700 font-extrabold font-proxima mx-auto pl-2">
                  રઘુવંશી ચેરિટેબલ ટ્રસ્ટ
                </p>
                <div className="text-lg lg:text-3xl text-indigo-600  px-4 py-1  text-center rounded-lg">
                  આરોગ્ય સહાય અરજી પત્રક
                </div>
              </div>
              <div className="col-span-1  flex justify-end ">
                <img
                  src={MedicalLogo}
                  className="w-24 h-24 object-cover mix-blend-multiply "
                  alt="Medical"
                />
              </div>
            </div>
            <div className="grid grid-cols-3 border-t border-b border-logoRed my-1">
              <div className="col-span-1 font-bold text-lg lg:text-xl text-red-700 r">
                || જય રઘુવંશ ||
              </div>{" "}
              <div className="col-span-1 font-bold text-lg lg:text-xl text-red-700 text-center">
                || જય જલારામ ||
              </div>{" "}
              <div className="col-span-1 font-bold text-lg lg:text-xl text-red-700 text-end">
                ।। જય મહાપરિષદ ।।
              </div>
            </div>
            <div className="mt-2">
              <div className="flex ">
                <p className="text-xs text-red-700 font-bold pr-4 flex-none">
                  પ્રેરક દાતાશ્રી : {"    "}
                </p>

                <p className="text-xs text-indigo-500 font-bold flex-grow">
                  સ્વ. તલકશીભાઈ દલછારામ કોટક મેડીકલ ફાઉન્ડેશન (પ્રવિણભાઈ
                  કોટક - જયેશભાઈ કોટક)
                </p>
              </div>
              <div>
                <div className="flex mt-2">
                  <p className="text-xs text-red-700 font-bold pr-4 flex-none">
                    સહયોગી દાતાશ્રી : {"    "}
                  </p>

                  <div className="flex items-center">
                    <p className="text-xs text-indigo-500 font-bold">
                      ખીમજી ભગવાનદાસ ચેરીટી ટ્રસ્ટ
                    </p>

                    <div className="h-4 w-4 bg-black rounded-full ml-3"></div>
                    <p className="text-xs text-indigo-500 font-bold pl-2">
                      શ્રી રઘુકુળ આરોગ્ય નિધી
                    </p>
                  </div>
                </div>
                <div className="flex items-center ml-32">
                  <div className="h-4 w-4 bg-black rounded-full ml-3"></div>
                  <p className="text-xs text-indigo-500 font-bold pl-2">
                    રવીન ઈન્ફ્રા પ્રોજેક્ટ પ્રી.લી. (વિજયભાઈ કારીયા)
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* PATIENT DETAIL */}
          <div className="mt-2   border border-black p-2">
            {/* NAME */}
            <div className="flex items-center  gap-2">
              <p className="block text-black text-xs font-proxima-semibold flex-none">
                Patient Name :
              </p>
              <div className="w-full border-b border-black">
                <p className="block text-black text-xs font-proxima-semibold ">
                  {medicalAidFormDetails?.patientDetails?.firstName} {medicalAidFormDetails?.patientDetails?.middleName} {medicalAidFormDetails?.patientDetails?.lastName}
                </p>
              </div>
            </div>

            {/* ADDRESS AND PINCODE */}
            <div className="grid grid-cols-4 gap-5 mt-2 items-center">
              <div className="flex items-center gap-2 col-span-2 lg:col-span-3">
                <p className="block text-black text-xs font-proxima-semibold ">
                  Address:
                </p>
                <div className="w-full border-b border-black">
                  <p className="block text-black text-xs font-proxima-semibold ">
                    {medicalAidFormDetails?.patientDetails?.address?.addressLine1}
                  </p>
                </div>
              </div>
              <div className="flex items-center  gap-2 col-span-2 lg:col-span-1">
                <p className="block text-black text-xs font-proxima-semibold ">
                  Pincode:
                </p>
                <div className="w-full border-b border-black">
                  <p className="block text-black text-xs font-proxima-semibold ">
                    {medicalAidFormDetails?.patientDetails?.address?.pinCode}
                  </p>
                </div>
              </div>
            </div>

            {/* NATIVE PLACE AGE AND CONTACT */}
            <div className="grid grid-cols-5 gap-4 mt-2">
              <div className="flex items-center  gap-2 col-span-2">
                <p className="block text-black text-xs font-proxima-semibold flex-none">
                  Native Place:
                </p>
                <div className="w-full border-b border-black">
                  <p className="block text-black text-xs font-proxima-semibold ">
                    {medicalAidFormDetails?.patientDetails?.nativePlace}
                  </p>
                </div>
              </div>
              <div className="flex items-center  gap-2 col-span-1">
                <p className="block text-black text-xs font-proxima-semibold flex-none">
                  Age:
                </p>
                <div className="w-full border-b border-black">
                  <p className="block text-black text-xs font-proxima-semibold ">
                    {medicalAidFormDetails?.patientDetails?.age}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-2 col-span-2">
                <p className="block text-black text-xs font-proxima-semibold flex-none">
                  Contact No:
                </p>
                <div className="w-full border-b border-black">
                  <p className="block text-black text-xs font-proxima-semibold ">
                    {medicalAidFormDetails?.patientDetails?.phoneNumber}
                  </p>
                </div>
              </div>
            </div>

            {/* INCOME PROOF AND TOTAL MEMBERS */}
            <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 mt-2">
              <div className="flex items-center   col-span-1 gap-2">
                <p className="block text-black text-xs font-proxima-semibold flex-none">
                  Avg Income of Family:
                </p>
                <div className="w-full border-b border-black">
                  <p className="block text-black text-xs font-proxima-semibold ">
                    {medicalAidFormDetails?.patientDetails?.annualIncome?.amount}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-2 col-span-1">
                <p className="block text-black text-xs font-proxima-semibold flex-none">
                  Upload Income proof:
                </p>
                <div className="w-full border-b border-black">
                  {medicalAidFormDetails?.patientDetails?.annualIncome?.file?.url &&
                    <Link to={medicalAidFormDetails?.patientDetails?.annualIncome?.file?.url}><p className="block text-black text-xs font-proxima-semibold ">
                      Document
                    </p></Link>}
                </div>
              </div>
              <div className="flex items-center  gap-2 col-span-1">
                <p className="block text-black text-xs font-proxima-semibold flex-none">
                  Total Earning members:
                </p>
                <div className="w-full border-b border-black flex-grow">
                  <p className="block text-black text-xs font-proxima-semibold w-full">
                    {medicalAidFormDetails?.patientDetails?.earningMemberCount}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* DISEASE DETAIL */}
          <div className="mt-2   border border-black p-2">
            {/* DISEASE NAME AND DURATION */}
            <div className="grid grid-cols-2 gap-5  items-center">
              <div className="flex items-center mt-2 col-span-1">
                <p className="block text-black text-xs font-proxima-semibold flex-none">
                  Disease Name :
                </p>
                <div className="w-full border-b border-black">
                  <p className="block text-black text-xs font-proxima-semibold ">
                    {medicalAidFormDetails?.medicalDetails?.medicalCondition}
                  </p>
                </div>
              </div>
              <div className="flex items-center mt-2 gap-2 col-span-1">
                <p className="block text-black text-xs font-proxima-semibold flex-none">
                  Disease Duration :
                </p>
                <div className="w-full border-b border-black">
                  <p className="block text-black text-xs font-proxima-semibold ">
                    {moment(medicalAidFormDetails?.medicalDetails?.sufferingSince).format("DD/MM/YYYY")}
                  </p>
                </div>
              </div>
            </div>

            {/* HOSPITAL NAME AND DATE */}
            <div className="grid grid-cols-2 gap-5 mt-2 items-center">
              <div className="flex items-center gap-2  col-span-1">
                <p className="block text-black text-xs font-proxima-semibold flex-none">
                  Hospital Name :
                </p>
                <div className="w-full border-b border-black">
                  <p className="block text-black text-xs font-proxima-semibold ">
                    {medicalAidFormDetails?.medicalDetails?.hospitalName}
                  </p>
                </div>
              </div>
              <div className="flex items-center col-span-1 gap-2">
                <p className="block text-black text-xs font-proxima-semibold flex-none">
                  Date When Admitted :
                </p>
                <div className="w-full border-b border-black">
                  <p className="block text-black text-xs font-proxima-semibold ">
                    {moment(medicalAidFormDetails?.medicalDetails?.admittedAt).format("DD/MM/YYYY")}
                  </p>
                </div>
              </div>
            </div>

            {/* DOCTOR NAME AND HOSPITAL ADDRESS */}
            <div className="grid grid-cols-1  mt-2 items-center">
              <div className="flex items-center gap-2  col-span-1">
                <p className="block text-black text-xs font-proxima-semibold flex-none ">
                  Doctor name and hospital address
                </p>
                <div className="w-full border-b border-black">
                  <p className="block text-black text-xs font-proxima-semibold ">
                    {medicalAidFormDetails?.medicalDetails?.doctorName}
                  </p>
                </div>
              </div>
              <div className="flex items-center mt-2  col-span-1">
                <div className="w-full border-b border-black">
                  <p className="block text-black text-xs font-proxima-semibold ">
                    {medicalAidFormDetails?.medicalDetails?.hospitalAddress}
                  </p>
                </div>
              </div>
            </div>

            {/* APPROX COST AND DUPLICATE COPIES */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mt-2 items-center">
              <div className="flex items-center gap-2  col-span-1">
                <p className="block text-black text-xs font-proxima-semibold flex-none">
                  Approx cost of treatment :
                </p>
                <div className="w-full border-b border-black">
                  <p className="block text-black text-xs font-proxima-semibold ">
                    Rs {medicalAidFormDetails?.medicalDetails?.costOfTreatment}
                  </p>
                </div>
              </div>
              <div className="flex items-center  gap-2 col-span-1">
                <p className="block text-black text-xs font-proxima-semibold flex-none">
                  Attach duplicate copies for the proof
                </p>
                <div className="w-full border-b border-black">
                  <p className="block text-black text-xs font-proxima-semibold "></p>
                </div>
              </div>
            </div>

            {/* RESPONSIBLE PERSON WITH PATIENT*/}
            <div className="grid grid-cols-1  mt-2 items-center">
              <div className="flex items-center gap-2  col-span-1">
                <p className="block text-black text-xs font-proxima-semibold flex-none">
                  Name of responsible person with the patient
                </p>
                <div className="w-full border-b border-black">
                  <p className="block text-black text-xs font-proxima-semibold w-full">
                    {medicalAidFormDetails?.medicalDetails?.careTakerName}
                  </p>
                </div>
              </div>
            </div>

            {/* OUTSIDE MUMBAI*/}
            <div className="grid grid-cols-1  mt-2 ">
              <div className="flex flex-col lg:flex-row gap-2  mt-2 col-span-1">
                <p className="block text-black text-xs font-proxima-semibold flex-none">
                  If the patient is not from Mumbai, their name and address :
                </p>
                <div className="w-full border-b border-black ">
                  <p className="block text-black text-xs font-proxima-semibold w-full">
                    {medicalAidFormDetails?.medicalDetails?.careTakerName}
                  </p>
                </div>
              </div>
            </div>

            {/* CONTACT NUM*/}
            <div className="grid grid-cols-1  mt-2 items-center">
              <div className="flex items-center gap-2 col-span-1">
                <p className="block text-black text-xs font-proxima-semibold flex-none">
                  Contact No
                </p>
                <div className="w-full border-b border-black">
                  <p className="block text-black text-xs font-proxima-semibold w-full">
                    {medicalAidFormDetails?.medicalDetails?.careTakerPhoneNumber}
                  </p>
                </div>
              </div>
            </div>

            {/* MEDI CLAIM CARD DETAIL*/}
            <div className="grid grid-cols-1  mt-2 ">
              <div className="flex flex-col lg:flex-row gap-2  col-span-1">
                <p className="block text-black text-xs font-proxima-semibold flex-none">
                  Mediclaim/Government Card Details (If you have) :
                </p>
                <div className="w-full border-b border-black">
                  <p className="block text-black text-xs font-proxima-semibold w-full">
                    {medicalAidFormDetails?.medicalDetails?.healthInsuranceName?.name}{" "}
                  </p>
                </div>
              </div>
            </div>

            {/* OTHER FACILITY ALONG WITH TREATMENt*/}
            <div className="grid grid-cols-1  mt-2 items-center">
              <div className="flex items-center gap-2 col-span-1">
                <p className="block text-black text-xs font-proxima-semibold flex-none">
                  Other facilities along with treatment
                </p>
                <div className="w-full border-b border-black">
                  <p className="block text-black text-xs font-proxima-semibold w-full">
                    {medicalAidFormDetails?.medicalDetails?.previousMedicalAidFromLMP?.name}{" "}
                  </p>
                </div>
              </div>
            </div>

            {/* OTHER FACILITy*/}
            <div className="grid grid-cols-1  mt-2 items-center">
              <div className="flex flex-col lg:flex-row  gap-2 col-span-1">
                <p className="block text-black text-xs font-proxima-semibold flex-none">
                  Any other facilities provided to you. Provide details
                </p>
                <div className="w-full border-b border-black">
                  <p className="block text-black text-xs font-proxima-semibold w-full">
                    {medicalAidFormDetails?.medicalDetails?.previousMedicalAidFromLMP?.name}{" "}{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* BANK DETAILS */}
          <div className="mt-2   border border-black p-2">
            {/* PERSON NAME ON CHEQUE */}
            <div className="grid grid-cols-1 mt-2 items-center">
              <div className="flex items-center gap-2 col-span-1">
                <p className="block text-black text-xs font-proxima-semibold flex-none ">
                  What name should be written on the cheque :
                </p>
                <div className="w-full border-b border-black">
                  <p className="block text-black text-xs font-proxima-semibold ">
                    {medicalAidFormDetails?.bankDetails?.accountHolder}
                  </p>
                </div>
              </div>
            </div>

            {/* BANK NAME AND BRANCH */}
            <div className="grid grid-cols-2 gap-5 mt-2 items-center">
              <div className="flex items-center gap-2 col-span-1">
                <p className="block text-black text-xs font-proxima-semibold flex-none">
                  Bank Name :
                </p>
                <div className="w-full border-b border-black">
                  <p className="block text-black text-xs font-proxima-semibold ">
                    {medicalAidFormDetails?.bankDetails?.bankName}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-2 col-span-1">
                <p className="block text-black text-xs font-proxima-semibold flex-none">
                  Branch :
                </p>
                <div className="w-full border-b border-black">
                  <p className="block text-black text-xs font-proxima-semibold ">
                    {medicalAidFormDetails?.bankDetails?.branch}
                  </p>
                </div>
              </div>
            </div>

            {/* ACCOUNT NUM AND IFSC */}
            <div className="grid grid-cols-2 gap-5 mt-2 items-center">
              <div className="flex items-center gap-2 col-span-1">
                <p className="block text-black text-xs font-proxima-semibold flex-none">
                  Account Number :
                </p>
                <div className="w-full border-b border-black">
                  <p className="block text-black text-xs font-proxima-semibold ">
                    {medicalAidFormDetails?.bankDetails?.accountNumber}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-2  col-span-1">
                <p className="block text-black text-xs font-proxima-semibold flex-none">
                  IFSC CODE :
                </p>
                <div className="w-full border-b border-black">
                  <p className="block text-black text-xs font-proxima-semibold ">
                    {medicalAidFormDetails?.bankDetails?.ifscCode}
                  </p>
                </div>
              </div>
            </div>

            {/* DATE AND SIGNATURE */}
            <div className="grid grid-cols-2 gap-5 mt-2 items-center">
              <div className="flex items-center gap-2 col-span-1">
                <p className="block text-black text-xs font-proxima-semibold flex-none">
                  Date :
                </p>
                <div className="w-full border-b border-black">
                  <p className="block text-black text-xs font-proxima-semibold ">
                    {moment(medicalAidFormDetails?.bankDetails?.date).format("DD/MM/yyyy")}
                  </p>
                </div>
              </div>
              <div className="flex items-center  gap-2 col-span-1">
                <p className="block text-black text-xs font-proxima-semibold flex-none">
                  Signature of Patient
                </p>
                <div className="w-full border-b border-black">
                  <img src={medicalAidFormDetails?.bankDetails?.signature?.url} />
                </div>
              </div>
            </div>
          </div>

          {/* SECOND HEADER */}
          <div className="mt-2 px-4">
            <p className="font-proxima-regular font-semibold text-xs lg:text-lg">
              (અધુરૂં ફોર્મ હશે તો અરજીને ધ્યાને લેવામાં આવશે નહીં)
            </p>
            <p className="text-center text-xs lg:text-lg font-semibold font-proxima-regular">
              ફોર્મ ભરીને મુંબઈ કાર્યાલયમાં મોકલવાનું સરનામું
            </p>
            <p className="text-center text-xl lg:text-4xl font-semibold font-proxima-regular">
              SHREE LOHANA MAHAPARISHAD
            </p>
            <p className="font-proxima-regular text-center font-semibold text-xs">
              Registered under B.P.T. Act 1950 No. A/2119
            </p>
            <p className="font-proxima-regular text-center font-semibold text-xs">
              Regd. Offi.: C/o. Lohana Kanya Shala, Netaji Subhash Chandra Bose
              Road, Near Mulund Police Station,
            </p>
            <p className="font-proxima-regular text-center font-semibold text-xs">
              Mulund (W), Mumbai-400 080 (INDIA)ोन नं: +9122 2561 7350-Helpline:
              +91 7977 958330
            </p>
            <p className="font-proxima-regular text-center font-semibold text-xs">
              Email: contact@lohanamahaparishad.org Website:
              www.lohanamahaparishad.org
            </p>
            <p className="font-proxima-regular text-center font-semibold text-xs text-greyText">
              Branch Offi.: 610, Colonnade, Opp. Iscon BRTS Bus Stand, Ambli
              Road, Bopal, Ahmedabad-380058. Mob.: +91 99099 99308
            </p>
          </div>

          {/* FAMILY MEMBERS LIST */}
          <div className="mt-10 border border-black p-2">
            <DataTable
              columns={columns}
              data={medicalAidFormDetails?.familyDetails}
              responsive
              className="custom-datatable overflow-hidden"
            />
          </div>

          {/* Location Mahajanshri  */}
          <div className="mt-2   border border-black p-2">
            {/* NAME */}
            <div className="flex items-center mt-2  gap-2">
              <p className="block text-black text-xs font-proxima-semibold flex-none">
                Location Mahajanshri name :
              </p>
              <div className="w-full border-b border-black">
                <p className="block text-black text-xs font-proxima-semibold ">
                  {medicalAidFormDetails?.mahajanDetails?.nearestMahajan}
                </p>
              </div>
            </div>

            <div className="flex items-center mt-2 gap-4">
              <div className="text-xs  font-proxima-semibold">
                <p>President/Commitee</p>
                <p>Shri Lohana Mahaparishad</p>
              </div>
              <div>
                <p className="text-xs font-proxima-regular ">To,</p>
                <p className="text-xs font-proxima-regular ">The Chairman,</p>
                <p className="text-xs font-proxima-regular ">
                  Healthcare Committee,
                </p>
                <p className="text-xs font-proxima-regular ">

                </p>
              </div>
            </div>

            <div className="mt-2 text-start font-proxima-semibold text-xs">
              <p>
                We know the patient as well as their family and kindly request
                you to help them as their financial conditions are weak.
              </p>
            </div>

            <div className="mt-2 font-proxima-semibold text-xs text-start px-4">
              This is to cerifty that Mr/Mrs{" "}
              <span className=" px-4 underline underline-offset-2">
                {" "}
                {medicalAidFormDetails?.mahajanDetails?.name} {" "}
              </span>
              , aged{" "}
              <span className=" px-4  underline underline-offset-2">
                {medicalAidFormDetails?.mahajanDetails?.age}  {" "}
                {" "}
              </span>{" "}
              living in
              <span className=" px-4  underline underline-offset-2">
                {medicalAidFormDetails?.mahajanDetails?.livingIn}  {" "}
                {" "}
              </span>{" "}
              (city/town/village) undergoing treatment for{" "}
              <span className="px-4  underline underline-offset-2">
                {" "}
                {medicalAidFormDetails?.mahajanDetails?.illness} {" "}
              </span>{" "}
              is known to us. Since their financial condition is weak, we
              request you to kindly provide monetary asistance to them.
            </div>

            <hr className="h-1 bg-black mt-2" />

            {/* LOCAL MAHAJAN SIGN, CONTACT AND PRESIDENT SIGN */}
            <div className="grid grid-cols-3 mt-2 justify-between text-xs lg:text-lg">
              <div className="col-span-1">
                <div>Put sign here</div>
                <div>
                  <p className="font-proxima-semibold">
                    Stamp/Seal of Mahajan
                  </p>
                  <br />
                </div>
              </div>
              <div className="col-span-1">
                {/* <span className="px-4">
                  _____________
                </span> */}
                <div>
                  <p className="font-proxima-semibold">Contact Number</p>
                </div>
              </div>
              <div className="col-span-1">
                <div>Signature of President/Secretary</div>
                <div>
                  {/* <span className="px-4">
                  _____________
                </span> */}
                </div>
              </div>
            </div>
          </div>

          {/* A LETTER OF REQ */}
          <div className="mt-2   border border-black p-2">
            <div>
              <p className="text-xs lg:text-lg font-proxima-bold text-center">
                A letter of request from the designated member of Shree Lohana
                Mahaparishad
              </p>
              <p className="text-xs font-proxima-semibold text-center">
                Recommendation of Zonal/Regional Office Bearer of Shree Lohana
                Mahaparishad
              </p>
            </div>
            {/* NAME */}
            <div className="flex items-center gap-2 mt-2 ">
              <p className="block text-black text-xs font-proxima-semibold flex-none">
                Name :
              </p>
              <div className="w-full border-b border-black">
                <p className="block text-black text-xs font-proxima-semibold ">
                  {medicalAidFormDetails?.zonalRegionOfficeDetails?.name}
                </p>
              </div>
            </div>

            {/* ADDRESS */}
            <div className="flex items-center gap-2 mt-2 ">
              <p className="block text-black text-xs font-proxima-semibold flex-none">
                Address :
              </p>
              <div className="w-full border-b border-black">
                <p className="block text-black text-xs font-proxima-semibold ">
                  {medicalAidFormDetails?.zonalRegionOfficeDetails?.address}  {" "}
                </p>
              </div>
            </div>

            {/* DESIGNATED MEMBER*/}
            <div className="flex flex-col lg:flex-row  gap-2 mt-2 ">
              <p className="block text-black text-xs font-proxima-semibold flex-none">
                If their is any local designated mahajan, enter their details
              </p>
              <div className="w-full border-b border-black">
                <p className="block text-black text-xs font-proxima-semibold ">
                  {medicalAidFormDetails?.zonalRegionOfficeDetails?.designationInMahaparishad} {" "}
                </p>
              </div>
            </div>

            {/* ROLE IN COMMUNITY AND SIGN*/}
            <div className="grid grid-cols-2 gap-5 mt-2 ">
              <div className="flex items-center gap-2 col-span-1">
                <p className="block text-black text-xs font-proxima-semibold flex-none">
                  Your role in community
                </p>
                <div className="w-full border-b border-black">
                  <p className="block text-black text-xs font-proxima-semibold ">
                    {medicalAidFormDetails?.zonalRegionOfficeDetails?.designation} {" "}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-2 col-span-1">
                <p className="block text-black text-xs font-proxima-semibold flex-none">
                  Sign
                </p>
                <div className="w-full border-b border-black">
                  <p className="block text-black text-xs font-proxima-semibold ">
                    {" "}
                    <br />
                    <br />

                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* REQ FROM PANEL OF DOCTORS*/}
          <div className="mt-2   border border-black p-2">
            <p className="font-proxima-semibold text-center text-xl">
              Request from the panel of doctors
            </p>
            <textarea
              value=""
              rows={5}
              disabled
              className="block w-full bg-white border border-[#E5E5E5] rounded-lg px-4 py-3 font-proxima-semibold placeholder:font-proxima-regular text-xs placeholder:text-xs"
            />
          </div>

          {/* OFFICE USE*/}
          <div className="mt-2   border border-black p-2">
            <p className="font-proxima-semibold text-center text-xl">
              Only for office use
            </p>
            {/* INTERVIEW DATE AND  */}
            <div className="grid grid-cols-2 gap-5 mt-2  items-center">
              <div className="flex items-center gap-2 col-span-1">
                <p className="block text-black text-xs font-proxima-semibold flex-none">
                  Interview Date :
                </p>
                <div className="w-full border-b border-black">
                  <p className="block text-black text-xs font-proxima-semibold ">
                    {moment(medicalAidFormDetails?.officeDetails?.interviewDate).format("DD/MM/YYYY")}{" "}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-2 col-span-1">
                <p className="block text-black text-xs font-proxima-semibold flex-none">
                  મંજુર સહાય
                </p>
                <div className="w-full border-b border-black">
                  <p className="block text-black text-xs font-proxima-semibold ">
                    {medicalAidFormDetails?.officeDetails?.grantedAssistance} {" "}
                  </p>
                </div>
              </div>
            </div>

            {/* INTERVIEWER NAME */}
            <div className="flex items-center mt-2 gap-2 col-span-1">
              <p className="block text-black text-xs font-proxima-semibold flex-none">
                Interviewer Name :
              </p>
              <div className="w-full border-b border-black">
                <p className="block text-black text-xs font-proxima-semibold ">
                  {medicalAidFormDetails?.officeDetails?.interviewName} {" "}
                </p>
              </div>
            </div>

            {/* CHECQUE NAME AND NUM */}
            <div className="grid grid-cols-2 gap-5 mt-2  items-center">
              <div className="flex items-center gap-2  col-span-1">
                <p className="block text-black text-xs font-proxima-semibold flex-none">
                  Name on the cheqye :
                </p>
                <div className="w-full border-b border-black">
                  <p className="block text-black text-xs font-proxima-semibold ">
                    {medicalAidFormDetails?.officeDetails?.chequeDetails?.name}{" "}
                  </p>
                </div>
              </div>
              <div className="flex items-center  gap-2 col-span-1">
                <p className="block text-black text-xs font-proxima-semibold flex-none">
                  Cheque No
                </p>
                <div className="w-full border-b border-black">
                  <p className="block text-black text-xs font-proxima-semibold ">
                    {medicalAidFormDetails?.officeDetails?.chequeDetails?.number}{" "}
                  </p>
                </div>
              </div>
            </div>

            {/* AMOUNT AND DATE */}
            <div className="grid grid-cols-2 gap-5  mt-2 items-center">
              <div className="flex items-center gap-2 col-span-1">
                <p className="block text-black text-xs font-proxima-semibold flex-none">
                  Amount :
                </p>
                <div className="w-full border-b border-black">
                  <p className="block text-black text-xs font-proxima-semibold ">
                    Rs {medicalAidFormDetails?.officeDetails?.chequeDetails?.amount}{" "}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-2 col-span-1">
                <p className="block text-black text-xs font-proxima-semibold flex-none">
                  Date:
                </p>
                <div className="w-full border-b border-black">
                  <p className="block text-black text-xs font-proxima-semibold ">
                    {moment(medicalAidFormDetails?.officeDetails?.chequeDetails?.date).format("DD/MM/YYYY")}{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-5 justify-end items-end mt-2">
              <div>Add sign here</div>
              <div>President/Treasurer Signature</div>
            </div>
          </div>

          {medicalAidFormDetails?.patientDetails?.patientImage?.url && <div className="p-5 text-start items-start">
            <p className="font-proxima-semibold  text-xl">
              Profile Image
            </p>
            <img alt="profile" className="items-center justify-center content-center" src={medicalAidFormDetails?.patientDetails?.patientImage?.url} />
          </div>}

          {medicalAidFormDetails?.patientDetails?.annualIncome?.file?.url && <div className="p-5 text-start items-start">
            <p className="font-proxima-semibold  text-xl">
              Annual Income
            </p>
            <object
              data={medicalAidFormDetails?.patientDetails?.annualIncome?.file?.url}
              className="pdf w-full mx-auto h-[90vh]"
            ></object>
          </div>}

          {medicalAidFormDetails?.recommendations?.url && <div className="p-5 text-start items-start">
            <p className="font-proxima-semibold  text-xl">
              Recommendation Letter
            </p>
            <object
              data={medicalAidFormDetails?.recommendations?.url}
              className="pdf w-full mx-auto h-[140vh]"
            ></object>
          </div>}


          {medicalAidFormDetails?.medicalDetails?.estimatedTreatmentCost?.url && <div className="p-5 text-start items-start">
            <p className="font-proxima-semibold  text-xl">
              Estimated Treatment Cost
            </p>
            <object
              data={medicalAidFormDetails?.medicalDetails?.estimatedTreatmentCost?.url}
              className="pdf w-full mx-auto h-[140vh]"
            ></object>
          </div>}


          {medicalAidFormDetails?.medicalDetails?.reports?.url && <div className="p-5 text-start items-start">
            <p className="font-proxima-semibold  text-xl">
              Reports
            </p>
            <object
              data={medicalAidFormDetails?.medicalDetails?.reports?.url}
              className="pdf w-full mx-auto h-[140vh]"
            ></object>
          </div>}


          {medicalAidFormDetails?.bankDetails?.signature?.url && <div className="p-5 text-start items-start">
            <p className="font-proxima-semibold  text-xl">
              Signature
            </p>
            <object
              data={medicalAidFormDetails?.bankDetails?.signature?.url}
              className="pdf w-full mx-auto h-[40vh]"
            ></object>
          </div>}



          {medicalAidFormDetails?.mahajanDetails?.signature?.url && <div className="p-5 text-start items-start">
            <p className="font-proxima-semibold  text-xl">
              Mahajan Signature
            </p>
            <object
              data={medicalAidFormDetails?.mahajanDetails?.signature?.url}
              className="pdf w-full mx-auto h-[140vh]"
            ></object>
          </div>}

          {medicalAidFormDetails?.mahajanDetails?.stamp?.url && <div className="p-5 text-start items-start">
            <p className="font-proxima-semibold  text-xl">
              Mahajan Stamp
            </p>
            <object
              data={medicalAidFormDetails?.mahajanDetails?.stamp?.url}
              className="pdf w-full mx-auto h-[140vh]"
            ></object>
          </div>}

          {medicalAidFormDetails?.zonalRegionOfficeDetails?.signature?.url && <div className="p-5 text-start items-start">
            <p className="font-proxima-semibold  text-xl">
              Zonal/Regional  signature
            </p>
            <object
              data={medicalAidFormDetails?.zonalRegionOfficeDetails?.signature?.url}
              className="pdf w-full mx-auto h-[140vh]"
            ></object>
          </div>}


          {medicalAidFormDetails?.officeDetails?.signature?.url && <div className="p-5 text-start items-start">
            <p className="font-proxima-semibold  text-xl">
              Office Details  signature
            </p>
            <object
              data={medicalAidFormDetails?.officeDetails?.signature?.url}
              className="pdf w-full mx-auto h-[140vh]"
            ></object>
          </div>}
        </div>


      </div>


    </section>
  );
};

export default ExportMedicalAid;
