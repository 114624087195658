import { Link } from "react-router-dom";

/* ICONS */
import { MdOutlineInventory2 } from "react-icons/md";
import { IoHeadsetOutline } from "react-icons/io5";
import { RiAdvertisementLine } from "react-icons/ri";
import { TbBrandGoogleHome } from "react-icons/tb";
import { FaBookMedical } from "react-icons/fa6";


/* ASSETS */
import Logo from "assets/images/logo.png";

const Sidebar = () => {
  var pathname = window.location.pathname;

  return (
    <section className="h-screen w-sidebar bg-black transition-all duration-300 ease-linear">
      {/* HEADER */}
      <div className="h-header border-b border-white border-opacity-20 px-5 flex items-center">
        <img
          src={Logo}
          className="w-14 h-auto object-contain"
          alt="Lohana Mahaparishad"
        />
      </div>

      {/* NAVIGATION OPTIONS */}
      <div className="h-[90vh] flex flex-col justify-between">
        <div className="p-5">
          {/* MANAGE DETAILS */}
          <div className="mt-0">
            <h5 className="text-xs font-proxima-semibold text-white text-opacity-50">
              Manage Details
            </h5>

            {/* ORGANIZATION */}
            <Link
              to={"/organization"}
              className="w-full group flex items-center gap-3 first-of-type:mt-5 mt-7"
            >
              <MdOutlineInventory2
                className={`w-5 h-5 text-sidebarText ${(pathname ===
                  "/organization" ||
                  pathname === "/organization/add-product") &&
                  "text-white"} group-hover:text-white transition-all duration-300 ease-in-out`}
              />
              <div>
                <p
                  className={`text-base font-proxima-semibold text-sidebarText ${(pathname ===
                    "/organization" ||
                    pathname === "/organization/add-product") &&
                    "text-white"} group-hover:text-white leading-none transition-all duration-300 ease-in-out`}
                >
                  Organization
                </p>
              </div>
            </Link>

            {/* MEMBERS */}
            <Link
              to={"/members"}
              className="w-full group flex items-center gap-3 first-of-type:mt-5 mt-7"
            >
              <MdOutlineInventory2
                className={`w-5 h-5 text-sidebarText ${(pathname ===
                  "/members" ||
                  pathname === "/members/add-product") &&
                  "text-white"} group-hover:text-white transition-all duration-300 ease-in-out`}
              />
              <div>
                <p
                  className={`text-base font-proxima-semibold text-sidebarText ${(pathname ===
                    "/members" ||
                    pathname === "/members/add-product") &&
                    "text-white"} group-hover:text-white leading-none transition-all duration-300 ease-in-out`}
                >
                  Members
                </p>
              </div>
            </Link>
          </div>

          {/* MANAGE QUERIES */}
          <div className="mt-10">
            <h5 className="text-xs font-proxima-semibold text-white text-opacity-50">
              Manage Queries
            </h5>

            <Link
              to={"/contact-us"}
              className="w-full group flex items-center gap-3 first-of-type:mt-5 mt-7"
            >
              <IoHeadsetOutline
                className={`w-5 h-5 text-sidebarText ${pathname ===
                  "/contact-us" &&
                  "text-white"} group-hover:text-white transition-all duration-300 ease-in-out`}
              />
              <div>
                <p
                  className={`text-base font-proxima-semibold text-sidebarText ${pathname ===
                    "/contact-us" &&
                    "text-white"} group-hover:text-white leading-none transition-all duration-300 ease-in-out`}
                >
                  Contact Us
                </p>
              </div>
            </Link>

            <Link
              to={"/newsletter"}
              className="w-full group flex items-center gap-3 first-of-type:mt-5 mt-7"
            >
              <IoHeadsetOutline
                className={`w-5 h-5 text-sidebarText ${pathname ===
                  "/newsletter" &&
                  "text-white"} group-hover:text-white transition-all duration-300 ease-in-out`}
              />
              <div>
                <p
                  className={`text-base font-proxima-semibold text-sidebarText ${pathname ===
                    "/newsletter" &&
                    "text-white"} group-hover:text-white leading-none transition-all duration-300 ease-in-out`}
                >
                  NewsLetter
                </p>
              </div>
            </Link>

            <Link
              to={"/advertisement"}
              className="w-full group flex items-center gap-3 first-of-type:mt-5 mt-7"
            >
              <RiAdvertisementLine
                className={`w-5 h-5 text-sidebarText ${pathname ===
                  "/advertisement" &&
                  "text-white"} group-hover:text-white transition-all duration-300 ease-in-out`}
              />
              <div>
                <p
                  className={`text-base font-proxima-semibold text-sidebarText ${pathname ===
                    "/advertisement" &&
                    "text-white"} group-hover:text-white leading-none transition-all duration-300 ease-in-out`}
                >
                  Advertisement
                </p>
              </div>
            </Link>
            


            <Link
              to={"/medical-aid"}
              className="w-full group flex items-center gap-3 first-of-type:mt-5 mt-7"
            >
              <FaBookMedical
                className={`w-5 h-5 text-sidebarText ${pathname ===
                  "/medical-aid" &&
                  "text-white"} group-hover:text-white transition-all duration-300 ease-in-out`}
              />
              <div>
                <p
                  className={`text-base font-proxima-semibold text-sidebarText ${pathname ===
                    "/medical-aid" &&
                    "text-white"} group-hover:text-white leading-none transition-all duration-300 ease-in-out`}
                >
                  Medical Aid
                </p>
              </div>
            </Link>
          </div>

          {/* CONTENT MANAGEMENT */}
          <div className="mt-10 hidden">
            <h5 className="text-xs font-proxima-semibold text-white text-opacity-50">
              Content Management
            </h5>

            <Link
              to={"/cms/view/homepage"}
              className="w-full group flex items-center gap-3 first-of-type:mt-5 mt-7"
            >
              <TbBrandGoogleHome
                className={`w-5 h-5 text-sidebarText ${pathname ===
                  "/cms/view/homepage" &&
                  "text-white"} group-hover:text-white transition-all duration-300 ease-in-out`}
              />
              <div>
                <p
                  className={`text-base font-proxima-semibold text-sidebarText ${pathname ===
                    "/cms/view/homepage" &&
                    "text-white"} group-hover:text-white leading-none transition-all duration-300 ease-in-out`}
                >
                  Homepage
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Sidebar;
