/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Select from "react-select";
import { Editor } from "react-draft-wysiwyg";
import ReactImageUploading from "react-images-uploading";
import ReactDatePicker from "react-datepicker";
import { Link, useParams } from "react-router-dom";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* REACT ICONS */
import { FiTrash } from "react-icons/fi";

/* TOAST NOTIFICATION */
import { toast } from "react-toast";

/* USER DEFINED COMPONENTS */
import InputField from "../../../components/input";
import { getRegionDetailsById } from "../../../service/api";

const ViewRegion = () => {
  const { id } = useParams();
  // STATE VARIABLES
  const [loading, setLoading] = useState(false);
  const [regionDetails, setRegionDetails] = useState(null);
  const [category, setCategory] = useState();
  const [regionImages, setRegionImages] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  /* CHOOSE IMAGES */
  const onImageChange = (imageList) => {
    setRegionImages(imageList);
  };

  /* HANDLE DELETE DATA */
  const handleGetRegionDetails = () => {
    getRegionDetailsById(id)
      .then((res) => {
        toast.success(res?.message);
        setRegionDetails(res?.data);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetRegionDetails();
  }, [id]);

  return (
    <section className="px-0 py-2 xl:p-10 lg:p-10 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>View Region | Lohana Mahaparishad</title>
      </Helmet>

      <div className="container-full-width">
        {/* HEADING */}
        <div className="block xl:flex lg:flex items-center justify-between">
          <div>
            <h1 className="text-3xl font-proxima-bold text-black tracking-tight">
              View Region
            </h1>
            <h2 className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText tracking-tight">
              View filled details of region
            </h2>
          </div>
          <div className="flex items-center gap-2 mt-2 xl:mt-0 lg:mt-0">
            <Link to="/regions">
              <Button className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-white hover:bg-black hover:bg-opacity-5 px-5 py-3 rounded-md border border-authBorder text-sm font-proxima-semibold text-black normal-case transition-all duration-300 ease-in-out">
                Back to Region
              </Button>
            </Link>
          </div>
        </div>

        {/* FORM */}
        <div className="mt-5">
          <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-y-5 xl:gap-5 lg:gap-5">
            {/* DETAILS */}
            <div className="col-span-2">
              <div className="bg-white rounded-lg border border-authBorder">
                <div className="px-5 py-3 border-b border-authBorder">
                  <h5 className="text-lg font-proxima-bold text-black">
                    Region Details
                  </h5>
                  <p className="text-sm font-proxima-regular text-greyText">
                    View these region specific details
                  </p>
                </div>
                <div className="p-5">
                  <form>
                    <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
                      <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Region Name <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <InputField
                              type="text"
                              name="regionName"
                              id="regionName"
                              disabled={true}
                              value={regionDetails?.regionName}
                              placeholder="Write the region name"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Establishment Date{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <InputField
                              type="text"
                              name="stock"
                              id="stock"
                              disabled={true}
                              value={regionDetails?.establishmentDate}
                              placeholder="Write the stock in inventory"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Email Address <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <InputField
                              type="text"
                              name="regionSKU"
                              id="regionSKU"
                              disabled={true}
                              value={regionDetails?.email}
                              placeholder="Write the region sku"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Phone Number{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <InputField
                              type="text"
                              name="regionPrice"
                              id="regionPrice"
                              disabled={true}
                              value={regionDetails?.phoneNumber}
                              placeholder="Write the region price"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-span-2">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Region Description{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <Editor
                              toolbar={{ options: ["inline", "link"] }}
                              editorClassName="text-editor"
                              wrapperClassName="text-editor-wrapper"
                              placeholder="Write the region description here"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="bg-white rounded-lg border border-authBorder mt-5">
                <div className="px-5 py-3 border-b border-authBorder">
                  <h5 className="text-lg font-proxima-bold text-black">
                    Region Address
                  </h5>
                  <p className="text-sm font-proxima-regular text-greyText">
                    View these region addresses
                  </p>
                </div>
                {regionDetails?.address?.length === 0 && <>
                  <div className="p-5">
                    <p className="text-base font-proxima-regular text-greyText">
                      No Addresses found
                    </p></div></>}

                {regionDetails?.address?.length !== 0 && regionDetails?.address?.map((address, index) => {
                  return <div className={index !== 0 ? "p-5 border-t-2" : "p-5"}>
                    <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
                      <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Region Name <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <InputField
                              type="text"
                              name="regionName"
                              id="regionName"
                              disabled={true}
                              value={regionDetails?.regionName}
                              placeholder="Write the region name"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Establishment Date{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <InputField
                              type="text"
                              name="stock"
                              id="stock"
                              disabled={true}
                              value={regionDetails?.establishmentDate}
                              placeholder="Write the stock in inventory"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Email Address <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <InputField
                              type="text"
                              name="regionSKU"
                              id="regionSKU"
                              disabled={true}
                              value={regionDetails?.email}
                              placeholder="Write the region sku"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Phone Number{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <InputField
                              type="text"
                              name="regionPrice"
                              id="regionPrice"
                              disabled={true}
                              value={regionDetails?.phoneNumber}
                              placeholder="Write the region price"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-span-2">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Region Description{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <Editor
                              toolbar={{ options: ["inline", "link"] }}
                              editorClassName="text-editor"
                              wrapperClassName="text-editor-wrapper"
                              placeholder="Write the region description here"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                })}
              </div>
            </div>

            {/* IMAGES  */}
            <div className="col-span-1">

              {/* DETAILS */}
              <div className="bg-white rounded-lg border border-authBorder">
                <div className="px-5 py-3 border-b border-authBorder">
                  <h5 className="text-lg font-proxima-bold text-black">
                    Region Insights
                  </h5>
                  <p className="text-sm font-proxima-regular text-greyText">
                    View region type and website URL
                  </p>
                </div>
                <div className="p-5">
                  <div className="form-group">
                    <label className="text-sm font-proxima-semibold text-black">
                      Region Type{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <Select
                        options={[{ label: "Mahajan", value: "Mahajan" }, { label: "Charitable Trust", value: "Charitable Trust" }, { label: "Community Group", value: "Community Group" },
                        { label: "Hostel", value: "Hostel" }
                        ]}
                        classNamePrefix="dropdown"
                        placeholder="Select category"
                        name="regionCategory"
                        id="regionCategory"
                        value={{ label: regionDetails?.type, value: regionDetails?.type }}
                        isDisabled={true}
                      />
                    </div>
                  </div>
                  <div className="mt-5 grid grid-cols-2 gap-5">
                    <div className="col-span-2 ">
                      <div className="form-group">
                        <label className="text-sm font-proxima-semibold text-black">
                          Website URL{" "}
                          <span className="text-red-500">*</span>
                        </label>
                        <div className="mt-1">
                          <InputField
                            type="text"
                            name="regionPrice"
                            id="regionPrice"
                            disabled={true}
                            value={regionDetails?.websiteUrl}
                            placeholder="Write the region price"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* IMAGES */}
              <div className="bg-white rounded-lg border border-authBorder mt-5">
                <div className="px-5 py-3 border-b border-authBorder">
                  <h5 className="text-lg font-proxima-bold text-black">
                    Region Images
                  </h5>
                  <p className="text-sm font-proxima-regular text-greyText">
                    Attach the Logo images for this region
                  </p>
                </div>
                <div className="p-5">
                  <div className="grid grid-cols-2 xl:grid-cols-3 lg:grid-cols-3 gap-5">

                    {regionDetails?.regionImage ? <div className="col-span-1 relative">
                      <img
                        src={regionDetails?.regionImage}
                        className="w-full h-regionImage object-cover rounded-md"
                        alt="Region"
                      />
                    </div> : <div className="col-span-1">
                      <div
                        className="w-full h-regionImage rounded-md bg-[#F5F5F5] bg-opacity-50 border border-authBorder cursor-pointer hover:border-logoYellow transition-all duration-300 ease-in-out p-5"

                      >
                        {/* <AiFillPlusCircle className="w-8 h-8 text-greyText mx-auto" /> */}
                        <div className="mt-2">
                          <p className="text-s font-proxima-semibold text-greyText text-center">
                            No Images found
                          </p>
                          {/* <p className="text-xs font-proxima-regular text-greyText text-center mt-1">
                                (JPG, JPEG, PNG)
                              </p> */}
                        </div>
                      </div>
                    </div>}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ViewRegion;
