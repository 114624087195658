/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Select from "react-select";
import { Editor } from "react-draft-wysiwyg";
import ReactImageUploading from "react-images-uploading";
import ReactDatePicker from "react-datepicker";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useFormik } from "formik";
import * as yup from "yup";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Button, Dialog,
  DialogBody,
} from "@material-tailwind/react";

/* REACT ICONS */
import { FiTrash } from "react-icons/fi";
import { AiFillPlusCircle } from "react-icons/ai";
import { IoClose } from "react-icons/io5";

/* TOAST NOTIFICATION */
import { toast } from "react-toast";

/* USER DEFINED COMPONENTS */
import InputField from "../../../components/input";
import { getOrganizationDetailsById, postOrganizationDetails } from "../../../service/api";
import AddAddressFormComponent from "../../../components/forms/addAddress";

/* VALIDATION SCHEMA */
const validationSchema = yup.object().shape({
  organizationName: yup.string().required(),
  type: yup.string().required(),
  address: yup.array(),
  email: yup.string().email(),
  phoneNumber: yup.string(),
  websiteUrl: yup.string(),
  establishmentDate: yup.string()
});


const AddOrganization = () => {

  const navigate = useNavigate();

  // STATE VARIABLES
  const [loading, setLoading] = useState(false);
  const [organizationDetails, setOrganizationDetails] = useState(null);
  const [addressList, setAddressList] = useState([]);
  const [organizationImages, setOrganizationImages] = useState([]);

  const [deleteImage, setDeleteImage] = useState(false);

  const [addAddressDialog, setAddressDialog] = useState(false);


  /* CHOOSE IMAGES */
  const onImageChange = (imageList) => {
    setOrganizationImages(imageList);
  };

  // FORMIK REGISTRATION FUNCTION
  const formik = useFormik({
    initialValues: {
      organizationName: "",
      type: "",
      address: "",
      email: "",
      phoneNumber: "",
      websiteUrl: "",
      establishmentDate: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      const formData = new FormData();

      formData.append("organizationName", values?.organizationName);

      formData.append("type", values?.type);

      formData.append("address", JSON.stringify(values?.address));

      if (values?.email) {
        formData.append("email", values?.email);
      }
      if (values?.phoneNumber) {
        formData.append("phoneNumber", values?.phoneNumber);
      }
      if (values?.websiteUrl) {
        formData.append("websiteUrl", values?.websiteUrl);
      }

      if (values?.establishmentDate) {
        formData.append("establishmentDate", values?.establishmentDate);
      }

      if (organizationImages?.[0]?.file) {
        formData.append("image", organizationImages?.[0]?.file);
      }
      console.log("Values", values)

      postOrganizationDetails(formData)
        .then((res) => {
          console.log("res", res);
          if (res?.status) {
            toast.success(res?.message);
            navigate("/organization/edit-organization/" + res?.data)
            // dispatch(actions.setToken(res?.data?.token));
            // dispatch(actions.setUser(res?.data?.userData));
            // dispatch(actions.setLoggedIn(true));
          } else {
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          setLoading(false);
          console.log("er", e?.response?.data?.message);
          if (e?.response?.data?.message) {
            toast.error(e?.response?.data?.message);
          } else {
            toast.error(e?.message);
          }
        })
        .finally((res) => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    if (organizationDetails) {
      formik.setFieldValue("organizationName", organizationDetails?.organizationName);
      formik.setFieldValue("type", organizationDetails?.type);
      formik.setFieldValue("address", organizationDetails?.address);
      setAddressList(organizationDetails?.address)
      formik.setFieldValue("email", organizationDetails?.email);
      formik.setFieldValue("phoneNumber", organizationDetails?.phoneNumber);
      formik.setFieldValue("websiteUrl", organizationDetails?.websiteUrl);
      formik.setFieldValue("establishmentDate", organizationDetails?.establishmentDate);
      formik.setFieldValue("previousImage", organizationDetails?.organizationImage);
    }
  }, [organizationDetails])

  useEffect(() => {
    formik.setFieldValue("address", addressList);
  }, [addressList])

  console.log("formik", formik?.values)
  /* ADDRESS BOX */
  const AddressBox = (props) => {
    return (
      <div className="border border-greyColor shadow p-5 rounded-lg">
        <div>
          <h5 className="text-base font-proxima-bold text-black">
            {props.addressDetails.addressLine1}, {props.addressDetails.addressLine2}
          </h5>
          <h5 className="text-sm font-proxima-regular text-headerGrey">
            {props.addressDetails.city}, {props.addressDetails.taluka}, {props.addressDetails.state} - {props.addressDetails.zipCode}, {props.addressDetails.country}
          </h5>
        </div>
        <div className="w-10 h-[0.1vh] bg-gray-400 mt-3" />
        <div className="mt-3">
          <h5 className="text-sm font-proxima-regular text-headerGrey">
            {props.addressDetails.type}
          </h5>
          <h5 className="text-sm font-proxima-regular text-headerGrey">
            {props.addressDetails.defaultAddress ? "Yes" : "No"}
          </h5>
        </div>
        {/* <div className="mt-3 flex items-center">
          <p className="cursor-pointer text-headerGrey font-proxima-regular italic text-sm hover:underline mr-5">
            Edit
          </p>
          <p className="cursor-pointer text-red-500 font-proxima-regular italic text-sm hover:underline">
            Delete
          </p>
        </div> */}
      </div>
    );
  };

  return (
    <section className="px-0 py-2 xl:p-10 lg:p-10 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Add Organization | Lohana Mahaparishad</title>
      </Helmet>

      <form onSubmit={formik.handleSubmit}>
        <div className="container-full-width">
          {/* HEADING */}
          <div className="block xl:flex lg:flex items-center justify-between">
            <div>
              <h1 className="text-3xl font-proxima-bold text-black tracking-tight">
                Add Organization
              </h1>
              <h2 className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText tracking-tight">
                Add organization details for website
              </h2>
            </div>
            <div className="flex items-center gap-2 mt-2 xl:mt-0 lg:mt-0">
              <Link to="/organization">
                <Button className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-white hover:bg-black hover:bg-opacity-5 px-5 py-3 rounded-md border border-authBorder text-sm font-proxima-semibold text-black normal-case transition-all duration-300 ease-in-out">
                  Back to Organization
                </Button>
              </Link>
              <Button type="submit" disabled={loading} className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md border border-logoYellow text-sm font-proxima-semibold text-white normal-case transition-all duration-300 ease-in-out">
                {loading ? "Please wait..." : "Save details"}
              </Button>
            </div>
          </div>

          {/* FORM */}
          <div className="mt-5">
            <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-y-5 xl:gap-5 lg:gap-5">
              {/* DETAILS */}
              <div className="col-span-2">
                <div className="bg-white rounded-lg border border-authBorder">
                  <div className="px-5 py-3 border-b border-authBorder">
                    <h5 className="text-lg font-proxima-bold text-black">
                      Organization Details
                    </h5>
                    <p className="text-sm font-proxima-regular text-greyText">
                      Edit these organization specific details
                    </p>
                  </div>
                  <div className="p-5">
                    <form>
                      <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
                        <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Organization Name <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <InputField
                                type="text"
                                name="organizationName"
                                id="organizationName"
                                value={formik.values.organizationName}
                                onChange={formik.handleChange}
                                error={formik.errors.organizationName && formik.touched.organizationName ? true : false}
                                placeholder="Write the organization name"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Establishment Date{" "}
                              <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <InputField
                                type="text"
                                name="establishmentDate"
                                id="establishmentDate"
                                value={formik.values.establishmentDate}
                                onChange={formik.handleChange}
                                error={formik.errors.establishmentDate && formik.touched.establishmentDate ? true : false}
                                placeholder="Write the establishment date"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Email Address <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <InputField
                                type="email"
                                name="email"
                                id="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.errors.email && formik.touched.email ? true : false}
                                placeholder="Write the organization sku"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Phone Number{" "}
                              <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <InputField
                                type="text"
                                name="phoneNumber"
                                id="phoneNumber"
                                value={formik.values.phoneNumber}
                                onChange={formik.handleChange}
                                error={formik.errors.phoneNumber && formik.touched.phoneNumber ? true : false}
                                placeholder="Write the organization price"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-span-2">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Organization Description{" "}
                              <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <Editor
                                toolbar={{ options: ["inline", "link"] }}
                                editorClassName="text-editor"
                                wrapperClassName="text-editor-wrapper"
                                placeholder="Write the organization description here"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="bg-white rounded-lg border border-authBorder mt-5">

                  <div className="block xl:flex lg:flex items-center justify-between px-5 py-3 border-b border-authBorder">
                    <div>
                      <h5 className="text-lg font-proxima-bold text-black">
                        Organization Address
                      </h5>
                      <p className="text-sm font-proxima-regular text-greyText">
                        Edit these organization addresses
                      </p>
                    </div>
                    <div className="flex items-center gap-2 mt-2 xl:mt-0 lg:mt-0">
                      <Button onClick={() => setAddressDialog(!addAddressDialog)} className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-white hover:bg-black hover:bg-opacity-5 px-5 py-3 rounded-md border border-authBorder text-sm font-proxima-semibold text-black normal-case transition-all duration-300 ease-in-out">
                        Add Address
                      </Button>

                    </div>
                  </div>


                  {addressList?.length === 0 && <>
                    <div className="p-5">
                      <p className="text-base font-proxima-regular text-greyText">
                        No Addresses found
                      </p></div></>}

                  {addressList?.length !== 0 && addressList?.map((address, index) => {
                    return <AddressBox
                      addressDetails={address}
                    />
                  })}
                </div>
              </div>

              {/* IMAGES  */}
              <div className="col-span-1">

                {/* DETAILS */}
                <div className="bg-white rounded-lg border border-authBorder">
                  <div className="px-5 py-3 border-b border-authBorder">
                    <h5 className="text-lg font-proxima-bold text-black">
                      Organization Insights
                    </h5>
                    <p className="text-sm font-proxima-regular text-greyText">
                      Edit organization type and website URL
                    </p>
                  </div>
                  <div className="p-5">
                    <div className="form-group">
                      <label className="text-sm font-proxima-semibold text-black">
                        Organization Type{" "}
                        <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-1">
                        <Select
                          options={[{ label: "Mahajan", value: "Mahajan" }, { label: "Charitable Trust", value: "Charitable Trust" }, { label: "Community Group", value: "Community Group" },
                          { label: "Hostel", value: "Hostel" }
                          ]}
                          classNamePrefix={formik.errors.type && formik.touched.type ? "dropdown_error" : "dropdown"}
                          placeholder="Select type"
                          name="type"
                          id="type"
                          value={{ label: formik.values.type, value: formik.values.type }}
                          onChange={(e) => formik.setFieldValue("type", e?.value)}
                        />
                      </div>
                    </div>
                    <div className="mt-5 grid grid-cols-2 gap-5">
                      <div className="col-span-2 ">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Website URL{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <InputField
                              type="text"
                              name="websiteUrl"
                              id="websiteUrl"
                              onChange={formik.handleChange}
                              value={formik?.values?.websiteUrl}
                              placeholder="Write the organization price"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* IMAGES */}
                <div className="bg-white rounded-lg border border-authBorder mt-5">
                  <div className="px-5 py-3 border-b border-authBorder">
                    <h5 className="text-lg font-proxima-bold text-black">
                      Organization Images
                    </h5>
                    <p className="text-sm font-proxima-regular text-greyText">
                      Attach the Logo images for this organization
                    </p>
                  </div>
                  <div className="p-5">
                    <ReactImageUploading
                      multiple
                      value={organizationImages}
                      onChange={onImageChange}
                      maxNumber={5}
                      dataURLKey="data_url"
                    >
                      {({ imageList, onImageUpload, onImageRemove }) => (
                        <div className="grid grid-cols-2 xl:grid-cols-3 lg:grid-cols-3 gap-5">
                          <div className="col-span-1">
                            <div
                              className="w-full h-productImage rounded-md bg-[#F5F5F5] bg-opacity-50 border border-authBorder cursor-pointer hover:border-logoYellow transition-all duration-300 ease-in-out p-5"
                              onClick={onImageUpload}
                            >
                              <AiFillPlusCircle className="w-8 h-8 text-greyText mx-auto" />
                              <div className="mt-2">
                                <p className="text-xs font-proxima-semibold text-greyText text-center">
                                  Click here to upload images
                                </p>
                                <p className="text-xs font-proxima-regular text-greyText text-center mt-1">
                                  (JPG, JPEG, PNG)
                                </p>
                              </div>
                            </div>
                          </div>
                          {imageList.map((image, index) => (
                            <div className="col-span-1 relative" key={index}>
                              <img
                                src={image["data_url"]}
                                className="w-full h-productImage object-cover rounded-md"
                                alt="Product"
                              />
                              <div className="absolute top-2 right-2">
                                <Button
                                  className="p-2 rounded-full shadow-none hover:shadow-none bg-white border border-logoYellow"
                                  onClick={() => onImageRemove(index)}
                                >
                                  <FiTrash className="w-3 h-3 text-black" />
                                </Button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </ReactImageUploading>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      {/* VIEW CATEGORY DIALOG */}
      <Dialog
        open={addAddressDialog}
        handler={() => setAddressDialog(!addAddressDialog)}
        size="xs"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <DialogBody className="p-0">
          <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
            <h5 className="text-lg font-proxima-bold text-black tracking-tight">
              Add New Address
            </h5>
            <Button
              className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
              onClick={() => setAddressDialog(!addAddressDialog)}
            >
              <IoClose className="w-4 h-4 text-greyText" />
            </Button>
          </div>
          <div className="p-5">

            <AddAddressFormComponent setAddressList={setAddressList} address={addressList} setAddressDialog={setAddressDialog} />

          </div>

        </DialogBody>
      </Dialog>

    </section>
  );
};

export default AddOrganization;
