/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-lone-blocks */
import { useState, useEffect } from "react";
import Select from "react-select";
import ReactImageUploading from "react-images-uploading";
import { toast } from "react-toast";

/* MATERIAL TAILWIND COMPONENTS */
import { Button, Dialog, DialogBody } from "@material-tailwind/react";

/* ICONS */
import { IoClose } from "react-icons/io5";
import { AiFillPlusCircle } from "react-icons/ai";
import { FiTrash } from "react-icons/fi";

/* FORM CONTROL */
import { useFormik } from "formik";
import * as yup from "yup";

/* USER DEFINED COMPONENTS */
import Input from "components/input";
import GetInTouch from "components/cms/homepage/GetInTouch";
import Advertisements from "components/cms/homepage/Advertisements";
import TeamSecond from "components/cms/homepage/TeamSecond";
import RecentCauses from "components/cms/homepage/RecentCauses";
import History from "components/cms/homepage/History";
import Counters from "components/cms/homepage/Counters";
import AboutUs from "components/cms/homepage/AboutUs";
import Hero from "components/cms/homepage/Hero";

/* API COMPONENTS */
import { editCmsDetailsById, getCmsList } from "service/api";
import { useDispatch } from "react-redux";
import actions from "redux/actions/userAction";

/* Validation Schema */
const validationSchema = yup.object().shape({
  pageName: yup.string().required("Tag Name is required."),
  moduleName: yup.string().required("Tag Name is required."),
  moduleType: yup.string().required("Tag Name is required."),
  redirectURL: yup.string(),
  description: yup.string(),
  iframe: yup.string(),
  sourceUrl: yup.string(),
  previousUrl: yup.string(),
});

/* OPTIONS */
const options = [
  { label: "Url", value: "url" },
  { label: "Upload", value: "upload" },
];

const moduleTypeOptions = [
  { label: "paragraph", value: "paragraph" },
  { label: "anchorTag", value: "anchorTag" },
  { label: "image", value: "image" },
  { label: "video", value: "video" },
  { label: "iframe", value: "iframe" },
];

const HomePage = (props) => {
  const dispatch = useDispatch();

  const [cmsList, setCmsList] = useState([]);
  const [editDialog, setEditDialog] = useState(false);
  const [editId, setEditId] = useState(null);
  const [editDetails, setEditDetails] = useState(null);
  const [editTagFormLoading, setEditTagFormLoading] = useState(false);
  const [sourceType, setSourceType] = useState(null);
  const [tagImages, setTagImages] = useState([]);

  /* FUNCTION FOR ADDING TAGS */
  const editFormik = useFormik({
    initialValues: {
      pageName: "",
      moduleName: "",
      moduleType: "",
      redirectURL: "",
      description: "",
      iframe: "",
      sourceUrl: "",
      previousUrl: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
      const formData = new FormData();
      formData.append("pageName", values?.pageName);
      formData.append("moduleName", values?.moduleName);
      formData.append("moduleType", values?.moduleType);

      if (values?.redirectURL) {
        formData.append("redirectURL", values?.redirectURL);
      }
      if (values?.description) {
        formData.append("description", values?.description);
      }
      if (values?.iframe) {
        formData.append("iframe", values?.iframe);
      }
      if (values?.sourceUrl) {
        formData.append("sourceUrl", values?.sourceUrl);
      }
      if (values?.previousUrl) {
        formData.append("previousUrl", values?.previousUrl);
      }

      // formData.append("isFeatured", values?.isFeatured?.value === "yes" ? true : false);

      // if (tagImages?.[0]?.file) {
      //     formData.append("image", tagImages?.[0]?.file);
      // }
      // for (const entry of formData.entries()) {
      //     console.log(`${entry[0]}: ${entry[1]}`);
      // }
      setEditTagFormLoading(true);
      editCmsDetailsById(editId, formData)
        .then((res) => {
          if (res?.status) {
            toast.success(res?.message);
            editFormik.handleReset();
            setTagImages([]);
            setEditDialog(false);
          } else {
            console.log(res?.message);
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          setEditTagFormLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          fetchCmsData();
          setEditTagFormLoading(false);
        });
    },
  });

  /* CHOOSE IMAGES */
  const onImageChange = (imageList) => {
    setTagImages(imageList);
  };

  const fetchCmsData = () => {
    dispatch(actions.setLoading(true));
    getCmsList({ pagination: false, pageName: "home-page" })
      .then((res) => {
        setCmsList(res?.data);
        console.log("RES", res);
      })
      .catch((err) => {
        console.log("Err", err);
      })
      .finally(() => {
        dispatch(actions.setLoading(false));
      });
  };

  useEffect(() => {
    fetchCmsData();
  }, []);

  useEffect(() => {
    if (editDetails) {
      editFormik.setFieldValue("pageName", editDetails?.pageName);
      editFormik.setFieldValue("moduleName", editDetails?.moduleName);
      editFormik.setFieldValue("moduleType", editDetails?.moduleType);
      editFormik.setFieldValue("redirectURL", editDetails?.redirectURL);
      editFormik.setFieldValue("description", editDetails?.description);
      if (editDetails?.source?.url) {
        editFormik.setFieldValue("previousUrl", editDetails?.source?.url);
      }
    }
  }, [editDetails]);

  return (
    <div className={props?.loading ? "hidden" : `w-full`}>
      {/* BANNER */}
      <Hero
        cmsList={cmsList}
        setEditDetails={setEditDetails}
        setEditId={setEditId}
        setEditDialog={setEditDialog}
        editDialog={editDialog}
      />

      {/* ABOUT US */}
      <div className="py-10">
        <AboutUs
          cmsList={cmsList}
          setEditDetails={setEditDetails}
          setEditId={setEditId}
          setEditDialog={setEditDialog}
          editDialog={editDialog}
        />
      </div>

      {/* COUNTERS */}
      <div className="pb-10">
        <Counters
          cmsList={cmsList}
          setEditDetails={setEditDetails}
          setEditId={setEditId}
          setEditDialog={setEditDialog}
          editDialog={editDialog}
        />
      </div>

      {/* HISTORY */}
      <div className="pb-10">
        <History
          cmsList={cmsList}
          setEditDetails={setEditDetails}
          setEditId={setEditId}
          setEditDialog={setEditDialog}
          editDialog={editDialog}
        />
      </div>

      {/* RECENT CAUSES */}
      <div className="py-10">
        <RecentCauses />
      </div>

      {/* KEY OFFICE BEARERS */}
      <div className="pb-10">
        <TeamSecond
          cmsList={cmsList}
          setEditDetails={setEditDetails}
          setEditId={setEditId}
          setEditDialog={setEditDialog}
          editDialog={editDialog}
        />
      </div>

      {/* ADVERTISEMENTS */}
      <div className="pb-10">
        <Advertisements
          cmsList={cmsList}
          setEditDetails={setEditDetails}
          setEditId={setEditId}
          setEditDialog={setEditDialog}
          editDialog={editDialog}
        />
      </div>

      {/* GET IN TOUCH */}
      <div className="pb-10">
        <GetInTouch />
      </div>

      {/* EDIT CMS DIALOG */}
      <Dialog
        open={editDialog}
        handler={() => {
          setEditDetails(null);
          setEditId(null);
          setEditDialog(!editDialog);
        }}
        size="s"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <DialogBody className="p-0">
          <form onSubmit={editFormik.handleSubmit}>
            <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
              <h5 className="text-lg font-proxima-bold text-black tracking-tight">
                Edit Content for{" "}
                {editFormik.values.pageName === "home-page" && "Home Page"}
              </h5>
              <Button
                className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
                onClick={() => {
                  setEditDetails(null);
                  setEditId(null);
                  setEditDialog(!editDialog);
                }}
              >
                <IoClose className="w-4 h-4 text-greyText" />
              </Button>
            </div>
            <div className="p-5 border-b border-authBorder">
              <div className="grid grid-cols-1 xl:grid-cols-6 gap-3">
                {/* MODULE NAME */}
                <div className="form-group col-span-3">
                  <label className="text-sm font-proxima-semibold text-black">
                    Module Name <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1">
                    <Input
                      type="text"
                      name="moduleName"
                      id="moduleName"
                      onChange={editFormik.handleChange}
                      value={editFormik.values.moduleName}
                      disabled={true}
                      error={
                        editFormik.errors.moduleName &&
                          editFormik.touched.moduleName
                          ? true
                          : false
                      }
                      placeholder="Write the tag moduleName"
                    />
                  </div>
                </div>
                {/* MODULE TYPE */}
                <div className="form-group col-span-3">
                  <label className="text-sm font-proxima-semibold text-black">
                    Module Type <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1">
                    <Select
                      options={moduleTypeOptions}
                      classNamePrefix={
                        editFormik.errors.moduleType &&
                          editFormik.touched.moduleType
                          ? "dropdown_error"
                          : "dropdown"
                      }
                      onChange={(event) => {
                        if (
                          event?.value === "image" ||
                          event?.value === "video"
                        ) {
                          setSourceType("url");
                        }
                        editFormik.setFieldValue("moduleType", event?.value);
                      }}
                      value={moduleTypeOptions?.find(
                        (e) => e?.value === editFormik.values.moduleType
                      )}
                      placeholder="Select"
                      name="moduleType"
                      id="moduleType"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-2 grid grid-cols-1 xl:grid-cols-6 gap-3">
                {/* SHOW AS FEATURED */}
                {(editFormik.values.moduleType === "image" ||
                  editFormik.values.moduleType === "video") && (
                    <div className="form-group col-span-3">
                      <label className="text-sm font-proxima-semibold text-black">
                        Image Source <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-1">
                        <Select
                          options={options}
                          classNamePrefix={"dropdown"}
                          onChange={(event) => setSourceType(event?.value)}
                          value={options?.find((e) => e?.value === sourceType)}
                          placeholder="Select"
                          name="status"
                          id="status"
                        />
                      </div>
                    </div>
                  )}

                {/* IMAGE SOURCE */}
                {(editFormik.values.moduleType === "image" ||
                  editFormik.values.moduleType === "video") &&
                  sourceType === "url" && (
                    <div className="form-group mt-2">
                      <label className="text-sm font-proxima-semibold text-black">
                        URL <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-1">
                        <Input
                          type="text"
                          name="sourceUrl"
                          id="sourceUrl"
                          onChange={editFormik.handleChange}
                          value={editFormik.values.sourceUrl}
                          error={
                            editFormik.errors.sourceUrl &&
                              editFormik.touched.sourceUrl
                              ? true
                              : false
                          }
                          placeholder="Write the tag sourceUrl"
                        />
                      </div>
                    </div>
                  )}

                {editFormik.values.moduleType === "anchorTag" && (
                  <div className="form-group mt-2 col-span-6">
                    <label className="text-sm font-proxima-semibold text-black">
                      Redirect URL <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <Input
                        type="text"
                        name="redirectURL"
                        id="redirectURL"
                        onChange={editFormik.handleChange}
                        value={editFormik.values.redirectURL}
                        error={
                          editFormik.errors.redirectURL &&
                            editFormik.touched.redirectURL
                            ? true
                            : false
                        }
                        placeholder="Write the tag redirectURL"
                      />
                    </div>
                  </div>
                )}

                {editFormik.values.moduleType === "iframe" && (
                  <div className="form-group mt-2 col-span-6">
                    <label className="text-sm font-proxima-semibold text-black">
                      IFRAME <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <Input
                        type="text"
                        name="iframe"
                        id="iframe"
                        onChange={editFormik.handleChange}
                        value={editFormik.values.iframe}
                        error={
                          editFormik.errors.iframe && editFormik.touched.iframe
                            ? true
                            : false
                        }
                        placeholder="Write the tag iframe"
                      />
                    </div>
                  </div>
                )}
              </div>

              {/* DESCRIPTION */}
              {(editFormik.values.moduleType === "paragraph" ||
                editFormik.values.moduleType === "anchorTag") && (
                  <div className="form-group mt-2">
                    <label className="text-sm font-proxima-semibold text-black">
                      Description <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <Input
                        type="text"
                        name="description"
                        id="description"
                        onChange={editFormik.handleChange}
                        value={editFormik.values.description}
                        error={
                          editFormik.errors.description &&
                            editFormik.touched.description
                            ? true
                            : false
                        }
                        placeholder="Write the tag description"
                      />
                    </div>
                  </div>
                )}

              {editFormik.values.moduleType === "image" &&
                sourceType === "upload" && (
                  <div className="bg-white rounded-lg border border-authBorder mt-3">
                    <div className="px-5 py-3 border-b border-authBorder">
                      <h5 className="text-sm font-proxima-bold text-black">
                        Tag Images
                      </h5>
                      <p className="text-xs font-proxima-regular text-greyText">
                        Attach the tag images for this product
                      </p>
                    </div>
                    <div className="p-5">
                      <ReactImageUploading
                        value={tagImages}
                        onChange={onImageChange}
                        maxNumber={1}
                        dataURLKey="data_url"
                      >
                        {({ imageList, onImageUpload, onImageRemove }) => (
                          <div className="grid grid-cols-2 xl:grid-cols-6 lg:grid-cols-6 gap-5">
                            <div className="col-span-3">
                              <div
                                className="w-full h-productImage rounded-md bg-[#F5F5F5] bg-opacity-50 border border-authBorder cursor-pointer hover:border-logoYellow transition-all duration-300 ease-in-out p-5"
                                onClick={onImageUpload}
                              >
                                <AiFillPlusCircle className="w-8 h-8 text-greyText mx-auto" />
                                <div className="mt-2">
                                  <p className="text-xs font-proxima-semibold text-greyText text-center">
                                    Click here to upload images
                                  </p>
                                  <p className="text-xs font-proxima-regular text-greyText text-center mt-1">
                                    (JPG, JPEG, PNG)
                                  </p>
                                </div>
                              </div>
                            </div>
                            {imageList.map((image, index) => (
                              <div className="col-span-3 relative" key={index}>
                                <img
                                  src={image["data_url"]}
                                  className="w-full h-productImage object-cover rounded-md"
                                  alt="Product"
                                />
                                <div className="absolute top-2 right-2">
                                  <Button
                                    className="p-2 rounded-full shadow-none hover:shadow-none bg-white border border-logoYellow"
                                    onClick={() => onImageRemove(index)}
                                  >
                                    <FiTrash className="w-3 h-3 text-black" />
                                  </Button>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </ReactImageUploading>
                    </div>
                  </div>
                )}

              {editFormik.values.moduleType === "video" &&
                sourceType === "upload" && (
                  <div className="bg-white rounded-lg border border-authBorder mt-3">
                    <div className="px-5 py-3 border-b border-authBorder">
                      <h5 className="text-sm font-proxima-bold text-black">
                        Module Video
                      </h5>
                      <p className="text-xs font-proxima-regular text-greyText">
                        Attach the video file for add in website
                      </p>
                    </div>
                    <div className="p-5">
                      <ReactImageUploading
                        value={tagImages}
                        onChange={onImageChange}
                        maxNumber={1}
                        dataURLKey="data_url"
                      >
                        {({ imageList, onImageUpload, onImageRemove }) => (
                          <div className="grid grid-cols-2 xl:grid-cols-6 lg:grid-cols-6 gap-5">
                            <div className="col-span-3">
                              <div
                                className="w-full h-productImage rounded-md bg-[#F5F5F5] bg-opacity-50 border border-authBorder cursor-pointer hover:border-logoYellow transition-all duration-300 ease-in-out p-5"
                                onClick={onImageUpload}
                              >
                                <AiFillPlusCircle className="w-8 h-8 text-greyText mx-auto" />
                                <div className="mt-2">
                                  <p className="text-xs font-proxima-semibold text-greyText text-center">
                                    Click here to upload images
                                  </p>
                                  <p className="text-xs font-proxima-regular text-greyText text-center mt-1">
                                    (MP4, MOV)
                                  </p>
                                </div>
                              </div>
                            </div>
                            {imageList.map((image, index) => (
                              <div className="col-span-3 relative" key={index}>
                                <img
                                  src={image["data_url"]}
                                  className="w-full h-productImage object-cover rounded-md"
                                  alt="Product"
                                />
                                <div className="absolute top-2 right-2">
                                  <Button
                                    className="p-2 rounded-full shadow-none hover:shadow-none bg-white border border-logoYellow"
                                    onClick={() => onImageRemove(index)}
                                  >
                                    <FiTrash className="w-3 h-3 text-black" />
                                  </Button>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </ReactImageUploading>
                    </div>
                  </div>
                )}
            </div>

            <div className="flex items-center justify-end px-5 py-3">
              <Button
                type="submit"
                className="text-sm shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md font-proxima-semibold text-white transition-all duration-300 ease-in-out normal-case"
              >
                {editTagFormLoading ? "Please wait.." : "Save details"}
              </Button>
            </div>
          </form>
        </DialogBody>
      </Dialog>
    </div>
  );
};

export default HomePage;
