/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import DataTable from "react-data-table-component";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Select from "react-select";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Button,
  Card,
  CardBody,
  Checkbox,
  Collapse,
  Dialog,
  DialogBody,
  Popover,
  PopoverContent,
  PopoverHandler,
  Tooltip,
  Typography,
} from "@material-tailwind/react";

/* ICONS */
import { BiEdit } from "react-icons/bi";
import { FaPhone } from "react-icons/fa6";
import { FiTrash } from "react-icons/fi";
import { IoBan, IoClose, IoEyeSharp } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { PiSlidersFill } from "react-icons/pi";

/* USER DEFINED COMPONENTS */
import Input from "../../../components/input";

/* TOAST NOTIFICATION */
import { toast } from "react-toast";

/* API IMPORT */
import {
  deleteOrganizations,
  editOrganizationsStatusById,
  getOrganizationsList,
} from "../../../service/api";

/* STATUS */
const status = [
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
];

const OrganizationList = () => {
  // STATE VARIABLES
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);

  const [organizationsList, setOrganizationsList] = useState([]);

  const [totalDocs, setTotalDocs] = useState([]);

  const [search, setSearch] = useState("");

  const [deleteId, setDeleteId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);

  const [viewFilters, setViewFilters] = useState(false);

  /* FUNCTIONS */
  const handlePageChange = async (e) => {
    setPage(e);
    getOrganizationsListFunction();
  };

  const handlePerPageChange = async (e) => {
    setSizePerPage(e);
    getOrganizationsListFunction();
  };

  const getOrganizationsListFunction = () => {
    setLoading(true);
    const params = {
      page: page,
      sizePerPage: sizePerPage,
      pagination: true,
    };
    if (search) {
      params.search = search;
    }

    getOrganizationsList(params)
      .then((res) => {
        setOrganizationsList(res?.data?.docs);
        setTotalDocs(res?.data?.totalDocs);
      })
      .catch((e) => {
        console.log("e-->", e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /* HANDLE DELETE DATA */
  const handleDelete = () => {
    deleteOrganizations(deleteId)
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        setDeleteModal(!deleteModal);
        setDeleteId(null);
        getOrganizationsListFunction();
      });
  };

  /* HANDLE STATUS CHANGE FUNCTION */
  const handleStatusChange = (editId) => {
    editOrganizationsStatusById(editId)
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        getOrganizationsListFunction();
      });
  };

  /* TABLE COLUMNS */
  const columns = [
    {
      name: "Image",
      width: "10vh",
      selector: (row) => (
        <span>
          <img
            src={
              row?.organizationImage
                ? row?.organizationImage
                : require("../../../assets/images/organization.png")
            }
            className={
              row?.organizationImage
                ? "w-14 h-14 rounded-lg object-cover"
                : "w-10 h-10 rounded-lg "
            }
            alt={row.name}
          />
        </span>
      ),
    },
    {
      name: "Name",
      width: "40vh",
      selector: (row) => (
        <span className="text-sm font-proxima-bold text-black">
          {row.organizationName}
        </span>
      ),
    },
    {
      name: "Contact Details",
      width: "30vh",
      selector: (row) => (
        <>
          <p className="text-sm font-proxima-semibold text-black text-opacity-60 flex">
            {row?.email ? (
              <>
                <MdEmail className="w-5 h-5 text-greyText" /> &nbsp;&nbsp;{" "}
                {row?.email}
              </>
            ) : (
              "No Email Address"
            )}
          </p>
          <p className="text-sm mt-2 font-proxima-semibold text-black text-opacity-60 flex">
            {row?.phoneNumber ? (
              <>
                <FaPhone className="w-4 h-4 text-greyText" />
                &nbsp;&nbsp; {row?.phoneNumber}
              </>
            ) : (
              "No Phone Number"
            )}
          </p>
        </>
      ),
    },
    {
      name: "Zone",
      width: "20vh",
      selector: (row) => (
        <span className="text-sm font-proxima-bold text-black">
          {row?.address?.[0]?.zone?.name}
        </span>
      ),
    },
    {
      name: "Type",
      width: "20vh",
      selector: (row) => (
        <span className="text-sm font-proxima-bold text-black">
          {row?.type ? row.type : "-"}
        </span>
      ),
    },
    {
      name: "Visibility",
      width: "15vh",
      selector: (row) => (
        <span
          className={
            row?.isActive
              ? "text-sm font-proxima-bold text-green-800 text-opacity-60"
              : "text-sm font-proxima-bold text-yellow-700 text-opacity-60"
          }
        >
          {row.isActive ? "Enabled" : "Disabled"}
        </span>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="flex items-center">
          <Tooltip content="View" className="text-sm font-proxima-semibold">
            <Link
              target="_blank"
              to={"/organization/view-organization/" + row?._id}
            >
              <Button className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5">
                <IoEyeSharp className="w-5 h-5 text-greyText" />
              </Button>
            </Link>
          </Tooltip>
          <Tooltip content="Edit" className="text-sm font-proxima-semibold">
            <Link
              target="_blank"
              to={"/organization/edit-organization/" + row?._id}
            >
              <Button className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5">
                <BiEdit className="w-5 h-5 text-greyText" />
              </Button>
            </Link>
          </Tooltip>
          <Tooltip content="Remove" className="text-sm font-proxima-semibold">
            <Button
              onClick={() => {
                setDeleteId(row?._id);
                setDeleteModal(!deleteModal);
              }}
              className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5"
            >
              <FiTrash className="w-5 h-5 text-greyText" />
            </Button>
          </Tooltip>
          <Tooltip
            content="Disable on website"
            className="text-sm font-proxima-semibold"
          >
            <Button
              onClick={() => {
                handleStatusChange(row?._id);
              }}
              className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5"
            >
              <IoBan className="w-5 h-5 text-greyText" />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  /* USE-EFFECTS */
  useEffect(() => {
    getOrganizationsListFunction();
  }, [page, sizePerPage, search]);

  /* LOADING SCREEN FOR DATATABLE */
  const LoadingScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-proxima-semibold text-black text-center">
          Please wait!
        </p>
        <p className="text-sm font-proxima-semibold text-black text-center">
          We are fetching data{" "}
        </p>
      </div>
    );
  };

  const NoDataScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-proxima-semibold text-black text-center">
          There are no queries to display.
        </p>
      </div>
    );
  };

  return (
    <section className="px-0 py-2 xl:p-5 lg:p-5 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Organization | Lohana Mahaparishad</title>
      </Helmet>

      <div className="container-full-width">
        <div className="block xl:flex lg:flex items-center justify-between">
          {/* HEADING */}
          <div>
            <h1 className="text-2xl font-proxima-bold text-black tracking-tight">
              Organization
            </h1>
            <h2 className="text-base font-proxima-regular text-greyText">
              Manage the organization details for your website
            </h2>
          </div>

          {/* SEARCH AND FILTERS */}
          <div className="block xl:flex lg:flex items-center gap-2">
            {/* SEARCHBAR */}
            <div className="w-full xl:w-searchBar lg:w-searchBar mt-2 xl:mt-0 lg:mt-0">
              <Input
                type="text"
                placeholder="Search..."
                name="searchInv"
                id="searchInv"
                value={search}
                onChange={(e)=>{
                  setSearch(e?.target?.value)
                }}
              />
            </div>

            {/* DROPDOWN */}
            {/* <div className="w-full xl:w-categorySelect lg:w-categorySelect mt-2 xl:mt-0 lg:mt-0">
              <Select
                options={categoryOptions}
                classNamePrefix="dropdown"
                placeholder="Select category"
                name="category"
                id="category"
              />
            </div> */}

            {/* STATUS DROPDOWN */}
            <div className="w-full xl:w-statusSelect hidden lg:w-statusSelect mt-2 xl:mt-0 lg:mt-0">
              <Select
                options={status}
                classNamePrefix="dropdown"
                placeholder="Select status"
                name="status"
                id="status"
              />
            </div>

            {/* FILTER */}
            <Popover placement="top-end" className="shadow-none hidden">
              <PopoverHandler>
                <Button
                  className=" h-full bg-white  rounded-lg flex items-center hidden shadow-none border border-gray-300 normal-case transition-all duration-300 ease-in-out"
                  onClick={() => setViewFilters(!viewFilters)}
                >
                  <PiSlidersFill color="#323232" size="13" />
                  <p className="ml-1 text-greyText">Filters</p>
                </Button>
              </PopoverHandler>
              <PopoverContent>
                <div className="grid grid-cols-1 gap-5 w-96">
                  {/* CHECKBOX */}
                  <label
                    htmlFor="checkbox"
                    className="inline-flex items-center cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      id="checkbox"
                      className=" h-5 w-5 text-black transition duration-150 ease-in-out rounded-lg"
                    />
                    <span className="ml-2 text-sm text-gray-700">
                      Check me out
                    </span>
                  </label>
                  {/* SEARCHBAR */}
                  <div className="w-full  mt-2 xl:mt-0 lg:mt-0">
                    <label
                      htmlFor="searchInv"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Search
                    </label>
                    <Input
                      type="text"
                      placeholder="Search..."
                      name="searchInv"
                      id="searchInv"
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm  sm:text-sm"
                    />
                  </div>

                  {/* SELECT */}
                  <div className="w-full  mt-4 xl:mt-0 lg:mt-0">
                    <label
                      htmlFor="status"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Status
                    </label>
                    <Select
                      options={status}
                      classNamePrefix="dropdown"
                      placeholder="Select status"
                      name="status"
                      id="status"
                      className="mt-1 block w-full   focus:outline-none  sm:text-sm"
                    />
                  </div>

                  <div className="flex justify-end">
                    <Button
                      onClick={() => setViewFilters(!viewFilters)}
                      className="bg-red-700 hover:bg-red-800 text-white font-semibold py-2 px-4 rounded inline-flex items-center"
                    >
                      Apply Filters
                    </Button>
                  </div>
                </div>
              </PopoverContent>
            </Popover>

            {/* ADD PRODUCT */}
            <Link to="/organization/add-organization">
              <Button className="w-full xl:w-auto lg:w-auto mt-2 xl:mt-0 lg:mt-0 bg-logoYellow rounded-lg py-3 px-8 text-sm font-proxima-semibold shadow-none hover:shadow-none normal-case hover:bg-opacity-80 transition-all duration-300 ease-in-out">
                Add Organization
              </Button>
            </Link>
          </div>
        </div>

        {/* INVENTORY TABLE */}
        <div className="mt-5">
          <DataTable
            className="data-table"
            columns={columns}
            data={totalDocs > 0 ? organizationsList : []}
            progressPending={loading}
            progressComponent={<LoadingScreen />}
            pagination
            paginationServer
            paginationTotalRows={totalDocs}
            onChangeRowsPerPage={handlePerPageChange}
            onChangePage={handlePageChange}
            noDataComponent={<NoDataScreen />}
          />
        </div>
      </div>

      <Dialog
        open={deleteModal}
        handler={() => setDeleteModal(!deleteModal)}
        size="xs"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <DialogBody className="p-0">
          <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
            <h5 className="text-lg font-proxima-bold text-black tracking-tight">
              Remove Organization
            </h5>
            <Button
              className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
              onClick={() => setDeleteModal(!deleteModal)}
            >
              <IoClose className="w-4 h-4 text-greyText" />
            </Button>
          </div>
          <div className="p-5 border-b border-authBorder">
            <p className="text-base font-proxima-regular text-black">
              Are you sure you want to delete this organization?
            </p>
          </div>
          <div className="flex items-center justify-end px-5 py-3">
            <Button className="text-sm shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md font-proxima-semibold text-white transition-all duration-300 ease-in-out normal-case">
              Delete
            </Button>
          </div>
        </DialogBody>
      </Dialog>
    </section>
  );
};

export default OrganizationList;
