import axios from 'axios';
import store from '../redux/store';
import { SERVER_URL } from '../config/index';

const request = axios.create({
  baseURL: SERVER_URL,
  timeout: 1000000,
});
let requests = [];
request.interceptors.request.use(
  (config) => {
    // do something before request is sent
    if (store.getState().user?.token) {
      config.headers.Authorization = `Bearer ${store.getState().user?.token}`;
    }
    requests.push(config);
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  },
);

/* Auth API */
// Login
export const adminLogin = (data) => {
  return new Promise(async (resolve, reject) => {
    await axios
      .post(SERVER_URL + "auth/login", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Register
export const adminRegister = (data) => {
  return new Promise(async (resolve, reject) => {
    await axios
      .post(SERVER_URL + "auth/register", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* Users API */
// Add User Details
export const postUserDetails = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`user/`, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get User List
export const getUsersList = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/user`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get User Details By Id
export const getUsersDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`user/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Update User Details By Id
export const editUsersDetailsById = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`user/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Update User Status By Id
export const editUsersStatusById = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch(`user/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Remove User Details By Id
export const deleteUsers = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`user/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};



/* Organizations API */
// Add Organization Details
export const postOrganizationDetails = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`organization/`, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get Organization List
export const getOrganizationsList = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/organization`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get Organization Details By Id
export const getOrganizationDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`organization/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Update Organization Details By Id
export const editOrganizationsDetailsById = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`organization/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Update Organization Status By Id
export const editOrganizationsStatusById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch(`organization/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Remove Organization Details By Id
export const deleteOrganizations = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`organization/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};


/* Zones API */
// Update Zone Details By Id
export const postZoneDetails = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`zone/`, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get Zone List
export const getZonesList = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/zone`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get Zone Details By Id
export const getZoneDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`zone/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Update Zone Details By Id
export const editZonesDetailsById = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`zone/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Update Zone Status By Id
export const editZonesStatusById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch(`zone/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Remove Zone Details By Id
export const deleteZones = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`zone/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* Regions API */
// Update Region Details By Id
export const postRegionDetails = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`region/`, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get Region List
export const getRegionsList = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/region`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get Region Details By Id
export const getRegionDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`region/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Update Region Details By Id
export const editRegionsDetailsById = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`region/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Update Region Status By Id
export const editRegionsStatusById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch(`region/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Remove Region Details By Id
export const deleteRegions = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`region/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};



/* Roles API */
// Update Role Details By Id
export const postRoleDetails = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`role/`, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get Role List
export const getRolesList = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/role`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get Role Details By Id
export const getRoleDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`role/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Update Role Details By Id
export const editRolesDetailsById = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`role/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Update Role Status By Id
export const editRolesStatusById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch(`role/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Remove Role Details By Id
export const deleteRoles = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`role/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};



/* CONTENT MANAGEMENT API */
// Add Cms Details
export const postCmsDetails = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`content-management/`, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get Cms List
export const getCmsList = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/content-management`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get Cms Details By Id
export const getCmsDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`content-management/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Update Cms Details By Id
export const editCmsDetailsById = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`content-management/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Remove Cms Details By Id
export const deleteCmsDetails = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`content-management/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};


/* Contact Us */
// Get Contact Us List
export const getContactUsList = (data, token) => {
  return new Promise(async (resolve, reject) => {
    await request.get('contact-us', { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Delete Contact Us Details By Id
export const deleteContactUsDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request.delete(`/contact-us/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};



/* NewsLetter */
// Get News Letter List
export const getNewsLetterList = (data, token) => {
  return new Promise(async (resolve, reject) => {
    await request.get('news-letter', { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Delete News Letter Details By Id
export const deleteNewsLetterDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request.delete(`/news-letter/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* Advertisement */
// Get Advertisement List
export const getAdvertisementList = (data, token) => {
  return new Promise(async (resolve, reject) => {
    await request.get('advertisement', { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Delete Advertisement Details By Id
export const deleteAdvertisementDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request.delete(`/advertisement/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};


/* MEDICAL AID API */
// Get MedicalAid List
export const getMedicalAidList = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/medical-aid`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get MedicalAid Details By Id
export const getMedicalAidDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`medical-aid/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Update MedicalAid Details By Id
export const editMedicalAidDetailsById = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`medical-aid/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Remove MedicalAid Details By Id
export const deleteMedicalAidDetails = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`medical-aid/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};