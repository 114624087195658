/* eslint-disable no-unused-vars */
/* ICONS */
import { MdSpaceDashboard, MdOutlineInventory2 } from "react-icons/md";
import { FiShoppingCart } from "react-icons/fi";
import { BsEmojiSmile } from "react-icons/bs";
import { BiCategory } from "react-icons/bi";
import { TbCategoryPlus } from "react-icons/tb";
import { LuTags } from "react-icons/lu";
import { FaInstagram } from "react-icons/fa";
import { RiCoupon2Line } from "react-icons/ri";
import { IoHeadsetOutline } from "react-icons/io5";

const navMenu = [
  {
    id: 1,
    link: "/dashboard",
    icon: MdSpaceDashboard,
    name: "Sales Dashboard",
  },
  {
    id: 2,
    link: "/customers",
    icon: BsEmojiSmile,
    name: "Customers",
  },
  {
    id: 3,
    link: "/organization",
    icon: MdOutlineInventory2,
    name: "Organization",
    secondary: "/organization/add-product",
  },
  // {
  //   id: 4,
  //   link: "/categories",
  //   icon: BiCategory,
  //   name: "Categories",
  // },
  // {
  //   id: 6,
  //   link: "/sub-categories",
  //   icon: TbCategoryPlus,
  //   name: "Sub-categories",
  // },
  // {
  //   id: 7,
  //   link: "/tags",
  //   icon: LuTags,
  //   name: "Tags",
  // },
  /* {
    id: 8,
    link: "/instagram-reels",
    icon: FaInstagram,
    name: "Instagram Reels",
  }, */
  // {
  //   id: 9,
  //   link: "/offers-and-promocodes",
  //   icon: RiCoupon2Line,
  //   name: "Offers & Promocodes",
  // },
  {
    id: 10,
    link: "/contact-us",
    icon: IoHeadsetOutline,
    name: "Contact Us",
  },
];


export default navMenu;
