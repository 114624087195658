import React from "react";

/* MATERIAL TAILWIND COMPONENTS */
import { Button, Tooltip } from "@material-tailwind/react";

/* ASSETS */
import Education from "assets/images/CMS/our-contributions/01.jpg";
import Thalasemmia from "assets/images/CMS/our-contributions/02.jpg";
import Accomodation from "assets/images/CMS/our-contributions/03.jpg";
import { BiEdit } from "react-icons/bi";

const causes = [
  {
    id: 1,
    image: Education,
    title: "Educational Scholarship",
    description: "To encourage talented students and help weaker sections.",
  },
  {
    id: 2,
    image: Thalasemmia,
    title: "Thalassemia",
    description: "Our mission is to support and fund thalassemia patients.",
  },
  {
    id: 3,
    image: Accomodation,
    title: "Accommodation",
    description: "Providing compassionate accommodation for needy patients.",
  },
];

const RecentCauses = () => {
  return (
    <div className="px-10">
      <div className="container-full-width">
        <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5 xl:gap-10 lg:gap-10 items-center">
          <div className="col-span-1">
            <h3 className="text-4xl font-sunflower-bold text-black tracking-tight leading-none xl:leading-tight lg:leading-tight">
              Our contribution to society
            </h3>
            <p className="text-lg font-proxima-regular text-greyText mt-3">
              Our commitment is to assist the less fortunate. Your contribution
              today will sow seeds for a brighter future for those in need. Join
              us in making a difference.
            </p>
            <a
              href="https://pages.razorpay.com/DonateToLMP"
              target="_blank"
              rel="noreferrer"
            >
              <Button className="mt-5 shadow hover:shadow bg-logoYellow hover:bg-opacity-70 rounded-md py-2.5 px-5 text-black font-proxima-semibold normal-case text-base transition-all duration-300 ease-in-out">
                Donate Now
              </Button>
            </a>
          </div>

          <div className="col-span-2">
            <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5">
              {causes.map((item) => (
                <div
                  className="col-span-1 border border-greyBorder rounded-lg shadow"
                  key={item.id}
                >
                  <div className="relative">
                    <img
                      src={item.image}
                      alt={item.title}
                      className="w-full h-contribution rounded-t-lg"
                    />
                    <div className="absolute top-3 right-3">
                      <Tooltip
                        content="Edit details"
                        className="text-sm font-proxima-regular text-white"
                      >
                        <Button className="shadow hover:shadow p-2 rounded-full bg-white border border-greyBorder hover:border-logoRed transition-all duration-300 ease-in-out">
                          <BiEdit className="w-5 h-5 text-greyText" />
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="p-4">
                    <h3 className="text-lg font-proxima-bold text-black">
                      {item.title}
                    </h3>
                    <p className="text-base font-proxima-regular text-greyText mt-1">
                      {item.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentCauses;
