import React from "react";

/* PACKAGES */
import PhoneInput from "react-phone-number-input";

/* MATERIAL TAILWIND COMPONENTS */
import { Button, Tooltip } from "@material-tailwind/react";
import { BiEdit } from "react-icons/bi";

const GetInTouch = () => {
  return (
    <div className="px-10">
      <div className="container-full-width">
        <div className="w-full rounded-xl bg-greyBg bg-opacity-50 border border-greyBorder shadow p-5 xl:p-10 lg:p-5">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-1 gap-20 items-center">
            {/* CONTENT SECTION */}
            <div className="col-span-1">
              <h5 className="text-4xl font-sunflower-bold tracking-tight text-black">
                Get in Touch
              </h5>
              <div className="mt-5">
                <div className="flex items-center justify-between">
                  <h5 className="text-xl font-proxima-bold text-black">
                    Visit us at our office
                  </h5>
                  <Tooltip
                    content="Edit office address"
                    className="text-sm font-proxima-regular text-white"
                  >
                    <Button className="shadow hover:shadow bg-white border border-greyBorder hover:border-logoRed rounded-full p-2 transition-all duration-300 ease-in-out">
                      <BiEdit className="w-5 h-5 text-greyText" />
                    </Button>
                  </Tooltip>
                </div>
                <p className="text-base font-proxima-regular text-black mt-1">
                  Come say hello at our office to learn more about us and how we
                  can help you
                </p>
                <a
                  href="https://maps.app.goo.gl/kNoRUPrPTiNuG88h8"
                  target="_blank"
                  rel="noreferrer"
                  className="block text-lg font-proxima-semibold text-logoRed mt-1"
                >
                  Shree Lohana Kanya Shala, Netaji Subhash Chandra Bose Road
                  Near Mulund Police Station, Mulund (W), Mumbai
                </a>
              </div>
              <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-1 gap-5 xl:gap-20 lg:gap-5 mt-5">
                <div className="col-span-1">
                  <div className="mt-0">
                    <div className="flex items-start justify-between">
                      <div className="w-4/5">
                        <h5 className="text-xl font-proxima-bold text-black">
                          Chat with us
                        </h5>
                        <p className="text-base font-proxima-regular text-black mt-1">
                          Our members are here to help you for your well being.
                        </p>
                      </div>
                      <Tooltip
                        content="Update email address"
                        className="text-sm font-proxima-regular text-white"
                      >
                        <Button className="shadow hover:shadow bg-white border border-greyBorder hover:border-logoRed rounded-full p-2 transition-all duration-300 ease-in-out">
                          <BiEdit className="w-5 h-5 text-greyText" />
                        </Button>
                      </Tooltip>
                    </div>
                    <a
                      href="mailto:contact@lohanamahaparishad.org"
                      target="_blank"
                      rel="noreferrer"
                      className="block text-lg font-proxima-semibold text-logoRed mt-1"
                    >
                      contact@lohanamahaparishad.org
                    </a>
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="mt-0">
                    <div className="flex items-start justify-between">
                      <div className="w-4/5">
                        <h5 className="text-xl font-proxima-bold text-black">
                          Give us a call
                        </h5>
                        <p className="text-base font-proxima-regular text-black mt-1">
                          Call us to get help with what you need and we will
                          help you.
                        </p>
                      </div>
                      <Tooltip
                        content="Update phone number"
                        className="text-sm font-proxima-regular text-white"
                      >
                        <Button className="shadow hover:shadow bg-white border border-greyBorder hover:border-logoRed rounded-full p-2 transition-all duration-300 ease-in-out">
                          <BiEdit className="w-5 h-5 text-greyText" />
                        </Button>
                      </Tooltip>
                    </div>
                    <a
                      href="tel:+919909999308"
                      target="_blank"
                      rel="noreferrer"
                      className="block text-lg font-proxima-semibold text-logoRed mt-1"
                    >
                      +91 9909999308
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {/* CONTACT US FORM */}
            <div className="col-span-1 mt-5 xl:mt-0 lg:mt-5">
              <form className="w-full xl:w-full lg:w-full mx-auto">
                {/* FIRST ROW */}
                <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
                  <div className="col-span-1">
                    <label
                      className="block text-black text-sm font-proxima-semibold"
                      htmlFor="firstName"
                    >
                      First Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      className="block w-full border border-greyBorder rounded-lg px-4 py-2 mt-1 font-proxima-semibold placeholder:font-proxima-regular text-base placeholder:text-sm"
                      placeholder="First Name"
                    />
                  </div>
                  <div className="col-span-1">
                    <label
                      className="block text-black text-sm font-proxima-semibold"
                      htmlFor="lastName"
                    >
                      Last Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      id="lastName"
                      className="block w-full border border-greyBorder rounded-lg px-4 py-2 mt-1 font-proxima-semibold placeholder:font-proxima-regular text-base placeholder:text-sm"
                      placeholder="Last Name"
                    />
                  </div>
                </div>

                {/* SECOND ROW */}
                <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5 mt-5">
                  <div className="col-span-1">
                    <label
                      className="block text-black text-sm font-proxima-semibold"
                      htmlFor="email"
                    >
                      Email address <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="block w-full border border-greyBorder rounded-lg px-4 py-2 mt-1 font-proxima-semibold placeholder:font-proxima-regular text-base placeholder:text-sm"
                      placeholder="Email address"
                    />
                  </div>
                  <div className="col-span-1">
                    <label className="block text-sm font-proxima-semibold text-black">
                      Phone <span className="text-red-500">*</span>
                    </label>
                    <PhoneInput
                      placeholder="Phone"
                      defaultCountry="IN"
                      className="mt-1"
                      name="phoneNumber"
                    />
                  </div>
                </div>

                {/* THID ROW */}
                <div className="mt-5">
                  <label className="text-sm font-proxima-semibold text-black">
                    Message <span className="text-red-500">*</span>
                  </label>
                  <textarea
                    rows={3}
                    className="block w-full border border-greyBorder rounded-lg px-4 py-2 mt-1 font-proxima-semibold placeholder:font-proxima-regular text-base placeholder:text-sm"
                    placeholder="Write your message"
                    name="message"
                  />
                </div>

                <Button className="mt-5 shadow-none hover:shadow-none text-sm text-white bg-logoRed normal-case font-proxima-semibold font-normal rounded-lg py-3">
                  Submit details
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
