import React from "react";

import { Button, Tooltip } from "@material-tailwind/react";
import { BiEdit } from "react-icons/bi";

/* ASSETS */
import Advertisement01 from "assets/images/CMS/advertisements/banner-01.jpg";
import Advertisement02 from "assets/images/CMS/advertisements/banner-02.jpg";

const Advertisements = (props) => {
  const cmsList = props?.cmsList;

  const EditButton = (editProps) => {
    return (
      <Tooltip
        content={editProps?.content}
        className="text-sm font-proxima-semibold"
      >
        <Button
          onClick={() => {
            props.setEditDetails(editProps?.details);
            props.setEditId(editProps?.id);
            props.setEditDialog(!props.editDialog);
          }}
          className="p-2 bg-white border border-greyBorder rounded-full shadow hover:shadow hover:border-logoRed transition-all duration-300 ease-in-out"
        >
          <BiEdit className="w-5 h-5 text-greyText" />
        </Button>
      </Tooltip>
    );
  };

  return (
    <div className="px-10">
      <div className="container-full-width">
        <h2 className="text-4xl font-sunflower-bold text-black tracking-tight">
          Advertisements
        </h2>
        <p className="text-lg font-proxima-regular text-greyText">
          Showcasing Excellence: Explore the vibrant businesses and services of
          our esteemed Shree Lohana Mahaparishad members
        </p>
        <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5 mt-5">
          <div className="col-span-1">
            <div className="relative w-full h-[15vh] xl:h-advertisementBanner lg:h-advertisementBanner">
              <img
                src={Advertisement01}
                className="w-full h-full object-cover rounded-xl shadow-md border border-greyBorder"
                fill
                alt="Taste of India in Malawi"
              />

              <div className="absolute top-3 right-3">
                <EditButton
                  details={cmsList?.find(
                    (el) => el?.moduleName === "bannerImage"
                  )}
                  content={"Edit Banner Image"}
                  id={
                    cmsList?.find((el) => el?.moduleName === "bannerImage")?._id
                  }
                />{" "}
              </div>
            </div>
          </div>
          <div className="col-span-1">
            <div className="relative w-full h-[15vh] xl:h-advertisementBanner lg:h-advertisementBanner">
              <img
                src={Advertisement02}
                className="w-full h-full object-cover rounded-xl shadow-md border border-greyBorder"
                fill
                alt="Rajani Group"
              />

              <div className="absolute top-3 right-3">
                <EditButton
                  details={cmsList?.find(
                    (el) => el?.moduleName === "bannerImage"
                  )}
                  content={"Edit Banner Image"}
                  id={
                    cmsList?.find((el) => el?.moduleName === "bannerImage")?._id
                  }
                />{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advertisements;
