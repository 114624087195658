import React from "react";
import { Button, Tooltip } from "@material-tailwind/react";
import { BiEdit } from "react-icons/bi";

/* ASSETS */
import AboutUs from "../../../assets/images/CMS/about-us.jpg";

const History = (props) => {
  const cmsList = props?.cmsList;

  const EditButton = (editProps) => {
    return (
      <Tooltip
        content={editProps?.content}
        className="text-sm font-proxima-semibold"
      >
        <Button
          onClick={() => {
            props.setEditDetails(editProps?.details);
            props.setEditId(editProps?.id);
            props.setEditDialog(!props.editDialog);
          }}
          className="p-2 rounded-full shadow hover:shadow bg-white border border-greyBorder hover:border-logoRed transition-all duration-300 ease-in-out"
        >
          <BiEdit className="w-5 h-5 text-greyText" />
        </Button>
      </Tooltip>
    );
  };

  return (
    <div className="px-10">
      <div className="container-full-width">
        <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5 xl:gap-10 lg:gap-10 items-center">
          <div className="col-span-1 w-full xl:w-4/5 lg:w-4/5 relative">
            <h3 className="text-4xl font-sunflower-bold text-black tracking-tight leading-none xl:leading-tight lg:leading-tight">
              We invest in ideas, individuals and institutions
            </h3>
            <p className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText mt-3">
              We envision philanthropy as a catalyst for nurturing leaders and
              mobilizing efforts toward social justice, fostering movements that
              resonate worldwide.
            </p>
            <p className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText mt-3">
              Through philanthropy, we aim to empower leaders and facilitate
              initiatives that drive social justice, fostering global movements
              that enact meaningful change and promote equality for all.{" "}
            </p>
            <Button className="mt-5 shadow hover:shadow bg-logoYellow hover:bg-opacity-70 rounded-md py-2.5 px-5 text-black font-proxima-semibold normal-case text-base transition-all duration-300 ease-in-out">
              Our History
            </Button>

            <div className="absolute top-0 right-0">
              <EditButton
                details={cmsList?.find(
                  (el) => el?.moduleName === "bannerImage"
                )}
                content="Edit content"
                id={
                  cmsList?.find((el) => el?.moduleName === "bannerImage")?._id
                }
              />
            </div>
          </div>
          <div className="col-span-1 relative mt-5 xl:mt-0 lg:mt-0">
            <div className="relative mx-auto z-50">
              <img
                src={AboutUs}
                className="w-11/12 h-[45vh] object-cover rounded-lg shadow-md"
                alt="We invest in Transformative Ideas, Individuals and Institutions"
              />

              <div className="absolute top-3 right-20">
                <EditButton
                  details={cmsList?.find(
                    (el) => el?.moduleName === "bannerImage"
                  )}
                  content="Update image"
                  id={
                    cmsList?.find((el) => el?.moduleName === "bannerImage")?._id
                  }
                />
              </div>
            </div>

            <div className="absolute -top-5 right-0 w-3/5 h-[50vh] bg-logoRed bg-opacity-15 rounded-md shadow" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;
