/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Select from "react-select";
import { Editor } from "react-draft-wysiwyg";
import ReactImageUploading from "react-images-uploading";
import ReactDatePicker from "react-datepicker";
import { Link, useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import * as yup from "yup";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Button, Dialog,
  DialogBody,
} from "@material-tailwind/react";

/* REACT ICONS */
import { FiTrash } from "react-icons/fi";
import { AiFillPlusCircle } from "react-icons/ai";
import { IoClose } from "react-icons/io5";
/* ICONS */
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { FaRegCircleCheck } from "react-icons/fa6";

/* TOAST NOTIFICATION */
import { toast } from "react-toast";

/* USER DEFINED COMPONENTS */
import InputField from "../../../components/input";
import { editRolesDetailsById, getRoleDetailsById, getZonesList, postRoleDetails } from "../../../service/api";

import AddAddressFormComponent from "../../../components/forms/addAddress";

/* VALIDATION SCHEMA */
const validationSchema = yup.object().shape({
  name: yup.string().required(),
  permission: yup.array(),
});


const AddRole = () => {

  const navigate = useNavigate();

  // STATE VARIABLES
  const [loading, setLoading] = useState(false);
  const [permissionList, setPermissionList] = useState([]);

  const [addAddressDialog, setAddressDialog] = useState(false);

  // FORMIK REGISTRATION FUNCTION
  const formik = useFormik({
    initialValues: {
      name: "",
      permission: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", values?.name);
      if (values?.permission) {
        formData.append("permission", JSON.stringify(values?.permission));
      }

      postRoleDetails(formData)
        .then((res) => {
          console.log("res", res);
          if (res?.status) {
            toast.success(res?.message);
            navigate("/role/edit-role/" + res?.data)
            // dispatch(actions.setToken(res?.data?.token));
            // dispatch(actions.setUser(res?.data?.userData));
            // dispatch(actions.setLoggedIn(true));
          } else {
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          setLoading(false);
          console.log("er", e?.response?.data?.message);
          if (e?.response?.data?.message) {
            toast.error(e?.response?.data?.message);
          } else {
            toast.error(e?.message);
          }
        })
        .finally((res) => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    formik.setFieldValue("permission", permissionList);
  }, [permissionList])

  /* ADDRESS BOX */
  const AddressBox = (props) => {
    return (
      <div className="border border-greyColor shadow p-5 rounded-lg">
        <div>
          <h5 className="text-base font-proxima-bold text-black">
            {props.addressDetails.addressLine1}, {props.addressDetails.addressLine2}
          </h5>
          <h5 className="text-sm font-proxima-regular text-headerGrey">
            {props.addressDetails.city}, {props.addressDetails.taluka}, {props.addressDetails.state} - {props.addressDetails.zipCode}, {props.addressDetails.country}
          </h5>
        </div>
        <div className="w-10 h-[0.1vh] bg-gray-400 mt-3" />
        <div className="mt-3">
          <h5 className="text-sm font-proxima-regular text-headerGrey">
            {props.addressDetails.type}
          </h5>
          <h5 className="text-sm font-proxima-regular text-headerGrey">
            {props.addressDetails.defaultAddress ? "Yes" : "No"}
          </h5>
        </div>
        {/* <div className="mt-3 flex items-center">
          <p className="cursor-pointer text-headerGrey font-proxima-regular italic text-sm hover:underline mr-5">
            Edit
          </p>
          <p className="cursor-pointer text-red-500 font-proxima-regular italic text-sm hover:underline">
            Delete
          </p>
        </div> */}
      </div>
    );
  };

  return (
    <section className="px-0 py-2 xl:p-10 lg:p-10 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Add Role | Lohana Mahaparishad</title>
      </Helmet>

      <form onSubmit={formik.handleSubmit}>
        <div className="container-full-width">
          {/* HEADING */}
          <div className="block xl:flex lg:flex items-center justify-between">
            <div>
              <h1 className="text-3xl font-proxima-bold text-black tracking-tight">
                Add Role
              </h1>
              <h2 className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText tracking-tight">
                Add role details for website
              </h2>
            </div>
            <div className="flex items-center gap-2 mt-2 xl:mt-0 lg:mt-0">
              <Link to="/roles">
                <Button className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-white hover:bg-black hover:bg-opacity-5 px-5 py-3 rounded-md border border-authBorder text-sm font-proxima-semibold text-black normal-case transition-all duration-300 ease-in-out">
                  Back to Role
                </Button>
              </Link>
              <Button type="submit" disabled={loading} className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md border border-logoYellow text-sm font-proxima-semibold text-white normal-case transition-all duration-300 ease-in-out">
                {loading ? "Please wait..." : "Save details"}
              </Button>
            </div>
          </div>

          {/* FORM */}
          <div className="mt-5">
            <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 gap-y-5 xl:gap-5 lg:gap-5">
              {/* DETAILS */}
              <div className="col-span-2">
                <div className="bg-white rounded-lg border border-authBorder">
                  <div className="px-5 py-3 border-b border-authBorder">
                    <h5 className="text-lg font-proxima-bold text-black">
                      Role Details
                    </h5>
                    <p className="text-sm font-proxima-regular text-greyText">
                      Add these role specific details
                    </p>
                  </div>
                  <div className="p-5">
                    <form>
                      <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
                        <div className="col-span-2">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Role Name <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <InputField
                                type="text"
                                name="name"
                                id="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.errors.name && formik.touched.name ? true : false}
                                placeholder="Write the role name"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>


              </div>
              <div className="col-span-2">
                <div className="bg-white rounded-lg border border-authBorder">

                  <div className="block xl:flex lg:flex items-center justify-between px-5 py-3 border-b border-authBorder">
                    <div>
                      <h5 className="text-lg font-proxima-bold text-black">
                        Role Permissions
                      </h5>
                      <p className="text-sm font-proxima-regular text-greyText">
                        Edit these role permission
                      </p>
                    </div>
                    <div className="flex items-center gap-2 mt-2 xl:mt-0 lg:mt-0">
                      <Button onClick={() => setAddressDialog(!addAddressDialog)} className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-white hover:bg-black hover:bg-opacity-5 px-5 py-3 rounded-md border border-authBorder text-sm font-proxima-semibold text-black normal-case transition-all duration-300 ease-in-out">
                        Add Permission
                      </Button>

                    </div>
                  </div>


                  {permissionList?.length === 0 && <>
                    <div className="p-5">
                      <p className="text-base font-proxima-regular text-greyText">
                        No Permissions found
                      </p></div></>}

                  {permissionList?.length !== 0 && permissionList?.map((address, index) => {
                    return <AddressBox
                      addressDetails={address}
                    />
                  })}
                </div>
              </div>

            </div>
          </div>
        </div>
      </form>

      {/* VIEW CATEGORY DIALOG */}
      <Dialog
        open={addAddressDialog}
        handler={() => setAddressDialog(!addAddressDialog)}
        size="xs"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <DialogBody className="p-0">
          <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
            <h5 className="text-lg font-proxima-bold text-black tracking-tight">
              Add New Address
            </h5>
            <Button
              className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
              onClick={() => setAddressDialog(!addAddressDialog)}
            >
              <IoClose className="w-4 h-4 text-greyText" />
            </Button>
          </div>
          <div className="p-5">

            <AddAddressFormComponent setPermissionList={setPermissionList} permission={permissionList} setAddressDialog={setAddressDialog} />

          </div>

        </DialogBody>
      </Dialog>

    </section>
  );
};

export default AddRole;
