/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Select from "react-select";
import { Editor } from "react-draft-wysiwyg";
import ReactImageUploading from "react-images-uploading";
import ReactDatePicker from "react-datepicker";
import { Link, useParams } from "react-router-dom";

import { useFormik } from "formik";
import * as yup from "yup";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Button, Dialog,
  DialogBody,
} from "@material-tailwind/react";

/* REACT ICONS */
import { FiTrash } from "react-icons/fi";
import { AiFillPlusCircle } from "react-icons/ai";
import { IoClose } from "react-icons/io5";

/* TOAST NOTIFICATION */
import { toast } from "react-toast";

/* USER DEFINED COMPONENTS */
import InputField from "../../../components/input";
import { editZonesDetailsById, getZoneDetailsById } from "../../../service/api";
import AddAddressFormComponent from "../../../components/forms/addAddress";

/* VALIDATION SCHEMA */
const validationSchema = yup.object().shape({
  name: yup.string().required(),
  address: yup.array(),
  description: yup.string(),
  shortDescription: yup.string(),
  previousImage: yup.string(),
});


const EditZone = () => {

  const { id } = useParams();
  // STATE VARIABLES
  const [loading, setLoading] = useState(false);
  const [zoneDetails, setZoneDetails] = useState(null);
  const [addressList, setAddressList] = useState([]);
  const [zoneImages, setZoneImages] = useState([]);

  const [deleteImage, setDeleteImage] = useState(false);

  const [addAddressDialog, setAddressDialog] = useState(false);


  /* CHOOSE IMAGES */
  const onImageChange = (imageList) => {
    setZoneImages(imageList);
  };

  /* HANDLE DELETE DATA */
  const handleGetZoneDetails = () => {
    getZoneDetailsById(id)
      .then((res) => {
        toast.success(res?.message);
        setZoneDetails(res?.data);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetZoneDetails();
  }, [id]);

  // FORMIK REGISTRATION FUNCTION
  const formik = useFormik({
    initialValues: {
      name: "",
      address: "",
      description: "",
      shortDescription: "",
      previousImage: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", values?.name);
      if (values?.address) {
        formData.append("address", JSON.stringify(values?.address));
      }
      if (values?.description) {
        formData.append("description", values?.description);
      }

      if (values?.shortDescription) {
        formData.append("shortDescription", values?.shortDescription);
      }

      if (values?.previousImage) {
        formData.append("previousImage", values?.previousImage);
      }


      if (zoneImages?.[0]?.file) {
        formData.append("image", zoneImages?.[0]?.file);
      }

      console.log("add",JSON.stringify(values?.address))
      editZonesDetailsById(id, formData)
        .then((res) => {
          console.log("res", res);
          if (res?.status) {
            toast.success(res?.message);
            handleGetZoneDetails();
            // dispatch(actions.setToken(res?.data?.token));
            // dispatch(actions.setUser(res?.data?.userData));
            // dispatch(actions.setLoggedIn(true));
          } else {
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          setLoading(false);
          console.log("er", e?.response?.data?.message);
          if (e?.response?.data?.message) {
            toast.error(e?.response?.data?.message);
          } else {
            toast.error(e?.message);
          }
        })
        .finally((res) => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    if (zoneDetails) {
      formik.setFieldValue("name", zoneDetails?.name);
      formik.setFieldValue("description", zoneDetails?.description);
      formik.setFieldValue("shortDescription", zoneDetails?.shortDescription);
      formik.setFieldValue("address", zoneDetails?.address);
      setAddressList(zoneDetails?.address)
      formik.setFieldValue("previousImage", zoneDetails?.zoneImage);

      if (zoneDetails?.zoneImage) {
        setDeleteImage(false)
      } else {
        setDeleteImage(true)
      }
    }
  }, [zoneDetails])

  useEffect(() => {
    formik.setFieldValue("address", addressList);
  }, [addressList])

  console.log("formik", formik?.values)
  /* ADDRESS BOX */
  const AddressBox = (props) => {
    return (
      <div className="border border-greyColor shadow p-5 rounded-lg">
        <div>
          <h5 className="text-base font-proxima-bold text-black">
            {props.addressDetails.addressLine1}, {props.addressDetails.addressLine2}
          </h5>
          <h5 className="text-sm font-proxima-regular text-headerGrey">
            {props.addressDetails.city}, {props.addressDetails.taluka}, {props.addressDetails.state} - {props.addressDetails.zipCode}, {props.addressDetails.country}
          </h5>
        </div>
        <div className="w-10 h-[0.1vh] bg-gray-400 mt-3" />
        <div className="mt-3">
          <h5 className="text-sm font-proxima-regular text-headerGrey">
            {props.addressDetails.type}
          </h5>
          <h5 className="text-sm font-proxima-regular text-headerGrey">
            {props.addressDetails.defaultAddress ? "Yes" : "No"}
          </h5>
        </div>
        {/* <div className="mt-3 flex items-center">
          <p className="cursor-pointer text-headerGrey font-proxima-regular italic text-sm hover:underline mr-5">
            Edit
          </p>
          <p className="cursor-pointer text-red-500 font-proxima-regular italic text-sm hover:underline">
            Delete
          </p>
        </div> */}
      </div>
    );
  };

  return (
    <section className="px-0 py-2 xl:p-10 lg:p-10 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Edit Zone | Lohana Mahaparishad</title>
      </Helmet>

      <form onSubmit={formik.handleSubmit}>
        <div className="container-full-width">
          {/* HEADING */}
          <div className="block xl:flex lg:flex items-center justify-between">
            <div>
              <h1 className="text-3xl font-proxima-bold text-black tracking-tight">
                Edit Zone
              </h1>
              <h2 className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText tracking-tight">
                Edit filled details of zone
              </h2>
            </div>
            <div className="flex items-center gap-2 mt-2 xl:mt-0 lg:mt-0">
              <Link to="/zones">
                <Button className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-white hover:bg-black hover:bg-opacity-5 px-5 py-3 rounded-md border border-authBorder text-sm font-proxima-semibold text-black normal-case transition-all duration-300 ease-in-out">
                  Back to Zone
                </Button>
              </Link>
              <Button type="submit" disabled={loading} className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md border border-logoYellow text-sm font-proxima-semibold text-white normal-case transition-all duration-300 ease-in-out">
                {loading ? "Please wait..." : "Save details"}
              </Button>
            </div>
          </div>

          {/* FORM */}
          <div className="mt-5">
            <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-y-5 xl:gap-5 lg:gap-5">
              {/* DETAILS */}
              <div className="col-span-2">
                <div className="bg-white rounded-lg border border-authBorder">
                  <div className="px-5 py-3 border-b border-authBorder">
                    <h5 className="text-lg font-proxima-bold text-black">
                      Zone Details
                    </h5>
                    <p className="text-sm font-proxima-regular text-greyText">
                      Edit these zone specific details
                    </p>
                  </div>
                  <div className="p-5">
                    <form>
                      <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
                        <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Zone Name <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <InputField
                                type="text"
                                name="name"
                                id="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.errors.name && formik.touched.name ? true : false}
                                placeholder="Write the zone name"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Short Description <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <InputField
                                type="text"
                                name="shortDescription"
                                id="shortDescription"
                                value={formik.values.shortDescription}
                                onChange={formik.handleChange}
                                error={formik.errors.shortDescription && formik.touched.shortDescription ? true : false}
                                placeholder="Write short description for zone"
                              />
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Establishment Date{" "}
                              <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <InputField
                                type="text"
                                name="establishmentDate"
                                id="establishmentDate"
                                value={formik.values.establishmentDate}
                                onChange={formik.handleChange}
                                error={formik.errors.establishmentDate && formik.touched.establishmentDate ? true : false}
                                placeholder="Write the establishment date"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Email Address <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <InputField
                                type="email"
                                name="email"
                                id="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.errors.email && formik.touched.email ? true : false}
                                placeholder="Write the zone sku"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Phone Number{" "}
                              <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <InputField
                                type="text"
                                name="phoneNumber"
                                id="phoneNumber"
                                value={formik.values.phoneNumber}
                                onChange={formik.handleChange}
                                error={formik.errors.phoneNumber && formik.touched.phoneNumber ? true : false}
                                placeholder="Write the zone price"
                              />
                            </div>
                          </div>
                        </div> */}

                        <div className="col-span-2">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Zone Description{" "}
                              <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <Editor
                                toolbar={{ options: ["inline", "link"] }}
                                editorClassName="text-editor"
                                wrapperClassName="text-editor-wrapper"
                                placeholder="Write the zone description here"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="bg-white rounded-lg border border-authBorder mt-5">

                  <div className="block xl:flex lg:flex items-center justify-between px-5 py-3 border-b border-authBorder">
                    <div>
                      <h5 className="text-lg font-proxima-bold text-black">
                        Zone Address
                      </h5>
                      <p className="text-sm font-proxima-regular text-greyText">
                        Edit these zone addresses
                      </p>
                    </div>
                    <div className="flex items-center gap-2 mt-2 xl:mt-0 lg:mt-0">
                      <Button onClick={() => setAddressDialog(!addAddressDialog)} className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-white hover:bg-black hover:bg-opacity-5 px-5 py-3 rounded-md border border-authBorder text-sm font-proxima-semibold text-black normal-case transition-all duration-300 ease-in-out">
                        Add Address
                      </Button>

                    </div>
                  </div>


                  {addressList?.length === 0 && <>
                    <div className="p-5">
                      <p className="text-base font-proxima-regular text-greyText">
                        No Addresses found
                      </p></div></>}

                  {addressList?.length !== 0 && addressList?.map((address, index) => {
                    return <AddressBox
                      addressDetails={address}
                    />
                  })}
                </div>
              </div>

              {/* IMAGES  */}
              <div className="col-span-1">

                {/* IMAGES */}
                <div className="bg-white rounded-lg border border-authBorder mt-5">
                  <div className="px-5 py-3 border-b border-authBorder">
                    <h5 className="text-lg font-proxima-bold text-black">
                      Zone Images
                    </h5>
                    <p className="text-sm font-proxima-regular text-greyText">
                      Attach the Logo images for this zone
                    </p>
                  </div>
                  <div className="p-5">


                    <div className="grid grid-cols-2 xl:grid-cols-3 lg:grid-cols-3 gap-5">

                      {!deleteImage ? <div className="col-span-1 relative">
                        <img
                          src={zoneDetails?.zoneImage}
                          className="w-full h-zoneImage object-cover rounded-md"
                          alt="Zone"
                        />
                        <div className="absolute top-2 right-2">
                          <Button
                            className="p-2 rounded-full shadow-none hover:shadow-none bg-white border border-logoYellow"
                            onClick={() => setDeleteImage(true)}
                          >
                            <FiTrash className="w-3 h-3 text-black" />
                          </Button>
                        </div>
                      </div> : ""}

                    </div>

                    {deleteImage &&
                      <ReactImageUploading
                        multiple
                        value={zoneImages}
                        onChange={onImageChange}
                        maxNumber={5}
                        dataURLKey="data_url"
                      >
                        {({ imageList, onImageUpload, onImageRemove }) => (
                          <div className="grid grid-cols-2 xl:grid-cols-3 lg:grid-cols-3 gap-5">
                            <div className="col-span-1">
                              <div
                                className="w-full h-productImage rounded-md bg-[#F5F5F5] bg-opacity-50 border border-authBorder cursor-pointer hover:border-logoYellow transition-all duration-300 ease-in-out p-5"
                                onClick={onImageUpload}
                              >
                                <AiFillPlusCircle className="w-8 h-8 text-greyText mx-auto" />
                                <div className="mt-2">
                                  <p className="text-xs font-proxima-semibold text-greyText text-center">
                                    Click here to upload images
                                  </p>
                                  <p className="text-xs font-proxima-regular text-greyText text-center mt-1">
                                    (JPG, JPEG, PNG)
                                  </p>
                                </div>
                              </div>
                            </div>
                            {imageList.map((image, index) => (
                              <div className="col-span-1 relative" key={index}>
                                <img
                                  src={image["data_url"]}
                                  className="w-full h-productImage object-cover rounded-md"
                                  alt="Product"
                                />
                                <div className="absolute top-2 right-2">
                                  <Button
                                    className="p-2 rounded-full shadow-none hover:shadow-none bg-white border border-logoYellow"
                                    onClick={() => onImageRemove(index)}
                                  >
                                    <FiTrash className="w-3 h-3 text-black" />
                                  </Button>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </ReactImageUploading>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      {/* VIEW CATEGORY DIALOG */}
      <Dialog
        open={addAddressDialog}
        handler={() => setAddressDialog(!addAddressDialog)}
        size="xs"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <DialogBody className="p-0">
          <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
            <h5 className="text-lg font-proxima-bold text-black tracking-tight">
              Add New Address
            </h5>
            <Button
              className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
              onClick={() => setAddressDialog(!addAddressDialog)}
            >
              <IoClose className="w-4 h-4 text-greyText" />
            </Button>
          </div>
          <div className="p-5">

            <AddAddressFormComponent setAddressList={setAddressList} address={addressList} setAddressDialog={setAddressDialog} />

          </div>

        </DialogBody>
      </Dialog>

    </section>
  );
};

export default EditZone;
