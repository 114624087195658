
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

/* COMPONENTS */
import InputField from "../../../components/input";
import TextareaField from "../../../components/textarea";

/* ICONS */
import { IoCloudUpload, IoDocumentText } from "react-icons/io5";
import { FaInfoCircle, FaTrash } from "react-icons/fa";
import { FaFilePdf } from "react-icons/fa6";

/* PACKAGES */
import Select from "react-select";
import PhoneInput from "react-phone-number-input";
import ReactImageUploading from "react-images-uploading";
import ReactDatePicker from "react-datepicker";
import DataTable from "react-data-table-component";

import { useFormik } from "formik";
import * as yup from "yup";

/* DATA */
import { DISTRICT_DATA } from "../../../data/district-list";

/* MATERIAL TAILWIND COMPONENTS */
import { Button, Tooltip } from "@material-tailwind/react";

/* VALIDATIONS */
import { editMedicalAidDetailsById, getMedicalAidDetailsById } from "../../../service/api";
import { toast } from "react-toast";
import { Link, useParams } from "react-router-dom";

/* FILE SIZE CONVERSION */
const bytesToSize = (bytes) => {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "n/a";
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) return `${bytes} ${sizes[i]})`;
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

const medicalFormValidation = yup.object().shape({
    zoneId: yup.object().required("Zone is Required"),
    district: yup.object().required("District is Required"),
    patientFirstName: yup.string().required("First Name is Required"),
    patientMiddleName: yup.string().required(),
    patientLastName: yup.string().required(),
    nativePlace: yup.string().required(),
    patientAge: yup.string().required(),
    phoneNumber: yup.string().required(),
    addressLineOne: yup.string().required(),
    addressPinCode: yup.string().required(),
    annualIncomeAmount: yup.string().required(),
    earningMemberCount: yup.string().required(),
    medicalDetails: yup.object().shape({
        medicalCondition: yup.string().required(),
        sufferingSince: yup.string(),
        doctorName: yup.string().required(),
        hospitalName: yup.string().required(),
        admittedAt: yup.string(),
        hospitalAddress: yup.string().required(),
        costOfTreatment: yup.string().required(),
        careTakerName: yup.string().required(),
        careTakerPhoneNumber: yup.string().required(),
        healthInsuranceName: yup.string(),
        healthInsuranceAmountAvailed: yup.string(),
        additionalFundsAvailed: yup.string(),
        additionalFundsAmountAvailed: yup.string(),
        previousMedicalAidFromLMP: yup.string(),
        previousMedicalAidFromLMPAmountAvailed: yup.string(),
    }),
    bankDetails: yup.object().shape({
        accountHolder: yup.string().required(),
        bankName: yup.string().required(),
        branch: yup.string().required(),
        accountNumber: yup.string().required(),
        ifscCode: yup.string().required(),
        date: yup.date().required(),
    }),
    familyDetails: yup.array().required(),
    incomeProof: yup.mixed(),
    costEstimate: yup.mixed(),
    photo: yup.mixed(),
    reports: yup.mixed(),
    stamp: yup.mixed(),
    mahajanPresident: yup.mixed(),
    zonalPresident: yup.mixed(),
    signatureOffice: yup.mixed(),
    mahajanDetails: yup.object().shape({
        nearestMahajan: yup.string(),
        name: yup.string(),
        age: yup.string(),
        livingIn: yup.string(),
        illness: yup.string()
    }),
    zonalRegionOfficeDetails: yup.object().shape({
        designation: yup.string(),
        name: yup.string(),
        address: yup.string(),
        designationInMahaparishad: yup.string(),
    }),
    officeDetails: yup.object().shape({
        interviewDate: yup.string(),
        interviewName: yup.string(),
        grantedAssistance: yup.string(),
        chequeDetailsName: yup.string(),
        chequeDetailsAmount: yup.number(),
        chequeDetailsNumber: yup.number(),
        chequeDetailsDate: yup.date()
    }),
    filesList: yup.object()
})


export default function MedicalAidForm() {

    const { id } = useParams();

    /* STATE VARIABLE */
    const [loading, setLoading] = useState(false);
    const [medicalAidFormDetails, setMedicalAidFormDetails] = useState(null);


    /* LOHANA MAHAPARISHAD STATES */
    const [regionsList, setRegionsList] = useState([]);

    /* DOCUMENTS STATE */
    const [documents, setDocuments] = useState();
    const [costDocument, setCostDocument] = useState();
    const [reportsDocument, setReportsDocument] = useState();

    const [stampDocument, setStampDocument] = useState();
    const [mahajanPresidentDocument, setMahajanPresidentDocument] = useState();
    const [zonalPresidentDocument, setZonalPresidentDocument] = useState();
    const [signatureOfficeDocument, setSignatureOfficeDocument] = useState();


    const [familyDetails, setFamilyDetails] = useState([
        {
            no: 1,
            familyMember: "",
            age: "",
            occupation: "",
            monthlyIncome: "",
            relation: "",
        },
        {
            no: 2,
            familyMember: "",
            age: "",
            occupation: "",
            monthlyIncome: "",
            relation: "",
        },
        {
            no: 3,
            familyMember: "",
            age: "",
            occupation: "",
            monthlyIncome: "",
            relation: "",
        },
        {
            no: 4,
            familyMember: "",
            age: "",
            occupation: "",
            monthlyIncome: "",
            relation: "",
        },
        {
            no: 5,
            familyMember: "",
            age: "",
            occupation: "",
            monthlyIncome: "",
            relation: "",
        },
        {
            no: 6,
            familyMember: "",
            age: "",
            occupation: "",
            monthlyIncome: "",
            relation: "",
        },
        {
            no: 7,
            familyMember: "",
            age: "",
            occupation: "",
            monthlyIncome: "",
            relation: "",
        },
    ]);

    /* HANDLE FAMILY DETAILS CHANGE */
    const handleFamilyDetailsChange = (id, fieldName, value) => {
        let currentVersion = familyDetails?.find((element) => element?.no === id);
        let filteredVersion = familyDetails?.filter(
            (element) => element?.no !== id
        );

        currentVersion[fieldName] = value;
        filteredVersion.push(currentVersion);

        setFamilyDetails(
            filteredVersion?.sort(function (a, b) {
                return new Date(a?.no) - new Date(b?.no);
            })
        );
    };

    /* TABLE COLUMNS */
    const columns = [
        {
            name: "No.",
            selector: (row) => (
                <input
                    disabled={true}
                    type="number"
                    id="no"
                    name="no"
                    className="w-full bg-white block text-sm font-proxima-semibold text-black outline-none focus:outline-none px-0 py-2 placeholder:text-sm placeholder:font-proxima-regular text-center"
                    placeholder="No."
                    value={row?.no}
                />
            ),
        },
        {
            name: "Family member's name",
            grow: 3,
            selector: (row) => (
                <input
                    disabled={true}
                    type="text"
                    id="familyMember"
                    name="familyMember"
                    className="w-full bg-white block text-sm font-proxima-semibold text-black outline-none focus:outline-none px-0 py-2 placeholder:text-sm placeholder:font-proxima-regular text-center"
                    placeholder="Name"
                    value={row?.familyMember}
                    onChange={(e) => {
                        handleFamilyDetailsChange(
                            row?.no,
                            "familyMember",
                            e?.target?.value
                        );
                    }}
                />
            ),
        },
        {
            name: "Age",
            selector: (row) => (
                <input
                    disabled={true}
                    type="number"
                    id="age"
                    name="age"
                    className="w-full bg-white block text-sm font-proxima-semibold text-black outline-none focus:outline-none px-0 py-2 placeholder:text-sm placeholder:font-proxima-regular text-center"
                    placeholder="Age"
                    value={row?.age}
                    onChange={(e) => {
                        handleFamilyDetailsChange(row?.no, "age", e?.target?.value);
                    }}
                />
            ),
        },
        {
            name: "Occupation",
            grow: 2,
            selector: (row) => (
                <input
                    disabled={true}
                    type="text"
                    id="occupation"
                    name="occupation"
                    className="w-full bg-white block text-sm font-proxima-semibold text-black outline-none focus:outline-none px-0 py-2 placeholder:text-sm placeholder:font-proxima-regular text-center"
                    placeholder="Occupation"
                    value={row?.occupation}
                    onChange={(e) => {
                        handleFamilyDetailsChange(row?.no, "occupation", e?.target?.value);
                    }}
                />
            ),
        },
        {
            name: "Monthly Income",
            grow: 2,
            selector: (row) => (
                <input
                    disabled={true}
                    type="number"
                    id="monthlyIncome"
                    name="monthlyIncome"
                    className="w-full bg-white block text-sm font-proxima-semibold text-black outline-none focus:outline-none px-0 py-2 placeholder:text-sm placeholder:font-proxima-regular text-center"
                    placeholder="Monthly income"
                    value={row?.monthlyIncome}
                    onChange={(e) => {
                        handleFamilyDetailsChange(
                            row?.no,
                            "monthlyIncome",
                            e?.target?.value
                        );
                    }}
                />
            ),
        },
        {
            name: "Relation",
            grow: 2,
            selector: (row) => (
                <input
                    disabled={true}
                    type="text"
                    id="relation"
                    name="relation"
                    className="w-full bg-white block text-sm font-proxima-semibold text-black outline-none focus:outline-none px-0 py-2 placeholder:text-sm placeholder:font-proxima-regular text-center"
                    placeholder="Relation"
                    value={row?.relation}
                    onChange={(e) => {
                        handleFamilyDetailsChange(row?.no, "relation", e?.target?.value);
                    }}
                />
            ),
        },
    ];

    /* STEP ONE FORM HANDLING */
    const formikForm = useFormik({
        initialValues: {
            zoneId: "",
            district: "",
            patientFirstName: "",
            patientMiddleName: "",
            patientLastName: "",
            nativePlace: "",
            patientAge: "",
            phoneNumber: "",
            addressLineOne: "",
            addressPinCode: "",
            annualIncomeAmount: "",
            earningMemberCount: "",
            medicalDetails: {
                medicalCondition: "",
                sufferingSince: "",
                doctorName: "",
                hospitalName: "",
                admittedAt: "",
                hospitalAddress: "",
                costOfTreatment: "",
                careTakerName: "",
                careTakerPhoneNumber: "",
                healthInsuranceName: "",
                healthInsuranceAmountAvailed: "",
                additionalFundsAvailed: "",
                additionalFundsAmountAvailed: "",
                previousMedicalAidFromLMP: "",
                previousMedicalAidFromLMPAmountAvailed: ""
            },
            bankDetails: {
                accountHolder: "",
                bankName: "",
                branch: "",
                accountNumber: "",
                ifscCode: "",
                date: new Date(),
            },
            familyDetails: "",
            incomeProof: "",
            costEstimate: "",
            photo: "",
            reports: "",
            stamp: "",
            mahajanPresident: "",
            zonalPresident: "",
            signatureOffice: "",
            mahajanDetails: {
                nearestMahajan: "",
                name: "",
                age: "",
                livingIn: "",
                illness: ""
            },
            zonalRegionOfficeDetails: {
                designation: "",
                name: "",
                address: "",
                designationInMahaparishad: "",
            },
            officeDetails: {
                interviewDate: "",
                interviewName: "",
                grantedAssistance: "",
                chequeDetailsName: "",
                chequeDetailsAmount: "",
                chequeDetailsNumber: "",
                chequeDetailsDate: ""
            },
            filesList: ""
        },
        validationSchema: medicalFormValidation,
        onSubmit: (values) => {
            const obj = {
                district: values?.district?.value,
                zoneId: values?.zoneId?.value,
                patientFirstName: values?.patientFirstName,
                patientMiddleName: values?.patientMiddleName,
                patientLastName: values?.patientLastName,
                nativePlace: values?.nativePlace,
                patientAge: String(values?.patientAge),
                phoneNumber: values?.phoneNumber,
                addressLineOne: values?.addressLineOne,
                addressPinCode: String(values?.addressPinCode),
                annualIncomeAmount: String(values?.annualIncomeAmount),
                earningMemberCount: String(values?.earningMemberCount),
                medicalDetails: {
                    medicalCondition: values?.medicalDetails?.medicalCondition,
                    doctorName: values?.medicalDetails?.doctorName,
                    hospitalName: values?.medicalDetails?.hospitalName,
                    hospitalAddress: values?.medicalDetails?.hospitalAddress,
                    costOfTreatment: String(values?.medicalDetails?.costOfTreatment),
                    careTakerName: values?.medicalDetails?.careTakerName,
                    careTakerPhoneNumber: values?.medicalDetails?.careTakerPhoneNumber,
                    healthInsuranceName: values?.medicalDetails?.healthInsuranceName,
                    additionalFundsAvailed: values?.medicalDetails?.additionalFundsAvailed,
                    previousMedicalAidFromLMP: values?.medicalDetails?.previousMedicalAidFromLMP,
                },
                bankDetails: {
                    accountHolder: values?.bankDetails?.accountHolder,
                    bankName: values?.bankDetails?.bankName,
                    branch: values?.bankDetails?.branch,
                    accountNumber: String(values?.bankDetails?.accountNumber),
                    ifscCode: values?.bankDetails?.ifscCode,
                    date: values?.bankDetails?.date,
                },
                familyDetails: values?.familyDetails,
                filesList: values?.filesList,
                mahajanDetails: {},
                officeDetails: {},
                zonalRegionOfficeDetails: {}
            };

            if (values?.medicalDetails?.sufferingSince) {
                obj.medicalDetails.sufferingSince = values?.medicalDetails?.sufferingSince
            }

            if (values?.medicalDetails?.admittedAt) {
                obj.medicalDetails.admittedAt = values?.medicalDetails?.admittedAt
            }

            if (values?.medicalDetails?.healthInsuranceAmountAvailed) {
                obj.medicalDetails.healthInsuranceAmountAvailed = String(values?.medicalDetails?.healthInsuranceAmountAvailed)
            }
            if (values?.medicalDetails?.additionalFundsAmountAvailed) {
                obj.medicalDetails.additionalFundsAmountAvailed = String(values?.medicalDetails?.additionalFundsAmountAvailed)
            }
            if (values?.medicalDetails?.previousMedicalAidFromLMPAmountAvailed) {
                obj.medicalDetails.previousMedicalAidFromLMPAmountAvailed = String(values?.medicalDetails?.previousMedicalAidFromLMPAmountAvailed)
            }






            if (values?.mahajanDetails?.nearestMahajan) {
                obj.mahajanDetails.nearestMahajan = values?.mahajanDetails?.nearestMahajan
            }
            if (values?.mahajanDetails?.name) {
                obj.mahajanDetails.name = values?.mahajanDetails?.name
            }
            if (values?.mahajanDetails?.illness) {
                obj.mahajanDetails.illness = values?.mahajanDetails?.illness
            }
            if (values?.mahajanDetails?.age) {
                obj.mahajanDetails.age = String(values?.mahajanDetails?.age)
            }
            if (values?.mahajanDetails?.livingIn) {
                obj.mahajanDetails.livingIn = values?.mahajanDetails?.livingIn
            }


            if (values?.zonalRegionOfficeDetails?.designation) {
                obj.zonalRegionOfficeDetails.designation = values?.zonalRegionOfficeDetails?.designation
            }
            if (values?.zonalRegionOfficeDetails?.name) {
                obj.zonalRegionOfficeDetails.name = values?.zonalRegionOfficeDetails?.name
            }
            if (values?.zonalRegionOfficeDetails?.address) {
                obj.zonalRegionOfficeDetails.address = values?.zonalRegionOfficeDetails?.address
            }
            if (values?.zonalRegionOfficeDetails?.designationInMahaparishad) {
                obj.zonalRegionOfficeDetails.designationInMahaparishad = values?.zonalRegionOfficeDetails?.designationInMahaparishad
            }


            if (values?.officeDetails?.interviewDate) {
                obj.officeDetails.interviewDate = values?.officeDetails?.interviewDate
            }
            if (values?.officeDetails?.interviewName) {
                obj.officeDetails.interviewName = values?.officeDetails?.interviewName
            }
            if (values?.officeDetails?.grantedAssistance) {
                obj.officeDetails.grantedAssistance = values?.officeDetails?.grantedAssistance
            }
            if (values?.officeDetails?.chequeDetailsName) {
                obj.officeDetails.chequeDetailsName = values?.officeDetails?.chequeDetailsName
            }
            if (values?.officeDetails?.chequeDetailsAmount) {
                obj.officeDetails.chequeDetailsAmount = values?.officeDetails?.chequeDetailsAmount
            }
            if (values?.officeDetails?.chequeDetailsNumber) {
                obj.officeDetails.chequeDetailsNumber = values?.officeDetails?.chequeDetailsNumber
            }
            if (values?.officeDetails?.chequeDetailsDate) {
                obj.officeDetails.chequeDetailsDate = values?.officeDetails?.chequeDetailsDate
            }


            console.log("OBJ", obj)

            const formData = new FormData();

            formData.append("data", JSON.stringify(obj));

            if (formikForm.values.photo?.[0]?.file) {
                formData.append("photo", formikForm.values.photo?.[0]?.file);
            }

            if (formikForm.values.costEstimate) {
                formData.append("costEstimate", formikForm.values.costEstimate);
            }

            if (formikForm.values.incomeProof) {
                formData.append("incomeProof", formikForm.values.incomeProof);
            }

            if (formikForm.values.reports) {
                formData.append("reports", formikForm.values.reports);
            }



            if (formikForm.values.stamp) {
                formData.append("stamp", formikForm.values.stamp);
            }
            if (formikForm.values.mahajanPresident) {
                formData.append("mahajanPresident", formikForm.values.mahajanPresident);
            }
            if (formikForm.values.zonalPresident) {
                formData.append("zonalPresident", formikForm.values.zonalPresident);
            }
            if (formikForm.values.signatureOffice) {
                formData.append("signatureOffice", formikForm.values.signatureOffice);
            }


            console.log("OB", obj)
            for (let pair of formData.entries()) {
                console.log(`${pair[0]}: ${pair[1]}`);
            }
            setLoading(true);
            editMedicalAidDetailsById(id, formData)
                .then((res) => {
                    if (res?.status) {
                        toast(
                            "Your details have been captured. We will get back to you shortly."
                        );
                        // formikForm.handleReset();
                        // formikForm.setFieldValue("phoneNumber", "");

                        // setTimeout(() => { window.location.reload() }, 2000)
                    } else {
                        toast.error(res?.message);
                    }
                    setLoading(false);
                })
                .catch((e) => {
                    setLoading(false);
                    toast.error(e?.response?.data?.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        },
    });

    const handleGetMedicalFormDetails = () => {
        getMedicalAidDetailsById(id)
            .then((res) => {
                toast.success(res?.message);
                setMedicalAidFormDetails(res?.data);
            })
            .catch((e) => {
                toast.error(e?.response?.data?.message);
            })
    };


    useEffect(() => {
        let tempArray = [];
        DISTRICT_DATA?.forEach((element) => {
            tempArray.push({
                label: element?.District,
                value: element?.District + "(" + element?.["State / Union Territory"] + ")",
                zoneId: element?.zoneId,
                zoneName: element?.Zone,
            });
        });
        setRegionsList(tempArray);
    }, []);

    useEffect(() => {
        if (familyDetails) {
            formikForm.setFieldValue("familyDetails", familyDetails);
        }
    }, [familyDetails]);

    console.log("Error", formikForm.errors)
    console.log("VALUES", formikForm.values)
    useEffect(() => {
        if (medicalAidFormDetails) {
            formikForm.setFieldValue("district", regionsList?.find((el) => el?.zoneId === medicalAidFormDetails?.zoneId));
            formikForm.setFieldValue("zoneId", { label: regionsList?.find((el) => el?.zoneId === medicalAidFormDetails?.zoneId)?.zoneName, value: regionsList?.find((el) => el?.zoneId === medicalAidFormDetails?.zoneId)?.zoneId });
            formikForm.setFieldValue("patientFirstName", medicalAidFormDetails?.patientDetails?.firstName)
            formikForm.setFieldValue("patientMiddleName", medicalAidFormDetails?.patientDetails?.middleName)
            formikForm.setFieldValue("patientLastName", medicalAidFormDetails?.patientDetails?.lastName)
            formikForm.setFieldValue("nativePlace", medicalAidFormDetails?.patientDetails?.nativePlace)
            formikForm.setFieldValue("patientAge", medicalAidFormDetails?.patientDetails?.age)
            formikForm.setFieldValue("phoneNumber", medicalAidFormDetails?.patientDetails?.phoneNumber)
            formikForm.setFieldValue("addressLineOne", medicalAidFormDetails?.patientDetails?.address?.addressLine1)
            formikForm.setFieldValue("addressPinCode", medicalAidFormDetails?.patientDetails?.address?.pinCode)
            formikForm.setFieldValue("annualIncomeAmount", medicalAidFormDetails?.patientDetails?.annualIncome?.amount)
            formikForm.setFieldValue("earningMemberCount", medicalAidFormDetails?.patientDetails?.earningMemberCount)


            formikForm.setFieldValue("medicalDetails.medicalCondition", medicalAidFormDetails?.medicalDetails?.medicalCondition)
            formikForm.setFieldValue("medicalDetails.hospitalName", medicalAidFormDetails?.medicalDetails?.hospitalName)
            formikForm.setFieldValue("medicalDetails.hospitalAddress", medicalAidFormDetails?.medicalDetails?.hospitalAddress)
            formikForm.setFieldValue("medicalDetails.careTakerName", medicalAidFormDetails?.medicalDetails?.careTakerName)
            formikForm.setFieldValue("medicalDetails.careTakerPhoneNumber", medicalAidFormDetails?.medicalDetails?.careTakerPhoneNumber)

            formikForm.setFieldValue("medicalDetails.additionalFundsAvailed", medicalAidFormDetails?.medicalDetails?.additionalFundsAvailed?.name)
            formikForm.setFieldValue("medicalDetails.additionalFundsAmountAvailed", medicalAidFormDetails?.medicalDetails?.additionalFundsAvailed?.amount)

            formikForm.setFieldValue("medicalDetails.costOfTreatment", medicalAidFormDetails?.medicalDetails?.costOfTreatment)



            formikForm.setFieldValue("medicalDetails.doctorName", medicalAidFormDetails?.medicalDetails?.doctorName)
            formikForm.setFieldValue("medicalDetails.healthInsuranceName", medicalAidFormDetails?.medicalDetails?.healthInsuranceName?.name)
            formikForm.setFieldValue("medicalDetails.healthInsuranceAmountAvailed", medicalAidFormDetails?.medicalDetails?.healthInsuranceName?.amount)
            formikForm.setFieldValue("medicalDetails.previousMedicalAidFromLMP", medicalAidFormDetails?.medicalDetails?.previousMedicalAidFromLMP?.name)
            formikForm.setFieldValue("medicalDetails.previousMedicalAidFromLMPAmountAvailed", medicalAidFormDetails?.medicalDetails?.previousMedicalAidFromLMP?.amount)
            formikForm.setFieldValue("medicalDetails.sufferingSince", medicalAidFormDetails?.medicalDetails?.sufferingSince)
            formikForm.setFieldValue("medicalDetails.admittedAt", medicalAidFormDetails?.medicalDetails?.admittedAt)

            formikForm.setFieldValue("familyDetails", medicalAidFormDetails?.familyDetails)
            setFamilyDetails(medicalAidFormDetails?.familyDetails)


            formikForm.setFieldValue("bankDetails.accountHolder", medicalAidFormDetails?.bankDetails?.accountHolder)
            formikForm.setFieldValue("bankDetails.bankName", medicalAidFormDetails?.bankDetails?.bankName)
            formikForm.setFieldValue("bankDetails.branch", medicalAidFormDetails?.bankDetails?.branch)
            formikForm.setFieldValue("bankDetails.accountNumber", medicalAidFormDetails?.bankDetails?.accountNumber)
            formikForm.setFieldValue("bankDetails.ifscCode", medicalAidFormDetails?.bankDetails?.ifscCode)
            formikForm.setFieldValue("bankDetails.date", medicalAidFormDetails?.bankDetails?.date)
            formikForm.setFieldValue("bankDetails.signature", medicalAidFormDetails?.bankDetails?.signature?.url)

            formikForm.setFieldValue("mahajanDetails.nearestMahajan", medicalAidFormDetails?.mahajanDetails?.nearestMahajan)
            formikForm.setFieldValue("mahajanDetails.name", medicalAidFormDetails?.mahajanDetails?.name)
            formikForm.setFieldValue("mahajanDetails.age", medicalAidFormDetails?.mahajanDetails?.age)
            formikForm.setFieldValue("mahajanDetails.livingIn", medicalAidFormDetails?.mahajanDetails?.livingIn)
            formikForm.setFieldValue("bankDetails.illness", medicalAidFormDetails?.bankDetails?.illness)

            formikForm.setFieldValue("zonalRegionOfficeDetails.designation", medicalAidFormDetails?.zonalRegionOfficeDetails?.designation)
            formikForm.setFieldValue("zonalRegionOfficeDetails.name", medicalAidFormDetails?.zonalRegionOfficeDetails?.name)
            formikForm.setFieldValue("zonalRegionOfficeDetails.address", medicalAidFormDetails?.zonalRegionOfficeDetails?.address)
            formikForm.setFieldValue("zonalRegionOfficeDetails.designationInMahaparishad", medicalAidFormDetails?.zonalRegionOfficeDetails?.designationInMahaparishad)

            formikForm.setFieldValue("officeDetails.interviewDate", medicalAidFormDetails?.officeDetails?.interviewDate)
            formikForm.setFieldValue("officeDetails.interviewName", medicalAidFormDetails?.officeDetails?.interviewName)
            formikForm.setFieldValue("officeDetails.grantedAssistance", medicalAidFormDetails?.officeDetails?.grantedAssistance)
            formikForm.setFieldValue("officeDetails.chequeDetailsName", medicalAidFormDetails?.officeDetails?.chequeDetails?.name)
            formikForm.setFieldValue("officeDetails.chequeDetailsAmount", medicalAidFormDetails?.officeDetails?.chequeDetails?.amount)
            formikForm.setFieldValue("officeDetails.chequeDetailsNumber", medicalAidFormDetails?.officeDetails?.chequeDetails?.number)
            formikForm.setFieldValue("officeDetails.chequeDetailsDate", medicalAidFormDetails?.officeDetails?.chequeDetails?.date)


            formikForm.setFieldValue("filesList", {
                incomeProof: medicalAidFormDetails?.patientDetails?.annualIncome?.file?.url,
                profile: medicalAidFormDetails?.patientDetails?.patientImage?.url,
                costEstimate: medicalAidFormDetails?.medicalDetails?.estimatedTreatmentCost?.url,
                reports: medicalAidFormDetails?.medicalDetails?.reports?.url,
                stamp: medicalAidFormDetails?.mahajanDetails?.stamp?.url,
                mahajanPresident: medicalAidFormDetails?.mahajanDetails?.signature?.url,
                zonalPresident: medicalAidFormDetails?.zonalRegionOfficeDetails?.signature?.url,
                signatureOffice: medicalAidFormDetails?.officeDetails?.signature?.url
            })

        }
    }, [medicalAidFormDetails]);

    useEffect(() => { handleGetMedicalFormDetails() }, [id])
    return (
        <section
            className="px-0 py-2 xl:p-10 lg:p-10 min-h-screen xl:min-h-full lg:min-h-full"
        >


            {/* HEADING */}
            <div className="block xl:flex lg:flex items-center justify-between">
                <div>
                    <h1 className="text-3xl font-proxima-bold text-black tracking-tight">
                        Medical Aid Form
                    </h1>
                    <h2 className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText tracking-tight">
                        View filled medical aid form details
                    </h2>
                </div>
                <div className="flex items-center gap-2 mt-2 xl:mt-0 lg:mt-0">
                    <Link to="/medical-aid">
                        <Button className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-white hover:bg-black hover:bg-opacity-5 px-5 py-3 rounded-md border border-authBorder text-sm font-proxima-semibold text-black normal-case transition-all duration-300 ease-in-out">
                            Back to Medical Aid Forms List
                        </Button>
                    </Link>
                </div>
            </div>

            {/* FORM */}
            <div className="container-full-width">
                <form className="p-0" onSubmit={formikForm.handleSubmit}>


                    {/* LOHANA MAHAPARISHAD DETAILS */}
                    <div className="w-full bg-white border border-greyBorder rounded-xl shadow p-5 xl:p-8 lg:p-5">
                        <h5 className="text-xl xl:text-2xl lg:text-2xl font-sunflower-bold text-black tracking-tight">
                            Shree Lohana Mahaparishad Details
                        </h5>
                        <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5 mt-3">
                            <div className="col-span-3">
                                <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 gap-5">
                                    {/* DISTRICT */}
                                    <div className="col-span-1 xl:col-span-2 lg:col-span-2">
                                        <div className="form-group">
                                            <label
                                                className="block text-black text-sm font-proxima-semibold"
                                                htmlFor="lmpState"
                                            >
                                                Select District{" "}
                                                <span className="text-red-500">*</span>
                                            </label>
                                            <div className="mt-1">
                                                <Select
                                                    className="react-select"
                                                    classNamePrefix={
                                                        formikForm.errors.district &&
                                                            formikForm.touched.district
                                                            ? "dropdown_error"
                                                            : "dropdown"
                                                    }
                                                    onChange={(e) => {
                                                        formikForm.setFieldValue("district", e);
                                                        formikForm.setFieldValue("zoneId", {
                                                            label: e?.zoneName,
                                                            value: e?.zoneId,
                                                        });
                                                    }}
                                                    value={formikForm.values.district}
                                                    options={regionsList}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* ZONE */}
                                    <div className="col-span-1 xl:col-span-2 lg:col-span-2">
                                        <label
                                            className="block text-black text-sm font-proxima-semibold"
                                            htmlFor="zone"
                                        >
                                            Zone <span className="text-red-500">*</span>
                                        </label>
                                        <div className="mt-1">
                                            <InputField
                                                type="text"
                                                name="zoneId"
                                                isDisabled={true}
                                                error={
                                                    formikForm.errors.zoneId &&
                                                    formikForm.touched.zoneId
                                                }
                                                value={formikForm.values.zoneId?.label}
                                                onChange={formikForm.handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* PATIENT DETAILS */}
                    <div className="mt-5">
                        <div className="w-full bg-white border border-greyBorder rounded-xl p-5 xl:p-8 lg:p-5">
                            <h5 className="text-xl xl:text-2xl lg:text-2xl font-sunflower-bold text-black tracking-tight">
                                Patient Details
                            </h5>
                            <div className="mt-3 grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-2 gap-5">
                                <div className="col-span-1">
                                    <label
                                        className="block text-black text-sm font-proxima-semibold"
                                        htmlFor="firstName"
                                    >
                                        First name
                                    </label>
                                    <div className="mt-1">
                                        <InputField
                                            type="text"
                                            name="patientFirstName"
                                            id="patientFirstName"
                                            placeholder="First name"
                                            error={
                                                formikForm.errors.patientFirstName &&
                                                formikForm.touched.patientFirstName
                                            }
                                            value={formikForm.values.patientFirstName}
                                            onChange={formikForm.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <label
                                        className="block text-black text-sm font-proxima-semibold"
                                        htmlFor="middleName"
                                    >
                                        Middle/Father&apos;s/Husband&apos;s name
                                    </label>
                                    <div className="mt-1">
                                        <InputField
                                            type="text"
                                            name="patientMiddleName"
                                            id="patientMiddleName"
                                            placeholder="Middle/Father's/Husband's name"
                                            error={
                                                formikForm.errors.patientMiddleName &&
                                                formikForm.touched.patientMiddleName
                                            }
                                            value={formikForm.values.patientMiddleName}
                                            onChange={formikForm.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <label
                                        className="block text-black text-sm font-proxima-semibold"
                                        htmlFor="lastName"
                                    >
                                        Last name (Surname)
                                    </label>
                                    <div className="mt-1">
                                        <InputField
                                            type="text"
                                            name="patientLastName"
                                            id="patientLastName"
                                            placeholder="Last name"
                                            error={
                                                formikForm.errors.patientLastName &&
                                                formikForm.touched.patientLastName
                                            }
                                            value={formikForm.values.patientLastName}
                                            onChange={formikForm.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-span-1 xl:col-span-2 lg:col-span-1">
                                    <label
                                        className="block text-black text-sm font-proxima-semibold"
                                        htmlFor="address"
                                    >
                                        Address
                                    </label>
                                    <div className="mt-1">
                                        <InputField
                                            type="text"
                                            name="addressLineOne"
                                            id="addressLineOne"
                                            placeholder="Address"
                                            error={
                                                formikForm.errors.addressLineOne &&
                                                formikForm.touched.addressLineOne
                                            }
                                            value={formikForm.values.addressLineOne}
                                            onChange={formikForm.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <label
                                        className="block text-black text-sm font-proxima-semibold"
                                        htmlFor="pincode"
                                    >
                                        Pincode
                                    </label>
                                    <div className="mt-1">
                                        <InputField
                                            type="number"
                                            name="addressPinCode"
                                            id="addressPinCode"
                                            placeholder="Pincode"
                                            error={
                                                formikForm.errors.addressPinCode &&
                                                formikForm.touched.addressPinCode
                                            }
                                            value={formikForm.values.addressPinCode}
                                            onChange={formikForm.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <label
                                        className="block text-black text-sm font-proxima-semibold"
                                        htmlFor="nativePlace"
                                    >
                                        Native Place
                                    </label>
                                    <div className="mt-1">
                                        <InputField
                                            type="text"
                                            name="nativePlace"
                                            id="nativePlace"
                                            placeholder="Native Place"
                                            error={
                                                formikForm.errors.nativePlace &&
                                                formikForm.touched.nativePlace
                                            }
                                            value={formikForm.values.nativePlace}
                                            onChange={formikForm.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <label
                                        className="block text-black text-sm font-proxima-semibold"
                                        htmlFor="age"
                                    >
                                        Age
                                    </label>
                                    <div className="mt-1">
                                        <InputField
                                            type="number"
                                            name="patientAge"
                                            id="patientAge"
                                            placeholder="Age"
                                            error={
                                                formikForm.errors.patientAge &&
                                                formikForm.touched.patientAge
                                            }
                                            value={formikForm.values.patientAge}
                                            onChange={formikForm.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <label
                                        className="block text-black text-sm font-proxima-semibold"
                                        htmlFor="contact"
                                    >
                                        Contact No.
                                    </label>
                                    <div className="mt-1">
                                        <PhoneInput
                                            placeholder="Phone"
                                            defaultCountry="IN"
                                            className={`mt-1 ${formikForm.errors.phoneNumber &&
                                                formikForm.touched.phoneNumber &&
                                                "error"
                                                }`}
                                            value={formikForm.values.phoneNumber}
                                            onChange={(e) => {
                                                formikForm.setFieldValue("phoneNumber", e);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
                                <div className="col-span-1">
                                    <label
                                        className="block text-black text-sm font-proxima-semibold"
                                        htmlFor="annualIncomeAmount"
                                    >
                                        Annual Income of Household (₹)
                                    </label>
                                    <div className="mt-1">
                                        <InputField
                                            type="number"
                                            name="annualIncomeAmount"
                                            id="annualIncomeAmount"
                                            placeholder="Annual Income of Household"
                                            error={
                                                formikForm.errors.annualIncomeAmount &&
                                                formikForm.touched.annualIncomeAmount
                                            }
                                            value={formikForm.values.annualIncomeAmount}
                                            onChange={formikForm.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <label
                                        className="block text-black text-sm font-proxima-semibold"
                                        htmlFor="earningMemberCount"
                                    >
                                        Total no. of earning members in family
                                    </label>
                                    <div className="mt-1">
                                        <InputField
                                            type="number"
                                            name="earningMemberCount"
                                            id="earningMemberCount"
                                            placeholder="Total no. of earning members in family"
                                            error={
                                                formikForm.errors.earningMemberCount &&
                                                formikForm.touched.earningMemberCount
                                            }
                                            value={formikForm.values.earningMemberCount}
                                            onChange={formikForm.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
                                <div className="col-span-1">
                                    <div className="form-group">
                                        <label
                                            className="block text-black text-sm font-proxima-semibold"
                                            htmlFor="photo"
                                        >
                                            Upload patient&apos;s profile photo
                                        </label>
                                        <div className="mt-1">
                                            <ReactImageUploading
                                                value={formikForm.values.photo}
                                                onChange={(imageList) => {
                                                    formikForm.setFieldValue("photo", imageList);
                                                }}
                                                maxNumber={1}
                                                dataURLKey="data_url"
                                            >
                                                {({
                                                    imageList,
                                                    onImageUpload,
                                                    onImageRemove,
                                                }) => (
                                                    <div className="flex items-center gap-5 mt-2">
                                                        {/* UPLOAD BOX */}
                                                        <div
                                                            className={`w-[15vh] h-[15vh] cursor-pointer bg-greyBg bg-opacity-60 border ${formikForm.errors.photo &&
                                                                formikForm.touched.photo
                                                                ? "border-red-300 hover:bg-red-300 hover:bg-opacity-10 hover:border-red-300"
                                                                : "border-greyBorder hover:bg-logoYellow hover:bg-opacity-10 hover:border-logoYellow"
                                                                }  rounded-lg p-5 flex-shrink-0 flex items-center justify-center transition-all duration-300 ease-in-out`}
                                                            onClick={onImageUpload}
                                                        >
                                                            <div className="text-center">
                                                                <IoCloudUpload className="w-8 h-8 text-greyText mx-auto" />
                                                                <p className="mt-2 text-xs font-proxima-semibold text-greyText leading-tight text-center">
                                                                    Upload image from computer
                                                                </p>
                                                            </div>
                                                        </div>

                                                        {/* IMAGE LIST */}
                                                        {imageList.map((image, index) => (
                                                            <div
                                                                className="w-[15vh] h-[15vh] relative"
                                                                key={index}
                                                            >
                                                                <img
                                                                    src={image["data_url"]}
                                                                    className="object-cover rounded-lg"
                                                                    fill
                                                                    alt="Uploaded"
                                                                />

                                                                <div className="absolute -top-2 -right-2">
                                                                    <Button
                                                                        className="shadow-none hover:shadow-none p-2 rounded-full bg-white border border-greyBorder hover:border-logoRed transition-all duration-300 ease-in-out"
                                                                        onClick={onImageRemove}
                                                                    >
                                                                        <FaTrash className="w-3 h-3 text-greyText" />
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        ))}

                                                        {/* IMAGE LIST */}
                                                        <div
                                                            className="w-[15vh] h-[15vh] relative"
                                                        >
                                                            <img
                                                                src={medicalAidFormDetails?.patientDetails?.patientImage?.url}
                                                                className="object-cover rounded-lg"
                                                                fill
                                                                alt="Uploaded"
                                                            />

                                                            <div className="absolute -top-2 -right-2">
                                                                <Button
                                                                    className="shadow-none hover:shadow-none p-2 rounded-full bg-white border border-greyBorder hover:border-logoRed transition-all duration-300 ease-in-out"
                                                                    onClick={onImageRemove}
                                                                >
                                                                    <FaTrash className="w-3 h-3 text-greyText" />
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </ReactImageUploading>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <div className="form-group">
                                        <label
                                            className="block text-black text-sm font-proxima-semibold"
                                            htmlFor="incomeProof"
                                        >
                                            Upload income proof of earning members
                                        </label>
                                        <div className="mt-2">
                                            <div className="flex items-center gap-5">
                                                <input
                                                    id="incomeProof"
                                                    className="hidden"
                                                    name="incomeProof"
                                                    type="file"
                                                    accept=".doc,.docx,.pdf"
                                                    disabled={
                                                        formikForm?.values?.incomeProof?.length !==
                                                        0
                                                    }
                                                    onChange={(e) => {
                                                        var temp = [];
                                                        setDocuments(e.target.files);
                                                        temp.push(e.target.files[0]);
                                                        formikForm.setFieldValue(
                                                            "incomeProof",
                                                            e.target.files[0]
                                                        );
                                                    }}
                                                />
                                                <label htmlFor="incomeProof">
                                                    <div
                                                        className={`w-[15vh] h-[15vh] cursor-pointer bg-greyBg bg-opacity-50 border ${formikForm.errors.incomeProof &&
                                                            formikForm.touched.incomeProof
                                                            ? "border-red-300 hover:bg-red-300 hover:bg-opacity-10 hover:border-red-300"
                                                            : "border-greyBorder hover:bg-logoYellow hover:bg-opacity-10 hover:border-logoYellow"
                                                            } rounded-lg p-5 flex-shrink-0 flex items-center justify-center transition-all duration-300 ease-in-out`}
                                                    >
                                                        <div className="text-center">
                                                            <IoCloudUpload className="w-8 h-8 text-greyText mx-auto" />
                                                            <p className="mt-2 text-xs font-proxima-semibold text-greyText leading-tight text-center">
                                                                Upload file from computer
                                                            </p>
                                                        </div>
                                                    </div>
                                                </label>

                                                {/* UPLOADED FILE */}
                                                {documents !== undefined && (
                                                    <div className="w-[15vh] h-[15vh] border border-greyBorder rounded-lg p-5 flex-shrink-0 flex items-center justify-center relative">
                                                        <div className="text-center">
                                                            {documents[0]?.type ===
                                                                "application/pdf" ? (
                                                                <FaFilePdf className="w-6 h-6 text-[#f15642] mx-auto" />
                                                            ) : (
                                                                <IoDocumentText className="w-6 h-6 text-[#508EF5] mx-auto" />
                                                            )}

                                                            <p className="mt-3 text-xs font-proxima-semibold text-greyText leading-tight text-center">
                                                                {documents[0]?.name}
                                                            </p>
                                                            <p className="mt-1 text-xs font-proxima-regular text-greyText">
                                                                {bytesToSize(documents[0]?.size)}
                                                            </p>
                                                        </div>

                                                        <div className="absolute -top-2 -right-2">
                                                            <Button
                                                                className="shadow-none hover:shadow-none p-2 rounded-full bg-white border border-greyBorder hover:border-logoRed transition-all duration-300 ease-in-out"
                                                                onClick={() => setDocuments()}
                                                            >
                                                                <FaTrash className="w-3 h-3 text-greyText" />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                )}

                                                {medicalAidFormDetails?.patientDetails?.annualIncome?.file?.url && <Link target="_blank" to={medicalAidFormDetails?.patientDetails?.annualIncome?.file?.url}>
                                                    <div className="w-[15vh] h-[15vh] border border-greyBorder rounded-lg p-5 flex-shrink-0 flex items-center justify-center relative">
                                                        <div className="text-center">

                                                            {medicalAidFormDetails?.patientDetails?.annualIncome?.file?.url?.includes(".pdf") ? (
                                                                <FaFilePdf className="w-6 h-6 text-[#f15642] mx-auto" />
                                                            ) : (
                                                                <IoDocumentText className="w-6 h-6 text-[#508EF5] mx-auto" />
                                                            )}

                                                            <p className="mt-3 text-xs font-proxima-semibold text-greyText leading-tight text-center">
                                                                Income Proof Document
                                                            </p>
                                                        </div>

                                                        <div className="absolute -top-2 -right-2">
                                                            <Button
                                                                className="shadow-none hover:shadow-none p-2 rounded-full bg-white border border-greyBorder hover:border-logoRed transition-all duration-300 ease-in-out"
                                                                onClick={() => setDocuments()}
                                                            >
                                                                <FaTrash className="w-3 h-3 text-greyText" />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Link>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* DISEASE DETAILS */}
                    <div className="mt-5">
                        <div className="w-full bg-white border border-greyBorder rounded-xl p-5 xl:p-8 lg:p-5">
                            <h5 className="text-xl xl:text-2xl lg:text-2xl font-sunflower-bold text-black tracking-tight">
                                Medical Condition Details
                            </h5>
                            <div className="mt-3 grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-1 gap-5">
                                <div className="col-span-1 xl:col-span-2 lg:col-span-1">
                                    <label
                                        className="block text-black text-sm font-proxima-semibold"
                                        htmlFor="medicalDetails.medicalCondition"
                                    >
                                        Name of Disease/Illness/Medical Condition
                                    </label>
                                    <div className="mt-1">
                                        <InputField
                                            type="text"
                                            name="medicalDetails.medicalCondition"
                                            id="medicalDetails.medicalCondition"
                                            placeholder="Name of Disease/Illness/Medical Condition"
                                            error={
                                                formikForm.errors?.medicalDetails
                                                    ?.medicalCondition &&
                                                formikForm.touched?.medicalDetails
                                                    ?.medicalCondition
                                            }
                                            value={
                                                formikForm.values?.medicalDetails
                                                    ?.medicalCondition
                                            }
                                            onChange={formikForm.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <label
                                        className="block text-black text-sm font-proxima-semibold"
                                        htmlFor="medicalDetails.sufferingSince"
                                    >
                                        Suffering since
                                    </label>
                                    <div className="mt-1">
                                        <ReactDatePicker
                                            selected={
                                                formikForm?.values?.medicalDetails
                                                    ?.sufferingSince
                                            }
                                            onChange={(date) =>
                                                formikForm.setFieldValue(
                                                    "medicalDetails.sufferingSince",
                                                    date
                                                )
                                            }
                                            name="medicalDetails.sufferingSince"
                                            dateFormat="dd/MM/yyyy"
                                            className={`block w-full bg-white ${formikForm?.errors?.medicalDetails
                                                ?.sufferingSince &&
                                                formikForm?.touched?.medicalDetails
                                                    ?.sufferingSince
                                                ? "border-red-300"
                                                : "border-greyBorder"
                                                } border rounded-lg px-4 py-3 font-proxima-semibold placeholder:font-proxima-regular text-sm placeholder:text-sm`}
                                        />
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <label
                                        className="block text-black text-sm font-proxima-semibold"
                                        htmlFor="medicalDetails.doctorName"
                                    >
                                        Doctor&apos;s name
                                    </label>
                                    <div className="mt-1">
                                        <InputField
                                            type="text"
                                            name="medicalDetails.doctorName"
                                            id="medicalDetails.doctorName"
                                            placeholder="Doctor name"
                                            error={
                                                formikForm?.errors?.medicalDetails
                                                    ?.doctorName &&
                                                formikForm?.touched?.medicalDetails?.doctorName
                                            }
                                            value={
                                                formikForm?.values?.medicalDetails?.doctorName
                                            }
                                            onChange={formikForm.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <label
                                        className="block text-black text-sm font-proxima-semibold"
                                        htmlFor="medicalDetails.hospitalName"
                                    >
                                        Hospital/Clinic name
                                    </label>
                                    <div className="mt-1">
                                        <InputField
                                            type="text"
                                            name="medicalDetails.hospitalName"
                                            id="medicalDetails.hospitalName"
                                            placeholder="Hospital name"
                                            error={
                                                formikForm?.errors?.medicalDetails
                                                    ?.hospitalName &&
                                                formikForm?.touched?.medicalDetails
                                                    ?.hospitalName
                                            }
                                            value={
                                                formikForm?.values?.medicalDetails?.hospitalName
                                            }
                                            onChange={formikForm.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <label
                                        className="block text-black text-sm font-proxima-semibold"
                                        htmlFor="medicalDetails.admittedAt"
                                    >
                                        Date when admitted
                                    </label>
                                    <div className="mt-1">
                                        <ReactDatePicker
                                            name="medicalDetails.admittedAt"
                                            selected={
                                                formikForm?.values?.medicalDetails?.admittedAt
                                            }
                                            onChange={(date) =>
                                                formikForm.setFieldValue(
                                                    "medicalDetails.admittedAt",
                                                    date
                                                )
                                            }
                                            dateFormat="dd/MM/yyyy"
                                            className={`block w-full bg-white ${formikForm?.errors?.medicalDetails
                                                ?.admittedAt &&
                                                formikForm?.touched?.medicalDetails?.admittedAt
                                                ? "border-red-300"
                                                : "border-greyBorder"
                                                } border rounded-lg px-4 py-3 font-proxima-semibold placeholder:font-proxima-regular text-sm placeholder:text-sm`}
                                        />
                                    </div>
                                </div>
                                <div className="col-span-1 xl:col-span-3 lg:col-span-1">
                                    <label
                                        className="block text-black text-sm font-proxima-semibold"
                                        htmlFor="medicalDetails.hospitalAddress"
                                    >
                                        Hospital/Clinic address
                                    </label>
                                    <div className="mt-1">
                                        <TextareaField
                                            rows={2}
                                            name="medicalDetails.hospitalAddress"
                                            id="medicalDetails.hospitalAddress"
                                            placeholder="Hospital address"
                                            error={
                                                formikForm?.errors?.medicalDetails
                                                    ?.hospitalAddress &&
                                                formikForm?.touched?.medicalDetails
                                                    ?.hospitalAddress
                                            }
                                            value={
                                                formikForm?.values?.medicalDetails
                                                    ?.hospitalAddress
                                            }
                                            onChange={formikForm.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <label
                                        className="block text-black text-sm font-proxima-semibold"
                                        htmlFor="medicalDetails.costOfTreatment"
                                    >
                                        Total cost of treatment
                                    </label>
                                    <div className="mt-1">
                                        <InputField
                                            type="number"
                                            name="medicalDetails.costOfTreatment"
                                            id="medicalDetails.costOfTreatment"
                                            placeholder="Approx. cost of treatment"
                                            error={
                                                formikForm?.errors?.medicalDetails
                                                    ?.costOfTreatment &&
                                                formikForm?.touched?.medicalDetails
                                                    ?.costOfTreatment
                                            }
                                            value={
                                                formikForm?.values?.medicalDetails
                                                    ?.costOfTreatment
                                            }
                                            onChange={formikForm.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <label
                                        className="block text-black text-sm font-proxima-semibold"
                                        htmlFor="medicalDetails.careTakerName"
                                    >
                                        Name of caretaker/contact person for patient
                                    </label>
                                    <div className="mt-1">
                                        <InputField
                                            type="text"
                                            name="medicalDetails.careTakerName"
                                            id="medicalDetails.careTakerName"
                                            placeholder="Name of caretaker/contact person for patient"
                                            error={
                                                formikForm?.errors?.medicalDetails
                                                    ?.careTakerName &&
                                                formikForm?.touched?.medicalDetails
                                                    ?.careTakerName
                                            }
                                            value={
                                                formikForm?.values?.medicalDetails
                                                    ?.careTakerName
                                            }
                                            onChange={formikForm.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <label
                                        className="block text-black text-sm font-proxima-semibold"
                                        htmlFor="medicalDetails.careTakerPhoneNumber"
                                    >
                                        Caretaker/Contact person&apos;s mobile number
                                    </label>
                                    <div className="mt-1">
                                        <PhoneInput
                                            placeholder="Phone"
                                            defaultCountry="IN"
                                            className={`mt-1 ${formikForm?.errors?.medicalDetails
                                                ?.careTakerPhoneNumber &&
                                                formikForm?.touched?.medicalDetails
                                                    ?.careTakerPhoneNumber
                                                ? "error"
                                                : ""
                                                }`}
                                            name="medicalDetails.careTakerPhoneNumber"
                                            value={
                                                formikForm?.values?.medicalDetails
                                                    ?.careTakerPhoneNumber
                                            }
                                            onChange={(e) =>
                                                formikForm.setFieldValue(
                                                    "medicalDetails.careTakerPhoneNumber",
                                                    e
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-span-1 xl:col-span-2 lg:col-span-2">
                                    <label
                                        className="block text-black text-sm font-proxima-semibold"
                                        htmlFor="medicalDetails.healthInsuranceName"
                                    >
                                        Name and details of Mediclaim or Private / Goverment
                                        Health Scheme / Health Insurance availed
                                    </label>
                                    <div className="mt-1">
                                        <TextareaField
                                            rows={2}
                                            name="medicalDetails.healthInsuranceName"
                                            id="medicalDetails.healthInsuranceName"
                                            placeholder="Name and details of Mediclaim or Private / Goverment Health Scheme / Health Insurance availed"
                                            error={
                                                formikForm?.errors?.medicalDetails
                                                    ?.healthInsuranceName &&
                                                formikForm?.touched?.medicalDetails
                                                    ?.healthInsuranceName
                                            }
                                            value={
                                                formikForm?.values?.medicalDetails
                                                    ?.healthInsuranceName
                                            }
                                            onChange={formikForm.handleChange}
                                        />
                                    </div>
                                </div>
                                {/* AMOUNT AVAILED */}
                                <div className="col-span-1">
                                    <label
                                        className="block text-black text-sm font-proxima-semibold"
                                        htmlFor="medicalDetails.healthInsuranceAmountAvailed"
                                    >
                                        Amount Availed (₹)
                                    </label>
                                    <div className="mt-1">
                                        <InputField
                                            type="text"
                                            name="medicalDetails.healthInsuranceAmountAvailed"
                                            id="medicalDetails.healthInsuranceAmountAvailed"
                                            placeholder="Amount Availed"
                                            error={
                                                formikForm?.errors?.medicalDetails
                                                    ?.healthInsuranceAmountAvailed &&
                                                formikForm?.touched?.medicalDetails
                                                    ?.healthInsuranceAmountAvailed
                                            }
                                            value={
                                                formikForm?.values?.medicalDetails
                                                    ?.healthInsuranceAmountAvailed
                                            }
                                            onChange={formikForm.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-span-1 xl:col-span-2 lg:col-span-2">
                                    <label
                                        className="block text-black text-sm font-proxima-semibold"
                                        htmlFor="medicalDetails.additionalFundsAvailed"
                                    >
                                        Name and details of any additional funds or
                                        assistance availed from any organization
                                    </label>
                                    <div className="mt-1">
                                        <TextareaField
                                            rows={2}
                                            name="medicalDetails.additionalFundsAvailed"
                                            id="medicalDetails.additionalFundsAvailed"
                                            placeholder="Name and details of any additional funds or assistance availed from any organization"
                                            error={
                                                formikForm?.errors?.medicalDetails
                                                    ?.additionalFundsAvailed &&
                                                formikForm?.touched?.medicalDetails
                                                    ?.additionalFundsAvailed
                                            }
                                            values={
                                                formikForm?.values?.medicalDetails
                                                    ?.additionalFundsAvailed
                                            }
                                            onChange={formikForm.handleChange}
                                        />
                                    </div>
                                </div>
                                {/* AMOUNT AVAILED */}
                                <div className="col-span-1">
                                    <label
                                        className="block text-black text-sm font-proxima-semibold"
                                        htmlFor="medicalDetails.additionalFundsAmountAvailed"
                                    >
                                        Amount Availed (₹)
                                    </label>
                                    <div className="mt-1">
                                        <InputField
                                            type="text"
                                            name="medicalDetails.additionalFundsAmountAvailed"
                                            id="medicalDetails.additionalFundsAmountAvailed"
                                            placeholder="Amount Availed"
                                            error={
                                                formikForm?.errors?.medicalDetails
                                                    ?.additionalFundsAmountAvailed &&
                                                formikForm?.touched?.medicalDetails
                                                    ?.additionalFundsAmountAvailed
                                            }
                                            value={
                                                formikForm?.values?.medicalDetails
                                                    ?.additionalFundsAmountAvailed
                                            }
                                            onChange={formikForm.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-span-1 xl:col-span-2 lg:col-span-2">
                                    <label
                                        className="block text-black text-sm font-proxima-semibold"
                                        htmlFor="medicalDetails.previousMedicalAidFromLMP"
                                    >
                                        Details of any previous medical aid received from
                                        Shree Lohana Mahaparishad
                                    </label>
                                    <div className="mt-1">
                                        <TextareaField
                                            rows={2}
                                            name="medicalDetails.previousMedicalAidFromLMP"
                                            id="medicalDetails.previousMedicalAidFromLMP"
                                            placeholder="Details of any previous medical aid received from Shree Lohana Mahaparishad"
                                            error={
                                                formikForm?.errors?.medicalDetails
                                                    ?.previousMedicalAidFromLMP &&
                                                formikForm?.touched?.medicalDetails
                                                    ?.previousMedicalAidFromLMP
                                            }
                                            value={
                                                formikForm?.values?.medicalDetails
                                                    ?.previousMedicalAidFromLMP
                                            }
                                            onChange={formikForm.handleChange}
                                        />
                                    </div>
                                </div>
                                {/* AMOUNT AVAILED */}
                                <div className="col-span-1">
                                    <label
                                        className="block text-black text-sm font-proxima-semibold"
                                        htmlFor="medicalDetails.previousMedicalAidFromLMPAmountAvailed"
                                    >
                                        Amount Availed (₹)
                                    </label>
                                    <div className="mt-1">
                                        <InputField
                                            type="text"
                                            name="medicalDetails.previousMedicalAidFromLMPAmountAvailed"
                                            id="medicalDetails.previousMedicalAidFromLMPAmountAvailed"
                                            placeholder="Amount Availed"
                                            error={
                                                formikForm?.errors?.medicalDetails
                                                    ?.previousMedicalAidFromLMPAmountAvailed &&
                                                formikForm?.touched?.medicalDetails
                                                    ?.previousMedicalAidFromLMPAmountAvailed
                                            }
                                            value={
                                                formikForm?.values?.medicalDetails
                                                    ?.previousMedicalAidFromLMPAmountAvailed
                                            }
                                            onChange={formikForm.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-1 gap-5">
                                <div className="col-span-1">
                                    <div className="form-group">
                                        <label
                                            className="block text-black text-sm font-proxima-semibold"
                                            htmlFor="treatmentCost"
                                        >
                                            Upload treatment cost estimate (on hospital/clinic
                                            letterhead)
                                        </label>
                                        <div className="mt-2">
                                            <div className="flex items-center gap-5">
                                                <input
                                                    id="costEstimate"
                                                    name="costEstimate"
                                                    className="hidden"
                                                    type="file"
                                                    accept=".doc,.docx,.pdf"
                                                    disabled={
                                                        formikForm?.values?.costEstimate?.length !==
                                                        0
                                                    }
                                                    onChange={(e) => {
                                                        var temp = [];
                                                        setCostDocument(e.target.files);
                                                        temp.push(e.target.files[0]);
                                                        formikForm.setFieldValue(
                                                            "costEstimate",
                                                            e.target.files[0]
                                                        );
                                                    }}
                                                />
                                                <label htmlFor="costEstimate">
                                                    <div
                                                        className={`w-[15vh] h-[15vh] cursor-pointer bg-greyBg bg-opacity-50 border ${formikForm.errors.costEstimate &&
                                                            formikForm.touched.costEstimate
                                                            ? "border-red-300 hover:bg-red-300 hover:bg-opacity-10 hover:border-red-300"
                                                            : "border-greyBorder hover:bg-logoYellow hover:bg-opacity-10 hover:border-logoYellow"
                                                            } rounded-lg p-5 flex-shrink-0 flex items-center justify-center transition-all duration-300 ease-in-out`}
                                                    >
                                                        <div className="text-center">
                                                            <IoCloudUpload className="w-8 h-8 text-greyText mx-auto" />
                                                            <p className="mt-2 text-xs font-proxima-semibold text-greyText leading-tight text-center">
                                                                Upload file from computer
                                                            </p>
                                                        </div>
                                                    </div>
                                                </label>

                                                {/* UPLOADED FILE */}
                                                {costDocument !== undefined && (
                                                    <div className="w-[15vh] h-[15vh] border border-greyBorder rounded-lg p-5 flex-shrink-0 flex items-center justify-center relative">
                                                        <div className="text-center">
                                                            {costDocument[0]?.type ===
                                                                "application/pdf" ? (
                                                                <FaFilePdf className="w-6 h-6 text-[#f15642] mx-auto" />
                                                            ) : (
                                                                <IoDocumentText className="w-6 h-6 text-[#508EF5] mx-auto" />
                                                            )}

                                                            <p className="mt-3 text-xs font-proxima-semibold text-greyText leading-tight text-center">
                                                                {costDocument[0]?.name}
                                                            </p>
                                                            <p className="mt-1 text-xs font-proxima-regular text-greyText">
                                                                {bytesToSize(costDocument[0]?.size)}
                                                            </p>
                                                        </div>

                                                        <div className="absolute -top-2 -right-2">
                                                            <Button
                                                                className="shadow-none hover:shadow-none p-2 rounded-full bg-white border border-greyBorder hover:border-logoRed transition-all duration-300 ease-in-out"
                                                                onClick={() => setDocuments()}
                                                            >
                                                                <FaTrash className="w-3 h-3 text-greyText" />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                )}

                                                {medicalAidFormDetails?.medicalDetails?.estimatedTreatmentCost?.url && <Link target="_blank" to={medicalAidFormDetails?.medicalDetails?.estimatedTreatmentCost?.url}>
                                                    <div className="w-[15vh] h-[15vh] border border-greyBorder rounded-lg p-5 flex-shrink-0 flex items-center justify-center relative">
                                                        <div className="text-center">

                                                            {medicalAidFormDetails?.medicalDetails?.estimatedTreatmentCost?.url?.includes(".pdf") ? (
                                                                <FaFilePdf className="w-6 h-6 text-[#f15642] mx-auto" />
                                                            ) : (
                                                                <IoDocumentText className="w-6 h-6 text-[#508EF5] mx-auto" />
                                                            )}

                                                            <p className="mt-3 text-xs font-proxima-semibold text-greyText leading-tight text-center">
                                                                Treatment Cost Estimate Document
                                                            </p>
                                                        </div>

                                                        <div className="absolute -top-2 -right-2">
                                                            <Button
                                                                className="shadow-none hover:shadow-none p-2 rounded-full bg-white border border-greyBorder hover:border-logoRed transition-all duration-300 ease-in-out"
                                                                onClick={() => setDocuments()}
                                                            >
                                                                <FaTrash className="w-3 h-3 text-greyText" />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Link>}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <div className="form-group">
                                        <label
                                            className="block text-black text-sm font-proxima-semibold"
                                            htmlFor="treatmentCost"
                                        >
                                            Upload reports
                                        </label>
                                        <div className="mt-2">
                                            <div className="flex items-center gap-5">
                                                <input
                                                    id="reports"
                                                    name="reports"
                                                    className="hidden"
                                                    type="file"
                                                    accept=".doc,.docx,.pdf"
                                                    disabled={
                                                        formikForm?.values?.reports?.length !== 0
                                                    }
                                                    onChange={(e) => {
                                                        var temp = [];
                                                        setReportsDocument(e.target.files);
                                                        temp.push(e.target.files[0]);
                                                        formikForm.setFieldValue(
                                                            "reports",
                                                            e.target.files[0]
                                                        );
                                                    }}
                                                />
                                                <label htmlFor="reports">
                                                    <div
                                                        className={`w-[15vh] h-[15vh] cursor-pointer bg-greyBg bg-opacity-50 border ${formikForm.errors.reports &&
                                                            formikForm.touched.reports
                                                            ? "border-red-300 hover:bg-red-300 hover:bg-opacity-10 hover:border-red-300"
                                                            : "border-greyBorder hover:bg-logoYellow hover:bg-opacity-10 hover:border-logoYellow"
                                                            } rounded-lg p-5 flex-shrink-0 flex items-center justify-center transition-all duration-300 ease-in-out`}
                                                    >
                                                        <div className="text-center">
                                                            <IoCloudUpload className="w-8 h-8 text-greyText mx-auto" />
                                                            <p className="mt-2 text-xs font-proxima-semibold text-greyText leading-tight text-center">
                                                                Upload file from computer
                                                            </p>
                                                        </div>
                                                    </div>
                                                </label>

                                                {/* UPLOADED FILE */}
                                                {reportsDocument !== undefined && (
                                                    <div className="w-[15vh] h-[15vh] border border-greyBorder rounded-lg p-5 flex-shrink-0 flex items-center justify-center relative">
                                                        <div className="text-center">
                                                            {reportsDocument[0]?.type ===
                                                                "application/pdf" ? (
                                                                <FaFilePdf className="w-6 h-6 text-[#f15642] mx-auto" />
                                                            ) : (
                                                                <IoDocumentText className="w-6 h-6 text-[#508EF5] mx-auto" />
                                                            )}

                                                            <p className="mt-3 text-xs font-proxima-semibold text-greyText leading-tight text-center">
                                                                {reportsDocument[0]?.name}
                                                            </p>
                                                            <p className="mt-1 text-xs font-proxima-regular text-greyText">
                                                                {bytesToSize(reportsDocument[0]?.size)}
                                                            </p>
                                                        </div>

                                                        <div className="absolute -top-2 -right-2">
                                                            <Button
                                                                className="shadow-none hover:shadow-none p-2 rounded-full bg-white border border-greyBorder hover:border-logoRed transition-all duration-300 ease-in-out"
                                                                onClick={() => setReportsDocument()}
                                                            >
                                                                <FaTrash className="w-3 h-3 text-greyText" />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                )}

                                                {medicalAidFormDetails?.medicalDetails?.reports?.url && <Link target="_blank" to={medicalAidFormDetails?.medicalDetails?.reports?.url}>
                                                    <div className="w-[15vh] h-[15vh] border border-greyBorder rounded-lg p-5 flex-shrink-0 flex items-center justify-center relative">
                                                        <div className="text-center">

                                                            {medicalAidFormDetails?.medicalDetails?.reports?.url?.includes(".pdf") ? (
                                                                <FaFilePdf className="w-6 h-6 text-[#f15642] mx-auto" />
                                                            ) : (
                                                                <IoDocumentText className="w-6 h-6 text-[#508EF5] mx-auto" />
                                                            )}

                                                            <p className="mt-3 text-xs font-proxima-semibold text-greyText leading-tight text-center">
                                                                Reports Document
                                                            </p>
                                                        </div>

                                                        <div className="absolute -top-2 -right-2">
                                                            <Button
                                                                className="shadow-none hover:shadow-none p-2 rounded-full bg-white border border-greyBorder hover:border-logoRed transition-all duration-300 ease-in-out"
                                                                onClick={() => setDocuments()}
                                                            >
                                                                <FaTrash className="w-3 h-3 text-greyText" />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Link>}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* FAMILY DETAILS */}
                    <div className="w-full bg-white border border-greyBorder rounded-xl  p-5 xl:p-8 lg:p-8 mt-5">
                        <h5 className="text-xl xl:text-2xl lg:text-2xl font-sunflower-bold text-black tracking-tight">
                            Family Details
                        </h5>
                        <div className="mt-3">
                            <DataTable
                                columns={columns}
                                data={familyDetails}
                                responsive
                                className="family"
                            />
                        </div>
                    </div>

                    {/* BANK DETAILS */}
                    <div className="mt-5">
                        <div className="w-full bg-white border border-greyBorder rounded-xl p-5 xl:p-8 lg:p-8">
                            <h5 className="text-xl xl:text-2xl lg:text-2xl font-sunflower-bold text-black tracking-tight">
                                Aid Disbursement Details
                            </h5>
                            <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5 mt-3">
                                <div className="col-span-1 xl:col-span-2 lg:col-span-2">
                                    <div className="flex items-center gap-1">
                                        <label
                                            className="block text-black text-sm font-proxima-semibold"
                                            htmlFor="bankDetails.accountHolder"
                                        >
                                            Name of Beneficiary Account Holder (to be written
                                            on cheque)
                                        </label>
                                        <Tooltip
                                            content="Name must be of the Hospital or Clinic only. Cheque will not be issued in the name of patient or relative."
                                            className="w-1/4 text-sm font-proxima-semibold text-white text-center"
                                        >
                                            <Button className="p-0 bg-transparent shadow-none hover:shadow-none">
                                                <FaInfoCircle className="w-4 h-4 text-greyText text-opacity-50" />
                                            </Button>
                                        </Tooltip>
                                    </div>
                                    <div className="mt-1">
                                        <InputField
                                            type="text"
                                            name="bankDetails.accountHolder"
                                            id="bankDetails.accountHolder"
                                            placeholder="Name of Beneficiary Account Holder"
                                            error={
                                                formikForm?.errors?.bankDetails
                                                    ?.accountHolder &&
                                                formikForm?.touched?.bankDetails?.accountHolder
                                            }
                                            value={
                                                formikForm?.values?.bankDetails?.accountHolder
                                            }
                                            onChange={formikForm.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <label
                                        className="block text-black text-sm font-proxima-semibold"
                                        htmlFor="bankDetails.bankName"
                                    >
                                        Bank name
                                    </label>
                                    <div className="mt-1">
                                        <InputField
                                            type="text"
                                            name="bankDetails.bankName"
                                            id="bankDetails.bankName"
                                            placeholder="Bank name"
                                            error={
                                                formikForm?.errors?.bankDetails?.bankName &&
                                                formikForm?.touched?.bankDetails?.bankName
                                            }
                                            value={formikForm?.values?.bankDetails?.bankName}
                                            onChange={formikForm.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <label
                                        className="block text-black text-sm font-proxima-semibold"
                                        htmlFor="bankDetails.branch"
                                    >
                                        Branch
                                    </label>
                                    <div className="mt-1">
                                        <InputField
                                            type="text"
                                            name="bankDetails.branch"
                                            id="bankDetails.branch"
                                            placeholder="Branch"
                                            error={
                                                formikForm?.errors?.bankDetails?.branch &&
                                                formikForm?.touched?.bankDetails?.branch
                                            }
                                            value={formikForm?.values?.bankDetails?.branch}
                                            onChange={formikForm.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <label
                                        className="block text-black text-sm font-proxima-semibold"
                                        htmlFor="bankDetails.accountNumber"
                                    >
                                        Bank A/c. No
                                    </label>
                                    <div className="mt-1">
                                        <InputField
                                            type="number"
                                            name="bankDetails.accountNumber"
                                            id="bankDetails.accountNumber"
                                            placeholder="Bank account no."
                                            error={
                                                formikForm?.errors?.bankDetails
                                                    ?.accountNumber &&
                                                formikForm?.touched?.bankDetails?.accountNumber
                                            }
                                            value={
                                                formikForm?.values?.bankDetails?.accountNumber
                                            }
                                            onChange={formikForm.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <label
                                        className="block text-black text-sm font-proxima-semibold"
                                        htmlFor="bankDetails.ifscCode"
                                    >
                                        IFSC code
                                    </label>
                                    <div className="mt-1">
                                        <InputField
                                            type="text"
                                            name="bankDetails.ifscCode"
                                            id="bankDetails.ifscCode"
                                            placeholder="IFSC code"
                                            error={
                                                formikForm?.errors?.bankDetails?.ifscCode &&
                                                formikForm?.touched?.bankDetails?.ifscCode
                                            }
                                            value={formikForm?.values?.bankDetails?.ifscCode}
                                            onChange={formikForm.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="block xl:flex lg:flex items-end justify-between mt-5">
                                <div className="form-group">
                                    <label
                                        className="block text-black text-sm font-proxima-semibold"
                                        htmlFor="bankDetails.date"
                                    >
                                        Date
                                    </label>
                                    <div className="mt-1">
                                        <ReactDatePicker
                                            selected={formikForm?.values?.bankDetails?.date}
                                            onChange={(date) =>
                                                formikForm.setFieldValue(
                                                    "bankDetails.date",
                                                    date
                                                )
                                            }
                                            name="bankDetails.date"
                                            dateFormat="dd/MM/yyyy"
                                            className={`block w-full bg-white border ${formikForm?.errors?.bankDetails?.date &&
                                                formikForm?.touched?.bankDetails?.date
                                                ? "border-red-300"
                                                : "border-greyBorder"
                                                } rounded-lg px-4 py-3 font-proxima-semibold placeholder:font-proxima-regular text-sm placeholder:text-sm`}
                                        />
                                    </div>
                                </div>
                                <div className="form-group mt-5 xl:mt-0 lg:mt-0">
                                    <label
                                        className="block text-black text-sm font-proxima-semibold"
                                        htmlFor="branch"
                                    >
                                        Signature
                                        <span className="text-red-500">*</span>
                                    </label>
                                    <div
                                        className={
                                            formikForm?.errors?.bankDetails?.signature &&
                                                formikForm?.touched?.bankDetails?.signature
                                                ? "border bg-white border-red-500 rounded-lg mt-1"
                                                : "border bg-white  rounded-lg mt-1"
                                        }
                                    >
                                        <img src={medicalAidFormDetails?.bankDetails?.signature?.url} alt="Signature" />
                                        {/* <ReactSignatureCanvas
                              ref={signatureCanvasRef}
                              penColor="red"
                              canvasProps={{
                                width: "300",
                                height: 100,
                                className: "sigCanvas rounded-lg",
                              }}
                              onEnd={(e) => {
                                console.log("Signed: ", e);
                              }}
                            /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>





                    {/* MAHAJAN DETAILS */}
                    <div className="mt-5 w-full bg-white border border-greyBorder rounded-xl shadow p-5 xl:p-8 lg:p-8">
                        <h5 className="text-xl xl:text-2xl lg:text-2xl font-sunflower-bold text-black tracking-tight">
                            Mahajan Details
                        </h5>
                        <div className="mt-3">
                            <div className="col-span-1">
                                <label
                                    className="block text-black text-sm font-proxima-semibold"
                                    htmlFor="mahajanDetails.nearestMahajan"
                                >
                                    Name of Nearest Mahajan
                                </label>
                                <div className="mt-1">
                                    <InputField
                                        type="text"
                                        name="mahajanDetails.nearestMahajan"
                                        id="mahajanDetails.nearestMahajan"
                                        placeholder="Name of Nearest Mahajan"
                                        error={
                                            formikForm?.errors?.mahajanDetails?.nearestMahajan &&
                                            formikForm?.touched?.mahajanDetails?.nearestMahajan
                                        }
                                        value={
                                            formikForm?.values?.mahajanDetails?.nearestMahajan
                                        }
                                        onChange={formikForm.handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-5">
                            <div className="p-0">
                                <p className="text-sm font-proxima-regular text-greyText">
                                    To,
                                </p>
                                <p className="text-sm font-proxima-regular text-greyText">
                                    The Chairman,
                                </p>
                                <p className="text-sm font-proxima-regular text-greyText">
                                    Healthcare Committee,
                                </p>
                                <p className="text-sm font-proxima-regular text-greyText">
                                    Shree Lohana Mahaparishad
                                </p>
                            </div>
                            <div className="mt-5">
                                <div className="flex items-center gap-3 flex-wrap xl:flex-nowrap lg:flex-wrap">
                                    <p className="text-sm font-proxima-semibold text-black flex-shrink-0">
                                        This is to certify that Mr/Mrs
                                    </p>
                                    <input
                                        type="text"
                                        name="mahajanDetails.name"
                                        id="mahajanDetails.name"
                                        placeholder="Name"
                                        error={
                                            formikForm?.errors?.mahajanDetails?.name &&
                                            formikForm?.touched?.mahajanDetails?.name
                                        }
                                        value={
                                            formikForm?.values?.mahajanDetails?.name
                                        }
                                        onChange={formikForm.handleChange}

                                        className="w-full block outline-none focus:outline-none border-b border-greyBorder rounded-none px-4 py-1 font-proxima-semibold text-black placeholder:font-proxima-regular text-sm"
                                    />
                                    <p className="text-sm font-proxima-semibold text-black flex-shrink-0">
                                        aged
                                    </p>
                                    <input
                                        type="number"
                                        name="mahajanDetails.age"
                                        id="mahajanDetails.age"
                                        error={
                                            formikForm?.errors?.mahajanDetails?.age &&
                                            formikForm?.touched?.mahajanDetails?.age
                                        }
                                        value={
                                            formikForm?.values?.mahajanDetails?.age
                                        }
                                        onChange={formikForm.handleChange}
                                        placeholder="Age"
                                        className="w-full block outline-none focus:outline-none border-b border-greyBorder rounded-none px-4 py-1 font-proxima-semibold text-black placeholder:font-proxima-regular text-sm"
                                    />
                                    <p className="text-sm font-proxima-semibold text-black flex-shrink-0">
                                        living in
                                    </p>
                                    <input
                                        type="text"
                                        name="mahajanDetails.livingIn"
                                        id="mahajanDetails.livingIn"
                                        error={
                                            formikForm?.errors?.mahajanDetails?.livingIn &&
                                            formikForm?.touched?.mahajanDetails?.livingIn
                                        }
                                        value={
                                            formikForm?.values?.mahajanDetails?.livingIn
                                        }
                                        onChange={formikForm.handleChange}
                                        placeholder="Living in"
                                        className="w-full block outline-none focus:outline-none border-b border-greyBorder rounded-none px-4 py-1 font-proxima-semibold text-black placeholder:font-proxima-regular text-sm"
                                    />
                                    <p className="text-sm font-proxima-semibold text-black flex-shrink-0">
                                        (city/town/village) undergoing treatment for
                                    </p>
                                </div>
                                <div className="flex items-center gap-3 flex-wrap xl:flex-nowrap lg:flex-wrap mt-3">
                                    <input
                                        type="text"
                                        name="mahajanDetails.illness"
                                        id="mahajanDetails.illness"
                                        error={
                                            formikForm?.errors?.mahajanDetails?.illness &&
                                            formikForm?.touched?.mahajanDetails?.illness
                                        }
                                        value={
                                            formikForm?.values?.mahajanDetails?.illness
                                        }
                                        onChange={formikForm.handleChange}
                                        placeholder="Illness name"
                                        className="w-full block outline-none focus:outline-none border-b border-greyBorder rounded-none px-4 py-1 font-proxima-semibold text-black placeholder:font-proxima-regular text-sm"
                                    />
                                    <p className="text-sm font-proxima-semibold text-black flex-shrink xl:flex-shrink-0 lg:flex-shrink">
                                        is known to us. Since their financial condition is
                                        weak, we request you to kindly provide monetary
                                        assistance to them.
                                    </p>
                                </div>
                            </div>
                            <div className="block xl:flex lg:flex items-end justify-between mt-5 xl:mt-8 lg:mt-5">
                                <div className="form-group">
                                    <label className="block text-black text-sm font-proxima-semibold">
                                        Stamp/Seal of Mahajan
                                        <span className="text-red-500">*</span>
                                    </label>
                                    <div className="border bg-white mt-1">
                                        <div className="col-span-1">
                                            <div className="form-group">
                                                <label
                                                    className="block text-black text-sm font-proxima-semibold"
                                                    htmlFor="treatmentCost"
                                                >
                                                    Upload Stamp
                                                </label>
                                                <div className="mt-2">
                                                    <div className="flex items-center gap-5">
                                                        <input
                                                            id="stamp"
                                                            name="stamp"
                                                            className="hidden"
                                                            type="file"
                                                            accept=".doc,.docx,.pdf"
                                                            disabled={
                                                                formikForm?.values?.stamp?.length !== 0
                                                            }
                                                            onChange={(e) => {
                                                                var temp = [];
                                                                setStampDocument(e.target.files);
                                                                temp.push(e.target.files[0]);
                                                                formikForm.setFieldValue(
                                                                    "stamp",
                                                                    e.target.files[0]
                                                                );
                                                            }}
                                                        />
                                                        <label htmlFor="stamp">
                                                            <div
                                                                className={`w-[15vh] h-[15vh] cursor-pointer bg-greyBg bg-opacity-50 border ${formikForm.errors.reports &&
                                                                    formikForm.touched.stamp
                                                                    ? "border-red-300 hover:bg-red-300 hover:bg-opacity-10 hover:border-red-300"
                                                                    : "border-greyBorder hover:bg-logoYellow hover:bg-opacity-10 hover:border-logoYellow"
                                                                    } rounded-lg p-5 flex-shrink-0 flex items-center justify-center transition-all duration-300 ease-in-out`}
                                                            >
                                                                <div className="text-center">
                                                                    <IoCloudUpload className="w-8 h-8 text-greyText mx-auto" />
                                                                    <p className="mt-2 text-xs font-proxima-semibold text-greyText leading-tight text-center">
                                                                        Upload file from computer
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </label>

                                                        {/* UPLOADED FILE */}
                                                        {stampDocument !== undefined && (
                                                            <div className="w-[15vh] h-[15vh] border border-greyBorder rounded-lg p-5 flex-shrink-0 flex items-center justify-center relative">
                                                                <div className="text-center">
                                                                    {stampDocument[0]?.type ===
                                                                        "application/pdf" ? (
                                                                        <FaFilePdf className="w-6 h-6 text-[#f15642] mx-auto" />
                                                                    ) : (
                                                                        <IoDocumentText className="w-6 h-6 text-[#508EF5] mx-auto" />
                                                                    )}

                                                                    <p className="mt-3 text-xs font-proxima-semibold text-greyText leading-tight text-center">
                                                                        {stampDocument[0]?.name}
                                                                    </p>
                                                                    <p className="mt-1 text-xs font-proxima-regular text-greyText">
                                                                        {bytesToSize(stampDocument[0]?.size)}
                                                                    </p>
                                                                </div>

                                                                <div className="absolute -top-2 -right-2">
                                                                    <Button
                                                                        className="shadow-none hover:shadow-none p-2 rounded-full bg-white border border-greyBorder hover:border-logoRed transition-all duration-300 ease-in-out"
                                                                        onClick={() => setStampDocument()}
                                                                    >
                                                                        <FaTrash className="w-3 h-3 text-greyText" />
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        )}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group mt-5 xl:mt-0 lg:mt-0">
                                    <label className="block text-black text-sm font-proxima-semibold">
                                        Signature of President/Secretary
                                        <span className="text-red-500">*</span>
                                    </label>
                                    <div className="border bg-white mt-1">
                                        <div className="col-span-1">
                                            <div className="form-group">
                                                <label
                                                    className="block text-black text-sm font-proxima-semibold"
                                                    htmlFor="treatmentCost"
                                                >
                                                    Upload President Signature
                                                </label>
                                                <div className="mt-2">
                                                    <div className="flex items-center gap-5">
                                                        <input
                                                            id="mahajanPresident"
                                                            name="mahajanPresident"
                                                            className="hidden"
                                                            type="file"
                                                            accept=".doc,.docx,.pdf"
                                                            disabled={
                                                                formikForm?.values?.mahajanPresident?.length !== 0
                                                            }
                                                            onChange={(e) => {
                                                                var temp = [];
                                                                setMahajanPresidentDocument(e.target.files);
                                                                temp.push(e.target.files[0]);
                                                                formikForm.setFieldValue(
                                                                    "mahajanPresident",
                                                                    e.target.files[0]
                                                                );
                                                            }}
                                                        />
                                                        <label htmlFor="mahajanPresident">
                                                            <div
                                                                className={`w-[15vh] h-[15vh] cursor-pointer bg-greyBg bg-opacity-50 border ${formikForm.errors.reports &&
                                                                    formikForm.touched.mahajanPresident
                                                                    ? "border-red-300 hover:bg-red-300 hover:bg-opacity-10 hover:border-red-300"
                                                                    : "border-greyBorder hover:bg-logoYellow hover:bg-opacity-10 hover:border-logoYellow"
                                                                    } rounded-lg p-5 flex-shrink-0 flex items-center justify-center transition-all duration-300 ease-in-out`}
                                                            >
                                                                <div className="text-center">
                                                                    <IoCloudUpload className="w-8 h-8 text-greyText mx-auto" />
                                                                    <p className="mt-2 text-xs font-proxima-semibold text-greyText leading-tight text-center">
                                                                        Upload file from computer
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </label>

                                                        {/* UPLOADED FILE */}
                                                        {mahajanPresidentDocument !== undefined && (
                                                            <div className="w-[15vh] h-[15vh] border border-greyBorder rounded-lg p-5 flex-shrink-0 flex items-center justify-center relative">
                                                                <div className="text-center">
                                                                    {mahajanPresidentDocument[0]?.type ===
                                                                        "application/pdf" ? (
                                                                        <FaFilePdf className="w-6 h-6 text-[#f15642] mx-auto" />
                                                                    ) : (
                                                                        <IoDocumentText className="w-6 h-6 text-[#508EF5] mx-auto" />
                                                                    )}

                                                                    <p className="mt-3 text-xs font-proxima-semibold text-greyText leading-tight text-center">
                                                                        {mahajanPresidentDocument[0]?.name}
                                                                    </p>
                                                                    <p className="mt-1 text-xs font-proxima-regular text-greyText">
                                                                        {bytesToSize(mahajanPresidentDocument[0]?.size)}
                                                                    </p>
                                                                </div>

                                                                <div className="absolute -top-2 -right-2">
                                                                    <Button
                                                                        className="shadow-none hover:shadow-none p-2 rounded-full bg-white border border-greyBorder hover:border-logoRed transition-all duration-300 ease-in-out"
                                                                        onClick={() => setMahajanPresidentDocument()}
                                                                    >
                                                                        <FaTrash className="w-3 h-3 text-greyText" />
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        )}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* ZONAL */}
                    <div className="mt-5 w-full bg-white border border-greyBorder rounded-xl shadow p-5 xl:p-8 lg:p-8">
                        <h5 className="text-xl xl:text-2xl lg:text-2xl font-sunflower-bold text-black tracking-tight">
                            Recommendation of Zonal/Regional Office Bearer of Shree
                            Lohana Mahaparishad
                        </h5>
                        <div className="mt-3 grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
                            <div className="col-span-1">
                                <label
                                    className="block text-black text-sm font-proxima-semibold"
                                    htmlFor="zonalPresident"
                                >
                                    Name of Zonal/Regional President or Secretary
                                </label>
                                <div className="mt-1">
                                    <InputField
                                        type="text"
                                        name="zonalRegionOfficeDetails.name"
                                        id="zonalRegionOfficeDetails.name"
                                        error={
                                            formikForm?.errors?.zonalRegionOfficeDetails?.name &&
                                            formikForm?.touched?.zonalRegionOfficeDetails?.name
                                        }
                                        value={
                                            formikForm?.values?.zonalRegionOfficeDetails?.name
                                        }
                                        onChange={formikForm.handleChange}
                                        placeholder="Name of Zonal/Regional President or Secretary"
                                    />
                                </div>
                            </div>
                            <div className="col-span-1">
                                <label
                                    className="block text-black text-sm font-proxima-semibold"
                                    htmlFor="designationMahajan"
                                >
                                    Details of designation held in local Mahajan (if any)
                                </label>
                                <div className="mt-1">
                                    <InputField
                                        type="text"
                                        name="zonalRegionOfficeDetails.designation"
                                        id="zonalRegionOfficeDetails.designation"
                                        error={
                                            formikForm?.errors?.zonalRegionOfficeDetails?.designation &&
                                            formikForm?.touched?.zonalRegionOfficeDetails?.designation
                                        }
                                        value={
                                            formikForm?.values?.zonalRegionOfficeDetails?.designation
                                        }
                                        onChange={formikForm.handleChange}
                                        placeholder="Details of designation held in local Mahajan (if any)"
                                    />
                                </div>
                            </div>
                            <div className="col-span-1 xl:col-span-2 lg:col-span-2">
                                <label
                                    className="block text-black text-sm font-proxima-semibold"
                                    htmlFor="mahajanAddress"
                                >
                                    Address
                                </label>
                                <div className="mt-1">
                                    <TextareaField
                                        rows={2}
                                        name="zonalRegionOfficeDetails.address"
                                        id="zonalRegionOfficeDetails.address"
                                        error={
                                            formikForm?.errors?.zonalRegionOfficeDetails?.address &&
                                            formikForm?.touched?.zonalRegionOfficeDetails?.address
                                        }
                                        value={
                                            formikForm?.values?.zonalRegionOfficeDetails?.address
                                        }
                                        onChange={formikForm.handleChange}
                                        placeholder="Address"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="block xl:flex lg:flex items-end justify-between mt-5">
                            <div className="form-group">
                                <label
                                    className="block text-black text-sm font-proxima-semibold"
                                    htmlFor="mahaparishadDesignation"       >
                                    Designation in Mahaparishad
                                </label>
                                <div className="mt-1 w-full xl:w-[30vh] lg:w-[30vh]">
                                    <InputField
                                        type="text"
                                        name="zonalRegionOfficeDetails.designationInMahaparishad"
                                        id="zonalRegionOfficeDetails.designationInMahaparishad"
                                        error={
                                            formikForm?.errors?.zonalRegionOfficeDetails?.designationInMahaparishad &&
                                            formikForm?.touched?.zonalRegionOfficeDetails?.designationInMahaparishad
                                        }
                                        value={
                                            formikForm?.values?.zonalRegionOfficeDetails?.designationInMahaparishad
                                        }
                                        onChange={formikForm.handleChange}
                                        placeholder="Designation in Mahaparishad"
                                    />
                                </div>
                            </div>
                            <div className="form-group mt-5 xl:mt-0 lg:mt-0">
                                <label
                                    className="block text-black text-sm font-proxima-semibold"
                                    htmlFor="signnature"
                                >
                                    Signature
                                    <span className="text-red-500">*</span>
                                </label>
                                <div className="border bg-white mt-1">
                                    <div className="col-span-1">
                                        <div className="form-group">
                                            <label
                                                className="block text-black text-sm font-proxima-semibold"
                                                htmlFor="treatmentCost"
                                            >
                                                Upload Signature
                                            </label>
                                            <div className="mt-2">
                                                <div className="flex items-center gap-5">
                                                    <input
                                                        id="zonalPresident"
                                                        name="zonalPresident"
                                                        className="hidden"
                                                        type="file"
                                                        accept=".doc,.docx,.pdf"
                                                        disabled={
                                                            formikForm?.values?.zonalPresident?.length !== 0
                                                        }
                                                        onChange={(e) => {
                                                            var temp = [];
                                                            setZonalPresidentDocument(e.target.files);
                                                            temp.push(e.target.files[0]);
                                                            formikForm.setFieldValue(
                                                                "zonalPresident",
                                                                e.target.files[0]
                                                            );
                                                        }}
                                                    />
                                                    <label htmlFor="zonalPresident">
                                                        <div
                                                            className={`w-[15vh] h-[15vh] cursor-pointer bg-greyBg bg-opacity-50 border ${formikForm.errors.reports &&
                                                                formikForm.touched.zonalPresident
                                                                ? "border-red-300 hover:bg-red-300 hover:bg-opacity-10 hover:border-red-300"
                                                                : "border-greyBorder hover:bg-logoYellow hover:bg-opacity-10 hover:border-logoYellow"
                                                                } rounded-lg p-5 flex-shrink-0 flex items-center justify-center transition-all duration-300 ease-in-out`}
                                                        >
                                                            <div className="text-center">
                                                                <IoCloudUpload className="w-8 h-8 text-greyText mx-auto" />
                                                                <p className="mt-2 text-xs font-proxima-semibold text-greyText leading-tight text-center">
                                                                    Upload file from computer
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </label>

                                                    {/* UPLOADED FILE */}
                                                    {zonalPresidentDocument !== undefined && (
                                                        <div className="w-[15vh] h-[15vh] border border-greyBorder rounded-lg p-5 flex-shrink-0 flex items-center justify-center relative">
                                                            <div className="text-center">
                                                                {zonalPresidentDocument[0]?.type ===
                                                                    "application/pdf" ? (
                                                                    <FaFilePdf className="w-6 h-6 text-[#f15642] mx-auto" />
                                                                ) : (
                                                                    <IoDocumentText className="w-6 h-6 text-[#508EF5] mx-auto" />
                                                                )}

                                                                <p className="mt-3 text-xs font-proxima-semibold text-greyText leading-tight text-center">
                                                                    {zonalPresidentDocument[0]?.name}
                                                                </p>
                                                                <p className="mt-1 text-xs font-proxima-regular text-greyText">
                                                                    {bytesToSize(zonalPresidentDocument[0]?.size)}
                                                                </p>
                                                            </div>

                                                            <div className="absolute -top-2 -right-2">
                                                                <Button
                                                                    className="shadow-none hover:shadow-none p-2 rounded-full bg-white border border-greyBorder hover:border-logoRed transition-all duration-300 ease-in-out"
                                                                    onClick={() => setZonalPresidentDocument()}
                                                                >
                                                                    <FaTrash className="w-3 h-3 text-greyText" />
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    )}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* ONLY FOR OFFICE USE */}
                    <div className="mt-5 w-full bg-white border border-greyBorder rounded-xl shadow p-5 xl:p-8 lg:p-8">
                        <h5 className="text-xl xl:text-2xl lg:text-2xl font-sunflower-bold text-black tracking-tight">
                            Only For Office Use
                        </h5>
                        <div className="mt-3 grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 gap-5">
                            {/* INTERVIEW DATE */}
                            <div className="col-span-1">
                                <label
                                    className="block text-black text-sm font-proxima-semibold"
                                    htmlFor="zonalPresident"
                                >
                                    Interview Date
                                </label>
                                <div className="mt-1">

                                    <ReactDatePicker
                                        selected={
                                            formikForm?.values?.officeDetails
                                                ?.interviewDate
                                        }
                                        onChange={(date) =>
                                            formikForm.setFieldValue(
                                                "officeDetails.interviewDate",
                                                date
                                            )
                                        }
                                        placeholderText="Interview Date"
                                        name="officeDetails.interviewDate"
                                        dateFormat="dd/MM/yyyy"
                                        className={`block w-full bg-white ${formikForm?.errors?.officeDetails
                                            ?.interviewDate &&
                                            formikForm?.touched?.officeDetails
                                                ?.interviewDate
                                            ? "border-red-300"
                                            : "border-none"
                                            } border rounded-base  font-proxima-semibold placeholder:font-proxima-regular text-sm placeholder:text-sm`}
                                    />
                                </div>
                            </div>
                            {/* APPROVED AID AMOUNT */}
                            <div className="col-span-1">
                                <label
                                    className="block text-black text-sm font-proxima-semibold"
                                    htmlFor="designationMahajan"
                                >
                                    Granted Assistance
                                </label>
                                <div className="mt-1">
                                    <InputField
                                        type="text"
                                        name="officeDetails.grantedAssistance"
                                        id="officeDetails.grantedAssistance"
                                        error={
                                            formikForm?.errors?.officeDetails?.grantedAssistance &&
                                            formikForm?.touched?.officeDetails?.grantedAssistance
                                        }
                                        value={
                                            formikForm?.values?.officeDetails?.grantedAssistance
                                        }
                                        onChange={formikForm.handleChange}
                                        placeholder="Granted Assistance"
                                    />
                                </div>
                            </div>
                            {/* INTERVIEW NAME */}
                            <div className="col-span-1 xl:col-span-2 lg:col-span-2">
                                <label
                                    className="block text-black text-sm font-proxima-semibold"
                                    htmlFor="mahajanAddress"
                                >
                                    Interview Name
                                </label>
                                <div className="mt-1">
                                    <InputField
                                        type="text"
                                        name="officeDetails.interviewName"
                                        id="officeDetails.interviewName"
                                        error={
                                            formikForm?.errors?.officeDetails?.interviewName &&
                                            formikForm?.touched?.officeDetails?.interviewName
                                        }
                                        value={
                                            formikForm?.values?.officeDetails?.interviewName
                                        }
                                        onChange={formikForm.handleChange}
                                        placeholder="Name of Nearest Mahajan"
                                    />
                                </div>
                            </div>

                            {/* NAME ON CHECK */}
                            <div className="col-span-1 xl:col-span-2 lg:col-span-2">
                                <label
                                    className="block text-black text-sm font-proxima-semibold"
                                    htmlFor="mahajanAddress"
                                >
                                    Name on the Check
                                </label>
                                <div className="mt-1">
                                    <InputField
                                        type="text"
                                        name="officeDetails.chequeDetailsName"
                                        id="officeDetails.chequeDetailsName"
                                        error={
                                            formikForm?.errors?.officeDetails?.chequeDetailsName &&
                                            formikForm?.touched?.officeDetails?.chequeDetailsName
                                        }
                                        value={
                                            formikForm?.values?.officeDetails?.chequeDetailsName
                                        }
                                        onChange={formikForm.handleChange}
                                        placeholder="Name on Cheque"
                                    />
                                </div>
                            </div>

                            {/* AMOUNT */}
                            <div className="col-span-1">
                                <div className="form-group block">
                                    <label
                                        className="block text-black text-sm font-proxima-semibold"
                                        htmlFor="mahaparishadDesignation"
                                    >
                                        Amount
                                    </label>
                                    <div className="mt-1 w-full xl:w-[30vh] lg:w-[30vh]">
                                        <InputField
                                            type="number"
                                            name="officeDetails.chequeDetailsAmount"
                                            id="officeDetails.chequeDetailsAmount"
                                            error={
                                                formikForm?.errors?.officeDetails?.chequeDetailsAmount &&
                                                formikForm?.touched?.officeDetails?.chequeDetailsAmount
                                            }
                                            value={
                                                formikForm?.values?.officeDetails?.chequeDetailsAmount
                                            }
                                            onChange={formikForm.handleChange}
                                            placeholder="Amount"
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* DATE */}
                            <div className="col-span-1">
                                <label
                                    className="block text-black text-sm font-proxima-semibold"
                                    htmlFor="medicalDetails.admittedAt"
                                >
                                    Date
                                </label>
                                <div className="mt-1">
                                    <ReactDatePicker
                                        name="officeDetails.chequeDetailsDate"
                                        selected={
                                            formikForm?.values?.officeDetails?.chequeDetailsDate
                                        }
                                        onChange={(date) =>
                                            formikForm.setFieldValue(
                                                "officeDetails.chequeDetailsDate",
                                                date
                                            )
                                        }
                                        dateFormat="dd/MM/yyyy"
                                        className={`block w-full bg-white ${formikForm?.errors?.officeDetails
                                            ?.chequeDetailsDate &&
                                            formikForm?.touched?.officeDetails?.chequeDetailsDate
                                            ? "border-red-300"
                                            : "border-none"
                                            } border rounded-lg px-0 py-0 font-proxima-semibold placeholder:font-proxima-regular text-sm placeholder:text-sm`}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-3 grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 gap-5">



                            <div className="col-span-1">
                                <div className="col-span-1">
                                    <div className="form-group">
                                        <label
                                            className="block text-black text-sm font-proxima-semibold"
                                            htmlFor="treatmentCost"
                                        >
                                            Upload Signature
                                        </label>
                                        <div className="mt-2">
                                            <div className="flex items-center gap-5">
                                                <input
                                                    id="signatureOffice"
                                                    name="signatureOffice"
                                                    className="hidden"
                                                    type="file"
                                                    accept=".doc,.docx,.pdf"
                                                    disabled={
                                                        formikForm?.values?.signatureOffice?.length !== 0
                                                    }
                                                    onChange={(e) => {
                                                        var temp = [];
                                                        setSignatureOfficeDocument(e.target.files);
                                                        temp.push(e.target.files[0]);
                                                        formikForm.setFieldValue(
                                                            "signatureOffice",
                                                            e.target.files[0]
                                                        );
                                                    }}
                                                />
                                                <label htmlFor="signatureOffice">
                                                    <div
                                                        className={`w-[15vh] h-[15vh] cursor-pointer bg-greyBg bg-opacity-50 border ${formikForm.errors.reports &&
                                                            formikForm.touched.signatureOffice
                                                            ? "border-red-300 hover:bg-red-300 hover:bg-opacity-10 hover:border-red-300"
                                                            : "border-greyBorder hover:bg-logoYellow hover:bg-opacity-10 hover:border-logoYellow"
                                                            } rounded-lg p-5 flex-shrink-0 flex items-center justify-center transition-all duration-300 ease-in-out`}
                                                    >
                                                        <div className="text-center">
                                                            <IoCloudUpload className="w-8 h-8 text-greyText mx-auto" />
                                                            <p className="mt-2 text-xs font-proxima-semibold text-greyText leading-tight text-center">
                                                                Upload file from computer
                                                            </p>
                                                        </div>
                                                    </div>
                                                </label>

                                                {/* UPLOADED FILE */}
                                                {signatureOfficeDocument !== undefined && (
                                                    <div className="w-[15vh] h-[15vh] border border-greyBorder rounded-lg p-5 flex-shrink-0 flex items-center justify-center relative">
                                                        <div className="text-center">
                                                            {signatureOfficeDocument[0]?.type ===
                                                                "application/pdf" ? (
                                                                <FaFilePdf className="w-6 h-6 text-[#f15642] mx-auto" />
                                                            ) : (
                                                                <IoDocumentText className="w-6 h-6 text-[#508EF5] mx-auto" />
                                                            )}

                                                            <p className="mt-3 text-xs font-proxima-semibold text-greyText leading-tight text-center">
                                                                {signatureOfficeDocument[0]?.name}
                                                            </p>
                                                            <p className="mt-1 text-xs font-proxima-regular text-greyText">
                                                                {bytesToSize(signatureOfficeDocument[0]?.size)}
                                                            </p>
                                                        </div>

                                                        <div className="absolute -top-2 -right-2">
                                                            <Button
                                                                className="shadow-none hover:shadow-none p-2 rounded-full bg-white border border-greyBorder hover:border-logoRed transition-all duration-300 ease-in-out"
                                                                onClick={() => setSignatureOfficeDocument()}
                                                            >
                                                                <FaTrash className="w-3 h-3 text-greyText" />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                    {/* BUTTONS */}
                    <div className="mt-5 flex items-center justify-end">
                        <Button
                            disabled={loading}
                            type="submit"
                            className="shadow hover:shadow text-sm font-proxima-semibold text-black bg-logoYellow hover:bg-opacity-80 rounded-md normal-case transition-all duration-300 ease-in-out"
                        >
                            {loading ? "Please wait..." : "Submit details"}
                        </Button>
                    </div>


                </form>
            </div>

        </section>
    );
}