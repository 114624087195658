const DISTRICT_DATA = [
    {
      "Zone No.": "1",
      "Zone": "Saurashtra 1",
      "zoneId": "664c2e8c593679c57b040bd0",
      "State / Union Territory": "Gujarat",
      "District": "Morbi"
    },
    {
      "Zone No.": "1",
      "Zone": "Saurashtra 1",
      "zoneId": "664c2e8c593679c57b040bd0",
      "State / Union Territory": "Gujarat",
      "District": "Rajkot"
    },
    {
      "Zone No.": "1",
      "Zone": "Saurashtra 1",
      "zoneId": "664c2e8c593679c57b040bd0",
      "State / Union Territory": "Gujarat",
      "District": "Surendranagar"
    },
    {
      "Zone No.": "2",
      "Zone": "Saurashtra 2",
      "zoneId": "664c2e98593679c57b040bda",
      "State / Union Territory": "Gujarat",
      "District": "Devbhoomi Dwarka"
    },
    {
      "Zone No.": "2",
      "Zone": "Saurashtra 2",
      "zoneId": "664c2e98593679c57b040bda",
      "State / Union Territory": "Gujarat",
      "District": "Jamnagar"
    },
    {
      "Zone No.": "2",
      "Zone": "Saurashtra 2",
      "zoneId": "664c2e98593679c57b040bda",
      "State / Union Territory": "Gujarat",
      "District": "Porbandar"
    },
    {
      "Zone No.": "3",
      "Zone": "Saurashtra 3",
      "zoneId": "664c2e9b593679c57b040be4",
      "State / Union Territory": "Gujarat",
      "District": "Gir Somnath"
    },
    {
      "Zone No.": "3",
      "Zone": "Saurashtra 3",
      "zoneId": "664c2e9b593679c57b040be4",
      "State / Union Territory": "Gujarat",
      "District": "Junagadh"
    },
    {
      "Zone No.": "3",
      "Zone": "Saurashtra 3",
      "zoneId": "664c2e9b593679c57b040be4",
      "State / Union Territory": "Dadra And Nagar Haveli And Daman And Diu",
      "District": "Diu"
    },
    {
      "Zone No.": "4",
      "Zone": "Saurashtra 4",
      "zoneId": "664c2e9d593679c57b040bee",
      "State / Union Territory": "Gujarat",
      "District": "Amreli"
    },
    {
      "Zone No.": "4",
      "Zone": "Saurashtra 4",
      "zoneId": "664c2e9d593679c57b040bee",
      "State / Union Territory": "Gujarat",
      "District": "Bhavnagar"
    },
    {
      "Zone No.": "4",
      "Zone": "Saurashtra 4",
      "zoneId": "664c2e9d593679c57b040bee",
      "State / Union Territory": "Gujarat",
      "District": "Botad"
    },
    {
      "Zone No.": "5",
      "Zone": "Kutch",
      "zoneId": "664c2eb9593679c57b040c02",
      "State / Union Territory": "Gujarat",
      "District": "Kutch"
    },
    {
      "Zone No.": "6",
      "Zone": "North Gujarat",
      "zoneId": "664c2ee0593679c57b040c0c",
      "State / Union Territory": "Gujarat",
      "District": "Aravalli"
    },
    {
      "Zone No.": "6",
      "Zone": "North Gujarat",
      "zoneId": "664c2ee0593679c57b040c0c",
      "State / Union Territory": "Gujarat",
      "District": "Banaskantha"
    },
    {
      "Zone No.": "6",
      "Zone": "North Gujarat",
      "zoneId": "664c2ee0593679c57b040c0c",
      "State / Union Territory": "Gujarat",
      "District": "Gandhinagar"
    },
    {
      "Zone No.": "6",
      "Zone": "North Gujarat",
      "zoneId": "664c2ee0593679c57b040c0c",
      "State / Union Territory": "Gujarat",
      "District": "Mahesana"
    },
    {
      "Zone No.": "6",
      "Zone": "North Gujarat",
      "zoneId": "664c2ee0593679c57b040c0c",
      "State / Union Territory": "Gujarat",
      "District": "Patan"
    },
    {
      "Zone No.": "6",
      "Zone": "North Gujarat",
      "zoneId": "664c2ee0593679c57b040c0c",
      "State / Union Territory": "Gujarat",
      "District": "Sabarkantha"
    },
    {
      "Zone No.": "7",
      "Zone": "Central Gujarat",
      "zoneId": "664ce2ad19c98f831a73f6d9",
      "State / Union Territory": "Gujarat",
      "District": "Ahmedabad"
    },
    {
      "Zone No.": "7",
      "Zone": "Central Gujarat",
      "zoneId": "664ce2ad19c98f831a73f6d9",
      "State / Union Territory": "Gujarat",
      "District": "Anand"
    },
    {
      "Zone No.": "7",
      "Zone": "Central Gujarat",
      "zoneId": "664ce2ad19c98f831a73f6d9",
      "State / Union Territory": "Gujarat",
      "District": "Chhota Udaipur"
    },
    {
      "Zone No.": "7",
      "Zone": "Central Gujarat",
      "zoneId": "664ce2ad19c98f831a73f6d9",
      "State / Union Territory": "Gujarat",
      "District": "Dahod"
    },
    {
      "Zone No.": "7",
      "Zone": "Central Gujarat",
      "zoneId": "664ce2ad19c98f831a73f6d9",
      "State / Union Territory": "Gujarat",
      "District": "Kheda"
    },
    {
      "Zone No.": "7",
      "Zone": "Central Gujarat",
      "zoneId": "664ce2ad19c98f831a73f6d9",
      "State / Union Territory": "Gujarat",
      "District": "Mahisagar"
    },
    {
      "Zone No.": "7",
      "Zone": "Central Gujarat",
      "zoneId": "664ce2ad19c98f831a73f6d9",
      "State / Union Territory": "Gujarat",
      "District": "Panchmahal"
    },
    {
      "Zone No.": "7",
      "Zone": "Central Gujarat",
      "zoneId": "664ce2ad19c98f831a73f6d9",
      "State / Union Territory": "Gujarat",
      "District": "Vadodara"
    },
    {
      "Zone No.": "8",
      "Zone": "South Gujarat",
      "zoneId": "664ce30919c98f831a73f75a",
      "State / Union Territory": "Gujarat",
      "District": "Bharuch"
    },
    {
      "Zone No.": "8",
      "Zone": "South Gujarat",
      "zoneId": "664ce30919c98f831a73f75a",
      "State / Union Territory": "Gujarat",
      "District": "Dang"
    },
    {
      "Zone No.": "8",
      "Zone": "South Gujarat",
      "zoneId": "664ce30919c98f831a73f75a",
      "State / Union Territory": "Gujarat",
      "District": "Narmada"
    },
    {
      "Zone No.": "8",
      "Zone": "South Gujarat",
      "zoneId": "664ce30919c98f831a73f75a",
      "State / Union Territory": "Gujarat",
      "District": "Navsari"
    },
    {
      "Zone No.": "8",
      "Zone": "South Gujarat",
      "zoneId": "664ce30919c98f831a73f75a",
      "State / Union Territory": "Gujarat",
      "District": "Surat"
    },
    {
      "Zone No.": "8",
      "Zone": "South Gujarat",
      "zoneId": "664ce30919c98f831a73f75a",
      "State / Union Territory": "Gujarat",
      "District": "Tapi"
    },
    {
      "Zone No.": "8",
      "Zone": "South Gujarat",
      "zoneId": "664ce30919c98f831a73f75a",
      "State / Union Territory": "Gujarat",
      "District": "Valsad"
    },
    {
      "Zone No.": "8",
      "Zone": "South Gujarat",
      "zoneId": "664ce30919c98f831a73f75a",
      "State / Union Territory": "Dadra And Nagar Haveli And Daman And Diu",
      "District": "Dadra And Nagar Haveli"
    },
    {
      "Zone No.": "8",
      "Zone": "South Gujarat",
      "zoneId": "664ce30919c98f831a73f75a",
      "State / Union Territory": "Dadra And Nagar Haveli And Daman And Diu",
      "District": "Daman"
    },
    {
      "Zone No.": "9",
      "Zone": "Mumbai",
      "zoneId": "664ce31619c98f831a73f770",
      "State / Union Territory": "Maharashtra",
      "District": "Mumbai"
    },
    {
      "Zone No.": "9",
      "Zone": "Mumbai",
      "zoneId": "664ce31619c98f831a73f770",
      "State / Union Territory": "Maharashtra",
      "District": "Mumbai Suburban"
    },
    {
      "Zone No.": "10",
      "Zone": "Maharashtra",
      "zoneId": "664ce33019c98f831a73f780",
      "State / Union Territory": "Maharashtra",
      "District": "Ahmednagar"
    },
    {
      "Zone No.": "10",
      "Zone": "Maharashtra",
      "zoneId": "664ce33019c98f831a73f780",
      "State / Union Territory": "Maharashtra",
      "District": "Aurangabad"
    },
    {
      "Zone No.": "10",
      "Zone": "Maharashtra",
      "zoneId": "664ce33019c98f831a73f780",
      "State / Union Territory": "Maharashtra",
      "District": "Beed"
    },
    {
      "Zone No.": "10",
      "Zone": "Maharashtra",
      "zoneId": "664ce33019c98f831a73f780",
      "State / Union Territory": "Maharashtra",
      "District": "Dhule"
    },
    {
      "Zone No.": "10",
      "Zone": "Maharashtra",
      "zoneId": "664ce33019c98f831a73f780",
      "State / Union Territory": "Maharashtra",
      "District": "Hingoli"
    },
    {
      "Zone No.": "10",
      "Zone": "Maharashtra",
      "zoneId": "664ce33019c98f831a73f780",
      "State / Union Territory": "Maharashtra",
      "District": "Jalgaon"
    },
    {
      "Zone No.": "10",
      "Zone": "Maharashtra",
      "zoneId": "664ce33019c98f831a73f780",
      "State / Union Territory": "Maharashtra",
      "District": "Jalna"
    },
    {
      "Zone No.": "10",
      "Zone": "Maharashtra",
      "zoneId": "664ce33019c98f831a73f780",
      "State / Union Territory": "Maharashtra",
      "District": "Kolhapur"
    },
    {
      "Zone No.": "10",
      "Zone": "Maharashtra",
      "zoneId": "664ce33019c98f831a73f780",
      "State / Union Territory": "Maharashtra",
      "District": "Latur"
    },
    {
      "Zone No.": "10",
      "Zone": "Maharashtra",
      "zoneId": "664ce33019c98f831a73f780",
      "State / Union Territory": "Maharashtra",
      "District": "Nanded"
    },
    {
      "Zone No.": "10",
      "Zone": "Maharashtra",
      "zoneId": "664ce33019c98f831a73f780",
      "State / Union Territory": "Maharashtra",
      "District": "Nandurbar"
    },
    {
      "Zone No.": "10",
      "Zone": "Maharashtra",
      "zoneId": "664ce33019c98f831a73f780",
      "State / Union Territory": "Maharashtra",
      "District": "Nashik"
    },
    {
      "Zone No.": "10",
      "Zone": "Maharashtra",
      "zoneId": "664ce33019c98f831a73f780",
      "State / Union Territory": "Maharashtra",
      "District": "Osmanabad"
    },
    {
      "Zone No.": "10",
      "Zone": "Maharashtra",
      "zoneId": "664ce33019c98f831a73f780",
      "State / Union Territory": "Maharashtra",
      "District": "Palghar"
    },
    {
      "Zone No.": "10",
      "Zone": "Maharashtra",
      "zoneId": "664ce33019c98f831a73f780",
      "State / Union Territory": "Maharashtra",
      "District": "Parbhani"
    },
    {
      "Zone No.": "10",
      "Zone": "Maharashtra",
      "zoneId": "664ce33019c98f831a73f780",
      "State / Union Territory": "Maharashtra",
      "District": "Pune"
    },
    {
      "Zone No.": "10",
      "Zone": "Maharashtra",
      "zoneId": "664ce33019c98f831a73f780",
      "State / Union Territory": "Maharashtra",
      "District": "Raigad"
    },
    {
      "Zone No.": "10",
      "Zone": "Maharashtra",
      "zoneId": "664ce33019c98f831a73f780",
      "State / Union Territory": "Maharashtra",
      "District": "Ratnagiri"
    },
    {
      "Zone No.": "10",
      "Zone": "Maharashtra",
      "zoneId": "664ce33019c98f831a73f780",
      "State / Union Territory": "Maharashtra",
      "District": "Sangli"
    },
    {
      "Zone No.": "10",
      "Zone": "Maharashtra",
      "zoneId": "664ce33019c98f831a73f780",
      "State / Union Territory": "Maharashtra",
      "District": "Satara"
    },
    {
      "Zone No.": "10",
      "Zone": "Maharashtra",
      "zoneId": "664ce33019c98f831a73f780",
      "State / Union Territory": "Maharashtra",
      "District": "Sindhudurg"
    },
    {
      "Zone No.": "10",
      "Zone": "Maharashtra",
      "zoneId": "664ce33019c98f831a73f780",
      "State / Union Territory": "Maharashtra",
      "District": "Solapur"
    },
    {
      "Zone No.": "10",
      "Zone": "Maharashtra",
      "zoneId": "664ce33019c98f831a73f780",
      "State / Union Territory": "Maharashtra",
      "District": "Thane"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Chhattisgarh",
      "District": "Balod"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Chhattisgarh",
      "District": "Baloda Bazar"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Chhattisgarh",
      "District": "Balrampur"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Chhattisgarh",
      "District": "Bastar"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Chhattisgarh",
      "District": "Bemetara"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Chhattisgarh",
      "District": "Bijapur"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Chhattisgarh",
      "District": "Bilaspur"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Chhattisgarh",
      "District": "Dantewada"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Chhattisgarh",
      "District": "Dhamtari"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Chhattisgarh",
      "District": "Durg"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Chhattisgarh",
      "District": "Gariyaband"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Chhattisgarh",
      "District": "Gaurella Pendra Marwahi"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Chhattisgarh",
      "District": "Janjgir-Champa"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Chhattisgarh",
      "District": "Jashpur"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Chhattisgarh",
      "District": "Kabirdham"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Chhattisgarh",
      "District": "Kanker"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Chhattisgarh",
      "District": "Kondagaon"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Chhattisgarh",
      "District": "Korba"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Chhattisgarh",
      "District": "Korea"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Chhattisgarh",
      "District": "Mahasamund"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Chhattisgarh",
      "District": "Mungeli"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Chhattisgarh",
      "District": "Narayanpur"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Chhattisgarh",
      "District": "Raigarh"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Chhattisgarh",
      "District": "Raipur"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Chhattisgarh",
      "District": "Rajnandgaon"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Chhattisgarh",
      "District": "Sukma"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Chhattisgarh",
      "District": "Surajpur"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Chhattisgarh",
      "District": "Surguja"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Agar Malwa"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Alirajpur"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Anuppur"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Ashoknagar"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Balaghat"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Barwani"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Betul"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Bhind"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Bhopal"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Burhanpur"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Chhatarpur"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Chhindwara"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Damoh"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Datia"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Dewas"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Dhar"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Dindori"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "East Nimar"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Guna"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Gwalior"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Harda"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Hoshangabad"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Indore"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Jabalpur"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Jhabua"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Katni"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Khargone"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Mandla"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Mandsaur"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Morena"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Narsinghpur"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Neemuch"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Niwari"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Panna"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Raisen"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Rajgarh"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Ratlam"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Rewa"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Sagar"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Satna"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Sehore"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Seoni"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Shahdol"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Shajapur"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Sheopur"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Shivpuri"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Sidhi"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Singrauli"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Tikamgarh"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Ujjain"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Umaria"
    },
    {
      "Zone No.": "11",
      "Zone": "Central India",
      "zoneId": "664ce34119c98f831a73f790",
      "State / Union Territory": "Madhya Pradesh",
      "District": "Vidisha"
    },
    {
      "Zone No.": "12",
      "Zone": "Vidarbha",
      "zoneId": "664ce34b19c98f831a73f7a0",
      "State / Union Territory": "Maharashtra",
      "District": "Akola"
    },
    {
      "Zone No.": "12",
      "Zone": "Vidarbha",
      "zoneId": "664ce34b19c98f831a73f7a0",
      "State / Union Territory": "Maharashtra",
      "District": "Amravati"
    },
    {
      "Zone No.": "12",
      "Zone": "Vidarbha",
      "zoneId": "664ce34b19c98f831a73f7a0",
      "State / Union Territory": "Maharashtra",
      "District": "Bhandara"
    },
    {
      "Zone No.": "12",
      "Zone": "Vidarbha",
      "zoneId": "664ce34b19c98f831a73f7a0",
      "State / Union Territory": "Maharashtra",
      "District": "Buldhana"
    },
    {
      "Zone No.": "12",
      "Zone": "Vidarbha",
      "zoneId": "664ce34b19c98f831a73f7a0",
      "State / Union Territory": "Maharashtra",
      "District": "Chandrapur"
    },
    {
      "Zone No.": "12",
      "Zone": "Vidarbha",
      "zoneId": "664ce34b19c98f831a73f7a0",
      "State / Union Territory": "Maharashtra",
      "District": "Gadchiroli"
    },
    {
      "Zone No.": "12",
      "Zone": "Vidarbha",
      "zoneId": "664ce34b19c98f831a73f7a0",
      "State / Union Territory": "Maharashtra",
      "District": "Gondia"
    },
    {
      "Zone No.": "12",
      "Zone": "Vidarbha",
      "zoneId": "664ce34b19c98f831a73f7a0",
      "State / Union Territory": "Maharashtra",
      "District": "Nagpur"
    },
    {
      "Zone No.": "12",
      "Zone": "Vidarbha",
      "zoneId": "664ce34b19c98f831a73f7a0",
      "State / Union Territory": "Maharashtra",
      "District": "Wardha"
    },
    {
      "Zone No.": "12",
      "Zone": "Vidarbha",
      "zoneId": "664ce34b19c98f831a73f7a0",
      "State / Union Territory": "Maharashtra",
      "District": "Washim"
    },
    {
      "Zone No.": "12",
      "Zone": "Vidarbha",
      "zoneId": "664ce34b19c98f831a73f7a0",
      "State / Union Territory": "Maharashtra",
      "District": "Yavatmal"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Andaman And Nicobar Islands",
      "District": "Nicobar"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Andaman And Nicobar Islands",
      "District": "North And Middle Andaman"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Andaman And Nicobar Islands",
      "District": "South Andaman"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Andhra Pradesh",
      "District": "Alluri Sitharama Raju"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Andhra Pradesh",
      "District": "Anakapalli"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Andhra Pradesh",
      "District": "Anantapur"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Andhra Pradesh",
      "District": "Annamayya"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Andhra Pradesh",
      "District": "Bapatla"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Andhra Pradesh",
      "District": "Chittoor"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Andhra Pradesh",
      "District": "East Godavari"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Andhra Pradesh",
      "District": "Eluru"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Andhra Pradesh",
      "District": "Guntur"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Andhra Pradesh",
      "District": "Kakinada"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Andhra Pradesh",
      "District": "Konaseema"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Andhra Pradesh",
      "District": "Krishna"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Andhra Pradesh",
      "District": "Kurnool"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Andhra Pradesh",
      "District": "Nandyal"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Andhra Pradesh",
      "District": "Ntr"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Andhra Pradesh",
      "District": "Palnadu"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Andhra Pradesh",
      "District": "Parvathipuram Manyam"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Andhra Pradesh",
      "District": "Prakasam"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Andhra Pradesh",
      "District": "Spsr Nellore"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Andhra Pradesh",
      "District": "Srikakulam"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Andhra Pradesh",
      "District": "Sri Sathya Sai"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Andhra Pradesh",
      "District": "Tirupati"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Andhra Pradesh",
      "District": "Visakhapatanam"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Andhra Pradesh",
      "District": "Vizianagaram"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Andhra Pradesh",
      "District": "West Godavari"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Andhra Pradesh",
      "District": "Ysr"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Goa",
      "District": "North Goa"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Goa",
      "District": "South Goa"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Karnataka",
      "District": "Bagalkote"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Karnataka",
      "District": "Ballari"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Karnataka",
      "District": "Belagavi"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Karnataka",
      "District": "Bengaluru Rural"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Karnataka",
      "District": "Bengaluru Urban"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Karnataka",
      "District": "Bidar"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Karnataka",
      "District": "Chamarajanagara"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Karnataka",
      "District": "Chikkaballapura"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Karnataka",
      "District": "Chikkamagaluru"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Karnataka",
      "District": "Chitradurga"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Karnataka",
      "District": "Dakshina Kannada"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Karnataka",
      "District": "Davangere"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Karnataka",
      "District": "Dharwad"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Karnataka",
      "District": "Gadag"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Karnataka",
      "District": "Hassan"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Karnataka",
      "District": "Haveri"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Karnataka",
      "District": "Kalaburagi"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Karnataka",
      "District": "Kodagu"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Karnataka",
      "District": "Kolar"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Karnataka",
      "District": "Koppal"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Karnataka",
      "District": "Mandya"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Karnataka",
      "District": "Mysuru"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Karnataka",
      "District": "Raichur"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Karnataka",
      "District": "Ramanagara"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Karnataka",
      "District": "Shivamogga"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Karnataka",
      "District": "Tumakuru"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Karnataka",
      "District": "Udupi"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Karnataka",
      "District": "Uttara Kannada"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Karnataka",
      "District": "Vijayanagar"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Karnataka",
      "District": "Vijayapura"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Karnataka",
      "District": "Yadgir"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Kerala",
      "District": "Alappuzha"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Kerala",
      "District": "Ernakulam"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Kerala",
      "District": "Idukki"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Kerala",
      "District": "Kannur"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Kerala",
      "District": "Kasaragod"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Kerala",
      "District": "Kollam"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Kerala",
      "District": "Kottayam"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Kerala",
      "District": "Kozhikode"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Kerala",
      "District": "Malappuram"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Kerala",
      "District": "Palakkad"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Kerala",
      "District": "Pathanamthitta"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Kerala",
      "District": "Thiruvananthapuram"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Kerala",
      "District": "Thrissur"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Kerala",
      "District": "Wayanad"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Lakshadweep",
      "District": "Lakshadweep District"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Puducherry",
      "District": "Karaikal"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Puducherry",
      "District": "Mahe"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Puducherry",
      "District": "Pondicherry"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Puducherry",
      "District": "Yanam"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Tamil Nadu",
      "District": "Ariyalur"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Tamil Nadu",
      "District": "Chengalpattu"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Tamil Nadu",
      "District": "Chennai"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Tamil Nadu",
      "District": "Coimbatore"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Tamil Nadu",
      "District": "Cuddalore"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Tamil Nadu",
      "District": "Dharmapuri"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Tamil Nadu",
      "District": "Dindigul"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Tamil Nadu",
      "District": "Erode"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Tamil Nadu",
      "District": "Kallakurichi"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Tamil Nadu",
      "District": "Kanchipuram"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Tamil Nadu",
      "District": "Kanniyakumari"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Tamil Nadu",
      "District": "Karur"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Tamil Nadu",
      "District": "Krishnagiri"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Tamil Nadu",
      "District": "Madurai"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Tamil Nadu",
      "District": "Mayiladuthurai"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Tamil Nadu",
      "District": "Nagapattinam"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Tamil Nadu",
      "District": "Namakkal"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Tamil Nadu",
      "District": "Perambalur"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Tamil Nadu",
      "District": "Pudukkottai"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Tamil Nadu",
      "District": "Ramanathapuram"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Tamil Nadu",
      "District": "Ranipet"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Tamil Nadu",
      "District": "Salem"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Tamil Nadu",
      "District": "Sivaganga"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Tamil Nadu",
      "District": "Tenkasi"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Tamil Nadu",
      "District": "Thanjavur"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Tamil Nadu",
      "District": "Theni"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Tamil Nadu",
      "District": "The Nilgiris"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Tamil Nadu",
      "District": "Thiruvallur"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Tamil Nadu",
      "District": "Thiruvarur"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Tamil Nadu",
      "District": "Tiruchirappalli"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Tamil Nadu",
      "District": "Tirunelveli"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Tamil Nadu",
      "District": "Tirupathur"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Tamil Nadu",
      "District": "Tiruppur"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Tamil Nadu",
      "District": "Tiruvannamalai"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Tamil Nadu",
      "District": "Tuticorin"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Tamil Nadu",
      "District": "Vellore"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Tamil Nadu",
      "District": "Villupuram"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Tamil Nadu",
      "District": "Virudhunagar"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Telangana",
      "District": "Adilabad"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Telangana",
      "District": "Bhadradri Kothagudem"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Telangana",
      "District": "Hanumakonda"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Telangana",
      "District": "Hyderabad"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Telangana",
      "District": "Jagitial"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Telangana",
      "District": "Jangoan"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Telangana",
      "District": "Jayashankar Bhupalapally"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Telangana",
      "District": "Jogulamba Gadwal"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Telangana",
      "District": "Kamareddy"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Telangana",
      "District": "Karimnagar"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Telangana",
      "District": "Khammam"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Telangana",
      "District": "Kumuram Bheem Asifabad"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Telangana",
      "District": "Mahabubabad"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Telangana",
      "District": "Mahabubnagar"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Telangana",
      "District": "Mancherial"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Telangana",
      "District": "Medak"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Telangana",
      "District": "Medchal Malkajgiri"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Telangana",
      "District": "Mulugu"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Telangana",
      "District": "Nagarkurnool"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Telangana",
      "District": "Nalgonda"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Telangana",
      "District": "Narayanpet"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Telangana",
      "District": "Nirmal"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Telangana",
      "District": "Nizamabad"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Telangana",
      "District": "Peddapalli"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Telangana",
      "District": "Rajanna Sircilla"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Telangana",
      "District": "Ranga Reddy"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Telangana",
      "District": "Sangareddy"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Telangana",
      "District": "Siddipet"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Telangana",
      "District": "Suryapet"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Telangana",
      "District": "Vikarabad"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Telangana",
      "District": "Wanaparthy"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Telangana",
      "District": "Warangal"
    },
    {
      "Zone No.": "13",
      "Zone": "South India",
      "zoneId": "664ce35419c98f831a73f7b0",
      "State / Union Territory": "Telangana",
      "District": "Yadadri Bhuvanagiri"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Arunachal Pradesh",
      "District": "Anjaw"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Arunachal Pradesh",
      "District": "Changlang"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Arunachal Pradesh",
      "District": "Dibang Valley"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Arunachal Pradesh",
      "District": "East Kameng"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Arunachal Pradesh",
      "District": "East Siang"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Arunachal Pradesh",
      "District": "Kamle"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Arunachal Pradesh",
      "District": "Kra Daadi"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Arunachal Pradesh",
      "District": "Kurung Kumey"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Arunachal Pradesh",
      "District": "Leparada"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Arunachal Pradesh",
      "District": "Lohit"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Arunachal Pradesh",
      "District": "Longding"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Arunachal Pradesh",
      "District": "Lower Dibang Valley"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Arunachal Pradesh",
      "District": "Lower Siang"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Arunachal Pradesh",
      "District": "Lower Subansiri"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Arunachal Pradesh",
      "District": "Namsai"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Arunachal Pradesh",
      "District": "Pakke Kessang"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Arunachal Pradesh",
      "District": "Papum Pare"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Arunachal Pradesh",
      "District": "Shi Yomi"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Arunachal Pradesh",
      "District": "Siang"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Arunachal Pradesh",
      "District": "Tawang"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Arunachal Pradesh",
      "District": "Tirap"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Arunachal Pradesh",
      "District": "Upper Siang"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Arunachal Pradesh",
      "District": "Upper Subansiri"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Arunachal Pradesh",
      "District": "West Kameng"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Arunachal Pradesh",
      "District": "West Siang"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Assam",
      "District": "Bajali"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Assam",
      "District": "Baksa"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Assam",
      "District": "Barpeta"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Assam",
      "District": "Biswanath"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Assam",
      "District": "Bongaigaon"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Assam",
      "District": "Cachar"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Assam",
      "District": "Charaideo"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Assam",
      "District": "Chirang"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Assam",
      "District": "Darrang"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Assam",
      "District": "Dhemaji"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Assam",
      "District": "Dhubri"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Assam",
      "District": "Dibrugarh"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Assam",
      "District": "Dima Hasao"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Assam",
      "District": "Goalpara"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Assam",
      "District": "Golaghat"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Assam",
      "District": "Hailakandi"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Assam",
      "District": "Hojai"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Assam",
      "District": "Jorhat"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Assam",
      "District": "Kamrup"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Assam",
      "District": "Kamrup Metro"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Assam",
      "District": "Karbi Anglong"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Assam",
      "District": "Karimganj"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Assam",
      "District": "Kokrajhar"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Assam",
      "District": "Lakhimpur"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Assam",
      "District": "Majuli"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Assam",
      "District": "Marigaon"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Assam",
      "District": "Nagaon"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Assam",
      "District": "Nalbari"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Assam",
      "District": "Sivasagar"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Assam",
      "District": "Sonitpur"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Assam",
      "District": "South Salmara Mancachar"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Assam",
      "District": "Tamulpur"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Assam",
      "District": "Tinsukia"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Assam",
      "District": "Udalguri"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Assam",
      "District": "West Karbi Anglong"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Bihar",
      "District": "Araria"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Bihar",
      "District": "Arwal"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Bihar",
      "District": "Aurangabad"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Bihar",
      "District": "Banka"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Bihar",
      "District": "Begusarai"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Bihar",
      "District": "Bhagalpur"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Bihar",
      "District": "Bhojpur"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Bihar",
      "District": "Buxar"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Bihar",
      "District": "Darbhanga"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Bihar",
      "District": "Gaya"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Bihar",
      "District": "Gopalganj"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Bihar",
      "District": "Jamui"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Bihar",
      "District": "Jehanabad"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Bihar",
      "District": "Kaimur (Bhabua)"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Bihar",
      "District": "Katihar"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Bihar",
      "District": "Khagaria"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Bihar",
      "District": "Kishanganj"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Bihar",
      "District": "Lakhisarai"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Bihar",
      "District": "Madhepura"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Bihar",
      "District": "Madhubani"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Bihar",
      "District": "Munger"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Bihar",
      "District": "Muzaffarpur"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Bihar",
      "District": "Nalanda"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Bihar",
      "District": "Nawada"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Bihar",
      "District": "Pashchim Champaran"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Bihar",
      "District": "Patna"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Bihar",
      "District": "Purbi Champaran"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Bihar",
      "District": "Purnia"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Bihar",
      "District": "Rohtas"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Bihar",
      "District": "Saharsa"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Bihar",
      "District": "Samastipur"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Bihar",
      "District": "Saran"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Bihar",
      "District": "Sheikhpura"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Bihar",
      "District": "Sheohar"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Bihar",
      "District": "Sitamarhi"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Bihar",
      "District": "Siwan"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Bihar",
      "District": "Supaul"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Bihar",
      "District": "Vaishali"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Jharkhand",
      "District": "Bokaro"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Jharkhand",
      "District": "Chatra"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Jharkhand",
      "District": "Deoghar"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Jharkhand",
      "District": "Dhanbad"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Jharkhand",
      "District": "Dumka"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Jharkhand",
      "District": "East Singhbum"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Jharkhand",
      "District": "Garhwa"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Jharkhand",
      "District": "Giridih"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Jharkhand",
      "District": "Godda"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Jharkhand",
      "District": "Gumla"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Jharkhand",
      "District": "Hazaribagh"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Jharkhand",
      "District": "Jamtara"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Jharkhand",
      "District": "Khunti"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Jharkhand",
      "District": "Koderma"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Jharkhand",
      "District": "Latehar"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Jharkhand",
      "District": "Lohardaga"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Jharkhand",
      "District": "Pakur"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Jharkhand",
      "District": "Palamu"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Jharkhand",
      "District": "Ramgarh"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Jharkhand",
      "District": "Ranchi"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Jharkhand",
      "District": "Sahebganj"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Jharkhand",
      "District": "Saraikela Kharsawan"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Jharkhand",
      "District": "Simdega"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Jharkhand",
      "District": "West Singhbhum"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Manipur",
      "District": "Bishnupur"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Manipur",
      "District": "Chandel"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Manipur",
      "District": "Churachandpur"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Manipur",
      "District": "Imphal East"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Manipur",
      "District": "Imphal West"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Manipur",
      "District": "Jiribam"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Manipur",
      "District": "Kakching"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Manipur",
      "District": "Kamjong"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Manipur",
      "District": "Kangpokpi"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Manipur",
      "District": "Noney"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Manipur",
      "District": "Pherzawl"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Manipur",
      "District": "Senapati"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Manipur",
      "District": "Tamenglong"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Manipur",
      "District": "Tengnoupal"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Manipur",
      "District": "Thoubal"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Manipur",
      "District": "Ukhrul"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Meghalaya",
      "District": "Eastern West Khasi Hills"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Meghalaya",
      "District": "East Garo Hills"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Meghalaya",
      "District": "East Jaintia Hills"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Meghalaya",
      "District": "East Khasi Hills"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Meghalaya",
      "District": "North Garo Hills"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Meghalaya",
      "District": "Ri Bhoi"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Meghalaya",
      "District": "South Garo Hills"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Meghalaya",
      "District": "South West Garo Hills"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Meghalaya",
      "District": "South West Khasi Hills"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Meghalaya",
      "District": "West Garo Hills"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Meghalaya",
      "District": "West Jaintia Hills"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Meghalaya",
      "District": "West Khasi Hills"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Mizoram",
      "District": "Aizawl"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Mizoram",
      "District": "Champhai"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Mizoram",
      "District": "Hnahthial"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Mizoram",
      "District": "Khawzawl"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Mizoram",
      "District": "Kolasib"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Mizoram",
      "District": "Lawngtlai"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Mizoram",
      "District": "Lunglei"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Mizoram",
      "District": "Mamit"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Mizoram",
      "District": "Saiha"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Mizoram",
      "District": "Saitual"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Mizoram",
      "District": "Serchhip"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Nagaland",
      "District": "Dimapur"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Nagaland",
      "District": "Kiphire"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Nagaland",
      "District": "Kohima"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Nagaland",
      "District": "Longleng"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Nagaland",
      "District": "Mokokchung"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Nagaland",
      "District": "Mon"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Nagaland",
      "District": "Noklak"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Nagaland",
      "District": "Peren"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Nagaland",
      "District": "Phek"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Nagaland",
      "District": "Tseminyu"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Nagaland",
      "District": "Tuensang"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Nagaland",
      "District": "Wokha"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Nagaland",
      "District": "Zunheboto"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Odisha",
      "District": "Anugul"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Odisha",
      "District": "Balangir"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Odisha",
      "District": "Baleshwar"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Odisha",
      "District": "Bargarh"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Odisha",
      "District": "Bhadrak"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Odisha",
      "District": "Boudh"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Odisha",
      "District": "Cuttack"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Odisha",
      "District": "Deogarh"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Odisha",
      "District": "Dhenkanal"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Odisha",
      "District": "Gajapati"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Odisha",
      "District": "Ganjam"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Odisha",
      "District": "Jagatsinghapur"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Odisha",
      "District": "Jajapur"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Odisha",
      "District": "Jharsuguda"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Odisha",
      "District": "Kalahandi"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Odisha",
      "District": "Kandhamal"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Odisha",
      "District": "Kendrapara"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Odisha",
      "District": "Kendujhar"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Odisha",
      "District": "Khordha"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Odisha",
      "District": "Koraput"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Odisha",
      "District": "Malkangiri"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Odisha",
      "District": "Mayurbhanj"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Odisha",
      "District": "Nabarangpur"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Odisha",
      "District": "Nayagarh"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Odisha",
      "District": "Nuapada"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Odisha",
      "District": "Puri"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Odisha",
      "District": "Rayagada"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Odisha",
      "District": "Sambalpur"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Odisha",
      "District": "Sonepur"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Odisha",
      "District": "Sundargarh"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Sikkim",
      "District": "Gangtok"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Sikkim",
      "District": "Gyalshing"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Sikkim",
      "District": "Mangan"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Sikkim",
      "District": "Namchi"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Sikkim",
      "District": "Pakyong"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Sikkim",
      "District": "Soreng"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Tripura",
      "District": "Dhalai"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Tripura",
      "District": "Gomati"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Tripura",
      "District": "Khowai"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Tripura",
      "District": "North Tripura"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Tripura",
      "District": "Sepahijala"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Tripura",
      "District": "South Tripura"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Tripura",
      "District": "Unakoti"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "Tripura",
      "District": "West Tripura"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "West Bengal",
      "District": "24 Paraganas North"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "West Bengal",
      "District": "24 Paraganas South"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "West Bengal",
      "District": "Alipurduar"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "West Bengal",
      "District": "Bankura"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "West Bengal",
      "District": "Birbhum"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "West Bengal",
      "District": "Coochbehar"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "West Bengal",
      "District": "Darjeeling"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "West Bengal",
      "District": "Dinajpur Dakshin"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "West Bengal",
      "District": "Dinajpur Uttar"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "West Bengal",
      "District": "Hooghly"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "West Bengal",
      "District": "Howrah"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "West Bengal",
      "District": "Jalpaiguri"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "West Bengal",
      "District": "Jhargram"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "West Bengal",
      "District": "Kalimpong"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "West Bengal",
      "District": "Kolkata"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "West Bengal",
      "District": "Maldah"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "West Bengal",
      "District": "Medinipur East"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "West Bengal",
      "District": "Medinipur West"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "West Bengal",
      "District": "Murshidabad"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "West Bengal",
      "District": "Nadia"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "West Bengal",
      "District": "Paschim Bardhaman"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "West Bengal",
      "District": "Purba Bardhaman"
    },
    {
      "Zone No.": "14",
      "Zone": "East India",
      "zoneId": "664ce36119c98f831a73f7da",
      "State / Union Territory": "West Bengal",
      "District": "Purulia"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Chandigarh",
      "District": "Chandigarh"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Delhi",
      "District": "Central"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Delhi",
      "District": "East"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Delhi",
      "District": "New Delhi"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Delhi",
      "District": "North"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Delhi",
      "District": "North East"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Delhi",
      "District": "North West"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Delhi",
      "District": "Shahdara"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Delhi",
      "District": "South"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Delhi",
      "District": "South East"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Delhi",
      "District": "South West"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Delhi",
      "District": "West"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Haryana",
      "District": "Ambala"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Haryana",
      "District": "Bhiwani"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Haryana",
      "District": "Charki Dadri"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Haryana",
      "District": "Faridabad"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Haryana",
      "District": "Fatehabad"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Haryana",
      "District": "Gurugram"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Haryana",
      "District": "Hisar"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Haryana",
      "District": "Jhajjar"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Haryana",
      "District": "Jind"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Haryana",
      "District": "Kaithal"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Haryana",
      "District": "Karnal"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Haryana",
      "District": "Kurukshetra"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Haryana",
      "District": "Mahendragarh"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Haryana",
      "District": "Nuh"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Haryana",
      "District": "Palwal"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Haryana",
      "District": "Panchkula"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Haryana",
      "District": "Panipat"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Haryana",
      "District": "Rewari"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Haryana",
      "District": "Rohtak"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Haryana",
      "District": "Sirsa"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Haryana",
      "District": "Sonipat"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Haryana",
      "District": "Yamunanagar"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Himachal Pradesh",
      "District": "Bilaspur"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Himachal Pradesh",
      "District": "Chamba"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Himachal Pradesh",
      "District": "Hamirpur"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Himachal Pradesh",
      "District": "Kangra"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Himachal Pradesh",
      "District": "Kinnaur"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Himachal Pradesh",
      "District": "Kullu"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Himachal Pradesh",
      "District": "Lahul And Spiti"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Himachal Pradesh",
      "District": "Mandi"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Himachal Pradesh",
      "District": "Shimla"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Himachal Pradesh",
      "District": "Sirmaur"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Himachal Pradesh",
      "District": "Solan"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Himachal Pradesh",
      "District": "Una"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Jammu And Kashmir",
      "District": "Anantnag"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Jammu And Kashmir",
      "District": "Bandipora"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Jammu And Kashmir",
      "District": "Baramulla"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Jammu And Kashmir",
      "District": "Budgam"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Jammu And Kashmir",
      "District": "Doda"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Jammu And Kashmir",
      "District": "Ganderbal"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Jammu And Kashmir",
      "District": "Jammu"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Jammu And Kashmir",
      "District": "Kathua"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Jammu And Kashmir",
      "District": "Kishtwar"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Jammu And Kashmir",
      "District": "Kulgam"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Jammu And Kashmir",
      "District": "Kupwara"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Jammu And Kashmir",
      "District": "Poonch"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Jammu And Kashmir",
      "District": "Pulwama"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Jammu And Kashmir",
      "District": "Rajouri"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Jammu And Kashmir",
      "District": "Ramban"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Jammu And Kashmir",
      "District": "Reasi"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Jammu And Kashmir",
      "District": "Samba"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Jammu And Kashmir",
      "District": "Shopian"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Jammu And Kashmir",
      "District": "Srinagar"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Jammu And Kashmir",
      "District": "Udhampur"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Ladakh",
      "District": "Kargil"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Ladakh",
      "District": "Leh Ladakh"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Punjab",
      "District": "Amritsar"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Punjab",
      "District": "Barnala"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Punjab",
      "District": "Bathinda"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Punjab",
      "District": "Faridkot"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Punjab",
      "District": "Fatehgarh Sahib"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Punjab",
      "District": "Fazilka"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Punjab",
      "District": "Ferozepur"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Punjab",
      "District": "Gurdaspur"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Punjab",
      "District": "Hoshiarpur"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Punjab",
      "District": "Jalandhar"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Punjab",
      "District": "Kapurthala"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Punjab",
      "District": "Ludhiana"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Punjab",
      "District": "Malerkotla"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Punjab",
      "District": "Mansa"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Punjab",
      "District": "Moga"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Punjab",
      "District": "Pathankot"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Punjab",
      "District": "Patiala"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Punjab",
      "District": "Rupnagar"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Punjab",
      "District": "Sangrur"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Punjab",
      "District": "S.A.S Nagar"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Punjab",
      "District": "Shahid Bhagat Singh Nagar"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Punjab",
      "District": "Sri Muktsar Sahib"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Punjab",
      "District": "Tarn Taran"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Rajasthan",
      "District": "Ajmer"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Rajasthan",
      "District": "Alwar"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Rajasthan",
      "District": "Banswara"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Rajasthan",
      "District": "Baran"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Rajasthan",
      "District": "Barmer"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Rajasthan",
      "District": "Bharatpur"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Rajasthan",
      "District": "Bhilwara"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Rajasthan",
      "District": "Bikaner"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Rajasthan",
      "District": "Bundi"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Rajasthan",
      "District": "Chittorgarh"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Rajasthan",
      "District": "Churu"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Rajasthan",
      "District": "Dausa"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Rajasthan",
      "District": "Dholpur"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Rajasthan",
      "District": "Dungarpur"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Rajasthan",
      "District": "Ganganagar"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Rajasthan",
      "District": "Hanumangarh"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Rajasthan",
      "District": "Jaipur"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Rajasthan",
      "District": "Jaisalmer"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Rajasthan",
      "District": "Jalore"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Rajasthan",
      "District": "Jhalawar"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Rajasthan",
      "District": "Jhunjhunu"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Rajasthan",
      "District": "Jodhpur"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Rajasthan",
      "District": "Karauli"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Rajasthan",
      "District": "Kota"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Rajasthan",
      "District": "Nagaur"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Rajasthan",
      "District": "Pali"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Rajasthan",
      "District": "Pratapgarh"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Rajasthan",
      "District": "Rajsamand"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Rajasthan",
      "District": "Sawai Madhopur"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Rajasthan",
      "District": "Sikar"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Rajasthan",
      "District": "Sirohi"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Rajasthan",
      "District": "Tonk"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Rajasthan",
      "District": "Udaipur"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttarakhand",
      "District": "Almora"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttarakhand",
      "District": "Bageshwar"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttarakhand",
      "District": "Chamoli"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttarakhand",
      "District": "Champawat"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttarakhand",
      "District": "Dehradun"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttarakhand",
      "District": "Haridwar"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttarakhand",
      "District": "Nainital"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttarakhand",
      "District": "Pauri Garhwal"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttarakhand",
      "District": "Pithoragarh"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttarakhand",
      "District": "Rudra Prayag"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttarakhand",
      "District": "Tehri Garhwal"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttarakhand",
      "District": "Udam Singh Nagar"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttarakhand",
      "District": "Uttar Kashi"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Agra"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Aligarh"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Ambedkar Nagar"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Amethi"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Amroha"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Auraiya"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Ayodhya"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Azamgarh"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Baghpat"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Bahraich"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Ballia"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Balrampur"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Banda"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Barabanki"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Bareilly"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Basti"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Bhadohi"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Bijnor"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Budaun"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Bulandshahr"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Chandauli"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Chitrakoot"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Deoria"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Etah"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Etawah"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Farrukhabad"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Fatehpur"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Firozabad"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Gautam Buddha Nagar"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Ghaziabad"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Ghazipur"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Gonda"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Gorakhpur"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Hamirpur"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Hapur"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Hardoi"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Hathras"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Jalaun"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Jaunpur"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Jhansi"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Kannauj"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Kanpur Dehat"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Kanpur Nagar"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Kasganj"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Kaushambi"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Kheri"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Kushi Nagar"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Lalitpur"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Lucknow"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Maharajganj"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Mahoba"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Mainpuri"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Mathura"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Mau"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Meerut"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Mirzapur"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Moradabad"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Muzaffarnagar"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Pilibhit"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Pratapgarh"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Prayagraj"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Rae Bareli"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Rampur"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Saharanpur"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Sambhal"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Sant Kabeer Nagar"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Shahjahanpur"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Shamli"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Shravasti"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Siddharth Nagar"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Sitapur"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Sonbhadra"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Sultanpur"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Unnao"
    },
    {
      "Zone No.": "15",
      "Zone": "North India",
      "zoneId": "664ce37019c98f831a73f7ea",
      "State / Union Territory": "Uttar Pradesh",
      "District": "Varanasi"
    }
  ];

  export {  DISTRICT_DATA };
