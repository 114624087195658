import { Helmet } from "react-helmet";

/* REDUX IMPORTS */
import { useSelector } from "react-redux";

/* MATERIAL TAILWIND COMPONENTS */
import { Card, CardBody } from "@material-tailwind/react";

/* COMPONENTS */
import HomePageCmsView from "./pages/home-page";

const Tags = () => {
  const loadingStatus = useSelector((state) => state?.user?.loading);

  return (
    <section className="px-0 py-2 xl:p-5 lg:p-5 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Content Management | Lohana Mahaparishad</title>
      </Helmet>

      <div className="container-full-width">
        {/* HEADING */}
        <div className="block xl:flex lg:flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-proxima-bold text-black tracking-tight">
              Homepage
            </h1>
            <h2 className="text-base font-proxima-regular text-greyText">
              Manage the content which are currently present on the homepage of
              the website
            </h2>
          </div>
        </div>

        <div className="mt-5 xl:mt-10 lg:mt-10">
          {/* DETAILS LOADING */}
          {loadingStatus && (
            <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5 mt-3">
              <p className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText tracking-tight text-center">
                Please wait we are fetching your page details...
              </p>
            </div>
          )}

          {/* HOMEPAGE */}
          <Card className="bg-white border border-authBorder rounded-md shadow-none">
            <CardBody className="p-5">
              <HomePageCmsView loadingStatus={loadingStatus} />
            </CardBody>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default Tags;
