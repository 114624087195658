import React from "react";

/* MATERIAL TAILWIND COMPONENTS */
import { Button, Tooltip } from "@material-tailwind/react";
import { BiEdit } from "react-icons/bi";

const AboutUs = (props) => {
  const cmsList = props?.cmsList;

  const EditButton = (editProps) => {
    return (
      <Tooltip
        content={editProps?.content}
        className="text-sm font-proxima-semibold"
      >
        <Button
          onClick={() => {
            props.setEditDetails(editProps?.details);
            props.setEditId(editProps?.id);
            props.setEditDialog(!props.editDialog);
          }}
          className="p-2 rounded-full shadow hover:shadow bg-white border border-greyBorder hover:border-logoRed transition-all duration-300 ease-in-out"
        >
          <BiEdit className="w-5 h-5 text-greyText" />
        </Button>
      </Tooltip>
    );
  };

  return (
    <div className="px-10">
      <div className="container-full-width">
        <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5 xl:gap-20 lg:gap-20 items-center relative">
          <div className="col-span-1">
            <h2 className="text-4xl font-sunflower-bold text-black tracking-tight leading-tight">
              Unveiling the spirit of Shree Lohana Mahaparishad Connecting
              Generations, Preserving Heritage and Building a Flourishing
              Community
            </h2>
          </div>
          <div className="col-span-1">
            <p className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText leading-relaxed">
              Shree Lohana Mahaparishad, a cultural organization, unites the
              global Lohana community. Full commitment in preserving traditions,
              fostering unity, and empowering generations, we celebrate our rich
              heritage through events, initiatives, and a shared commitment to
              collective growth and prosperity.
            </p>
          </div>

          <div className="absolute top-0 right-0">
            <EditButton
              details={cmsList?.find((el) => el?.moduleName === "bannerImage")}
              content="Edit details"
              id={cmsList?.find((el) => el?.moduleName === "bannerImage")?._id}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
